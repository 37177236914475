/*global google*/
import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { Modal, Paper, Grid, Fade, Button, InputLabel, FormControl, MenuItem, Select } from "@material-ui/core";
import { closeUploadPaymentModal, handleGetPayments } from './paymentSlice';
import { showLoading, hideLoading } from 'react-redux-loading';
import * as Api from "../../app/Api";
import { Close } from "@material-ui/icons";
import styles from './Payment.module.css';

export function UploadPayment(props) {
  const dispatch = useDispatch();
  const initialState = {
    platform: "BEEP",
    payments: [],
    error: false,
    errorMessage: "",
  };
  const [paymentState, _setPaymentState] = useStateWithCallbackLazy({
    ...initialState
  });
  const paymentStateRef = useRef(paymentState);

  const setPaymentState = data => {
    paymentStateRef.current = data;
    _setPaymentState(data);
  };

  const handleUploadCsv = async (e) => {
    dispatch(showLoading());

    let error = false;
    let file = e.target.files[0];
    if (file != undefined) {
      let formData = new FormData();
      formData.append("csvFile", file);
      try {
        const res = await Api.uploadPayment(formData, paymentState.platform);
        if (res && res.ok === true) {
          dispatch(closeUploadPaymentModal());
          dispatch(handleGetPayments());
        } else if (res.status == 500) {
          let err = await res.json();
          throw err;
        } else {
          setPaymentState({...paymentState, error: true, errorMessage: "Please select valid CSV file"});
        }
      } catch (err) {
        setPaymentState({...paymentState, error: true, errorMessage: err.message});
      }
    } else {
      error = true;
      setPaymentState({...paymentState, error: true, errorMessage: "Please select file to upload"});
      return;
    }
  };

  return (
    <Modal
      open={props.uploadPaymentModalOpen}
      aria-labelledby="add-payment-modal"
      className={styles.modal}
    >
      <Paper className={styles.modalContainer}>
        <div className={styles.container}>
          <Close className={styles.closeButton} onClick={() => dispatch(closeUploadPaymentModal())}/>
          <h3 className={styles.subtitle}>Upload Payments From CSV</h3>
          <Fade in={paymentState.error}>
            <p className={styles.errorMessage}>{paymentState.errorMessage}</p>
          </Fade>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <form id="form">
                <FormControl variant="outlined" style={{marginBottom: 20}} fullWidth>
                  <InputLabel id="platform-label">Delivery Platform</InputLabel>
                  <Select
                    labelId="platform-label"
                    id="delivery-platform"
                    value={paymentState.platform}
                    label="Delivery Platform"
                    onChange={e => setPaymentState({ ...paymentState, platform: e.target.value }) }
                  >
                    {
                      Api.getDeliveryPlatforms().map((platform) => (
                        <MenuItem value={platform}>{platform}</MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
                <label for="file-upload" >
                  <input
                    type="file"
                    id="file-upload"
                    name="payment_file"
                    onChange={handleUploadCsv}
                    accept=".csv"
                    style={{display: "none"}}
                  />
                  <Button className={styles.gradientButton} component="span">
                    Choose your file
                  </Button>
                </label>
              </form>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Modal>
  );
}
