import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, TableFooter, Checkbox, Breadcrumbs, Typography, Link, Toolbar } from "@material-ui/core";
import { TableToolbar } from './TableToolbar';
import TablePaginationActions from './TablePaginationActions';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { selectState, setSelectedId, handleGetUser, setPage, setRowsPerPage, handleDownloadPayslip, setRedeemSelectedId, setRedeemPage, setRedeemRowsPerPage, handleDownloadRedeemPayslip } from './userPaymentSlice';
import styles from './UserPayment.module.css';
import Moment from 'moment';

export function UserPayment(props) {
  const state = useSelector(selectState);
  const dispatch = useDispatch();
  const { id } = useParams();
  useEffect(() => { 
    if (state.status === 'idle') {
      dispatch(handleGetUser(id));
    }
  });
  
  const handleChangePage = (event, newPage, userPayment=true) => {
    if (userPayment) {
      dispatch(setPage(newPage));
    } else {
      dispatch(setRedeemPage(newPage));
    }
    dispatch(handleGetUser(id));
  };

  const handleChangeRowsPerPage = (event, userPayment=true) => {
    if (userPayment) {
      dispatch(setRowsPerPage(parseInt(event.target.value, 10)));
      dispatch(setPage(0));
    } else {
      dispatch(setRedeemRowsPerPage(parseInt(event.target.value, 10)));
      dispatch(setRedeemPage(0));
    }
    dispatch(handleGetUser(id));
  };

  return (
    <div className={styles.container}>
      {
        state.user && 
        <Breadcrumbs separator="›" aria-label="breadcrumb" className={styles.breadcrumb}>
          <Link color="inherit" href="/app/users">
            User
          </Link>
          <Typography color="textPrimary">Payment</Typography>
        </Breadcrumbs>
      }
      <TableContainer component={Paper} className={styles.denseTable}>
        <Toolbar>
          <Typography variant="h6" id="tableTitle" component="div" style={{flex: '1 1 100%'}}>
            User
          </Typography>
        </Toolbar>
        <Table size="small">
          <TableHead className={styles.tableHeader}>
            <TableRow>
              <TableCell align="left" className={styles.tableHeaderNarrow}>ID</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Platform IDs</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Email</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Role</TableCell>
              <TableCell align="left" className={styles.tableHeader}>First Name</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Last Name</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Contact Number</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Salary Earned (RM)</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Available Amount (RM)</TableCell>
            </TableRow>
          </TableHead>
          {
            state.user &&
            <TableBody>
              <TableRow>
                <TableCell align="left" className={styles.tableCellNarrow}>{state.user.id}</TableCell>
                <TableCell align="left">{state.user.platformIds?.join(', ')}</TableCell>
                <TableCell align="left">{state.user.userName}</TableCell>
                <TableCell align="left">{state.user.role}</TableCell>
                <TableCell align="left">{state.user.firstName}</TableCell>
                <TableCell align="left">{state.user.lastName}</TableCell>
                <TableCell align="left">{state.user.contactNumber}</TableCell>
                <TableCell align="left">{state.user.salaryEarned ? state.user.salaryEarned / 100 : 0 }</TableCell>
                <TableCell align="left">{state.user.availableAmount ? state.user.availableAmount / 100 : 0}</TableCell>
              </TableRow>
            </TableBody>
          }
        </Table>
      </TableContainer>
      <TableContainer component={Paper} className={styles.denseTable}>
        <TableToolbar 
          numSelected={state.selectedId == null ? 0 : 1}
          title="User Payments"
        />
        <Table size="small">
          <TableHead className={styles.tableHeader}>
            <TableRow>
              <TableCell align="left" className={styles.tableHeader}>{" "}</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Payment Date</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Platform ID</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Name</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Amount (RM)</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Bonus (RM)</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Weekly Transit Time</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Download Payslip</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              state.userPayments.length > 0 ? (
                state.userPayments.map((payment) => (
                  <TableRow 
                    key={payment.id}
                    hover
                    onClick={() => dispatch(setSelectedId(state.selectedId == payment.id ? null : payment.id))}
                    role="checkbox"
                    aria-checked={state.selectedId == payment.id ? true : false}
                    selected={state.selectedId == payment.id ? true : false}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox 
                        color="primary"
                        checked={state.selectedId == payment.id ? true : false}
                      />
                    </TableCell>
                    <TableCell align="left">{payment.startDate} - {payment.endDate}</TableCell>
                    <TableCell align="left">{payment.platformId}</TableCell>
                    <TableCell align="left">{payment.user.firstName}</TableCell>
                    <TableCell align="left">{payment.amount / 100}</TableCell>
                    <TableCell align="left">{payment.bonus / 100}</TableCell>
                    <TableCell align="left">{payment.user.weeklyTransitTime ? new Date(payment.user.weeklyTransitTime * 1000).toISOString().substr(11, 8) : ""}</TableCell>
                    <TableCell align="left">
                      <IconButton aria-label="download" onClick={() => dispatch(handleDownloadPayslip(payment.startDate, payment.platformId, payment.user.id))}>
                        <CloudDownloadIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={9} align="center">
                    <p>No Payments</p>
                  </TableCell>
                </TableRow>
              )
            }
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                colSpan={7}
                count={state.pagination ? state.pagination.totalElements ? state.pagination.totalElements : 0 : 0}
                rowsPerPage={state.rowsPerPage}
                page={state.page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <TableContainer component={Paper} className={styles.denseTable}>
        <TableToolbar 
          numSelected={state.selectedId == null ? 0 : 1}
          title="User Redeems"
        />
        <Table size="small">
          <TableHead className={styles.tableHeader}>
            <TableRow>
            <TableCell align="left" className={styles.tableHeader}>{" "}</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Created At</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Username</TableCell>
              <TableCell align="left" className={styles.tableHeader}>First Name</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Platform Ids</TableCell>
              <TableCell align="left" className={styles.tableHeaderNarrow}>Completed Order</TableCell>
              <TableCell align="left" className={styles.tableHeaderNarrow}>Peak Hour Time</TableCell>
              <TableCell align="left" className={styles.tableHeaderNarrow}>Transit Time (Hour)</TableCell>
              <TableCell align="left" className={styles.tableHeaderNarrow}>Redeemed Amount (RM)</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Download Payslip</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              state.userRedeems.length > 0 ? (
                state.userRedeems.map((redeem) => (
                  <TableRow 
                    key={redeem.id}
                    hover
                    onClick={() => dispatch(setRedeemSelectedId(state.redeemSelectedId == redeem.id ? null : redeem.id))}
                    role="checkbox"
                    aria-checked={state.redeemSelectedId == redeem.id ? true : false}
                    selected={state.redeemSelectedId == redeem.id ? true : false}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox 
                        color="primary"
                        checked={state.redeemSelectedId == redeem.id ? true : false}
                      />
                    </TableCell>
                    <TableCell align="left" className={styles.tableCell}>{Moment(redeem.createdAt).toString()}</TableCell>
                    <TableCell align="left" className={styles.tableCell}>{redeem.user.userName}</TableCell>
                    <TableCell align="left" className={styles.tableCell}>{redeem.user.firstName}</TableCell>
                    <TableCell align="left" className={styles.tableCell}>{redeem.user.platformIds.join(', ')}</TableCell>
                    <TableCell align="left" className={styles.tableCellNarrow}>{redeem.completedOrder}</TableCell>
                    <TableCell align="left" className={styles.tableCellNarrow}>{redeem.peakHourTime ? redeem.peakHourTime/3600.0 : ''}</TableCell>
                    <TableCell align="left" className={styles.tableCellNarrow}>{redeem.transitTime ? redeem.transitTime/3600.0 : ''}</TableCell>
                    <TableCell align="left" className={styles.tableCellNarrow}>{(redeem.currentRedeemedAmount/100.0).toFixed(2)}</TableCell>
                    <TableCell align="left">
                      <IconButton aria-label="download" onClick={() => dispatch(handleDownloadRedeemPayslip(redeem.id))}>
                        <CloudDownloadIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={9} align="center">
                    <p>No Redeems</p>
                  </TableCell>
                </TableRow>
              )
            }
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                colSpan={7}
                count={state.redeemPagination ? state.redeemPagination.totalElements ? state.redeemPagination.totalElements : 0 : 0}
                rowsPerPage={state.redeemRowsPerPage}
                page={state.redeemPage}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onChangePage={(event, page) => handleChangePage(event, page, false)}
                onChangeRowsPerPage={(event) => handleChangeRowsPerPage(event, false)}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
}
