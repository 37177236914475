import { Toolbar, Typography, Tooltip, IconButton } from "@material-ui/core";
import { lighten, makeStyles } from '@material-ui/core/styles';
import BlockIcon from '@material-ui/icons/Block';
import DoneIcon from '@material-ui/icons/Done';
import GetAppIcon from '@material-ui/icons/GetApp';

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
  theme.palette.type === 'light'
  ? {
    color: theme.palette.secondary.main,
    backgroundColor: lighten(theme.palette.secondary.light, 0.85),
  }
  : {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.secondary.dark,
  },
  title: {
    flex: '1 1 100%',
  },
  toolTipContainer: {
    position: "absolute",
    right: 10
  }
}));

export const TableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected, confirmWithdrawal, rejectWithdrawal, withdrawStatus, downloadReport, downloadCashedOutReport } = props;
  return (
    <Toolbar>
      { numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          {withdrawStatus == "CASHING_OUT" ? "Rider Cashout Requests" : "Rider Cashout History"}
        </Typography>
      )}
      {
        numSelected > 0 && withdrawStatus == "CASHING_OUT" &&
        <Tooltip title="Reject Withdrawal" onClick={rejectWithdrawal}>
          <IconButton aria-label="reject">
            <BlockIcon />
          </IconButton>
        </Tooltip>
      }

      {
        numSelected > 0 && withdrawStatus == "CASHING_OUT" &&
        <Tooltip title="Confirm Withdrawal" onClick={confirmWithdrawal}>
          <IconButton aria-label="confirm">
            <DoneIcon />
          </IconButton>
        </Tooltip>
      }
      
      {withdrawStatus == "CASHING_OUT" &&
        <Tooltip title="Download Report" onClick={downloadReport}>
          <IconButton aria-label="download">
            <GetAppIcon />
          </IconButton>
        </Tooltip>
      }

      {withdrawStatus == "CASHED_OUT" &&
        <Tooltip title="Download Cashed Out Report" onClick={downloadCashedOutReport}>
          <IconButton aria-label="download">
            <GetAppIcon />
          </IconButton>
        </Tooltip>
      }
    </Toolbar>
  );
};