import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox, TablePagination, TableFooter, Tooltip, IconButton, InputLabel, FormControl, MenuItem, Select } from "@material-ui/core";
import { EditUser } from './EditUser';
import { CreateUser } from './CreateUser';
import { ConfirmDelete } from './ConfirmDelete';
import { CreateWithdrawal } from './CreateWithdrawal';
import { CreateNotification } from './CreateNotification';
import { TableToolbar } from './TableToolbar';
import { openEditUserModal, handleGetUsers, selectState, setSelectedId, setPage, setRowsPerPage, openCreateUserModal, openDeleteUserModal, handleSearchUsers, openCreateWithdrawalModal, openCreateNotificationModal } from './userSlice';
import TablePaginationActions from './TablePaginationActions';
import SearchIcon from '@material-ui/icons/Search';
import styles from './User.module.css';

export function User(props) {
  const state = useSelector(selectState);
  const [searchValue, setSearchValue] = useState("");
  const [searchFilter, setSearchFilter] = useState("name");
  const [createNotficationDisabled, setCreateNotficationDisabled] = useState(false);
  const dispatch = useDispatch();
  
  useEffect(() => {
    if (state.status === 'idle') {
      dispatch(handleGetUsers());
    }
  });

  const handleChangePage = (event, newPage) => {
    dispatch(setPage(newPage));
    dispatch(handleGetUsers());
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setRowsPerPage(parseInt(event.target.value, 10)));
    dispatch(setPage(0));
    dispatch(handleGetUsers());
  };

  return (
    <div className={styles.container}>
      <FormControl variant="outlined" style={{marginBottom: 20, minWidth: 200}}>
        <InputLabel id="filter-label">Filter</InputLabel>
        <Select
          labelId="filter-label"
          id="filter"
          value={searchFilter}
          label="Delivery Platform"
          onChange={e => setSearchFilter(e.target.value) }
        >
          <MenuItem value="name">Name</MenuItem>
          <MenuItem value="platformId">Platform ID</MenuItem>
        </Select>
      </FormControl>
      <Paper className={styles.textInputContainer} style={{display: "flex", flexDirection: "row"}}>
        <input
          id="search"
          name="search"
          value={searchValue}
          onChange={e => setSearchValue(e.target.value)}
          placeholder="Search User's Name"
          className={styles.textInput}
        />
        <Tooltip title="Search" onClick={() => dispatch(handleSearchUsers(searchValue, searchFilter))}>
          <IconButton aria-label="search" style={{padding: 10}}>
              <SearchIcon />
          </IconButton>
        </Tooltip>
      </Paper>
      <TableContainer component={Paper} className={styles.denseTable}>
        <TableToolbar 
          numSelected={state.selectedId == null ? 0 : 1}
          editUser={() => dispatch(openEditUserModal())}
          createUser={() => dispatch(openCreateUserModal())}
          deleteUser={() => dispatch(openDeleteUserModal())}
          createWithdrawal={() => dispatch(openCreateWithdrawalModal())}
          createNotfication={() => dispatch(openCreateNotificationModal())}
          createNotficationDisabled={createNotficationDisabled}
        />
        <Table size="small">
          <TableHead className={styles.tableHeader}>
            <TableRow>
              <TableCell align="left" className={styles.tableHeader}>{" "}</TableCell>
              <TableCell align="left" className={styles.tableHeaderNarrow}>ID</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Platform IDs</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Email</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Role</TableCell>
              <TableCell align="left" className={styles.tableHeader}>First Name</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Last Name</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Contact Number</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Salary Earned (RM)</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Available Amount (RM)</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Current Amount (RM)</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Withdrawn Amount (RM)</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Archived Amount (RM)</TableCell>
              <TableCell align="left" className={styles.tableHeader}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              state.users.length > 0 ? (
                state.users.map((user) => (
                  <TableRow 
                    key={user.id}
                    hover
                    onClick={() => {
                      dispatch(setSelectedId(state.selectedId == user.id ? null : user.id));
                      setCreateNotficationDisabled(user.endpointArn ? false : true);
                    }}
                    role="checkbox"
                    aria-checked={state.selectedId == user.id ? true : false}
                    selected={state.selectedId == user.id ? true : false}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox 
                        color="primary"
                        checked={state.selectedId == user.id ? true : false}
                      />
                    </TableCell>
                    <TableCell align="left" className={styles.tableCellNarrow}>{user.id}</TableCell>
                    <TableCell align="left">{user.platformIds?.join(', ')}</TableCell>
                    <TableCell align="left">{user.userName}</TableCell>
                    <TableCell align="left">{user.role}</TableCell>
                    <TableCell align="left">{user.firstName}</TableCell>
                    <TableCell align="left">{user.lastName}</TableCell>
                    <TableCell align="left">{user.contactNumber}</TableCell>
                    <TableCell align="left">{user.salaryEarned ? user.salaryEarned / 100 : 0 }</TableCell>
                    <TableCell align="left">{user.availableAmount ? user.availableAmount / 100 : 0}</TableCell>
                    <TableCell align="left">{user.currentAmount ? user.currentAmount / 100 : 0}</TableCell>
                    <TableCell align="left">{user.withdrawnAmount ? user.withdrawnAmount / 100 : 0}</TableCell>
                    <TableCell align="left">{user.archivedAmount ? user.archivedAmount / 100 : 0}</TableCell>
                    <TableCell align="left">
                      <a target="_blank" className={styles.tableLink} onClick={() => window.open("/app/user-payments/" + user.id, '_blank')}>
                        View Payments
                      </a>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={9} align="center">
                    <p>No Users</p>
                  </TableCell>
                </TableRow>
              )
            }
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                colSpan={7}
                count={state.pagination ? state.pagination.totalElements ? state.pagination.totalElements : 0 : 0}
                rowsPerPage={state.rowsPerPage}
                page={state.page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      {
        state.editUserModalOpen  &&
        <EditUser user={state.selectedId ? state.users.find(user => user.id == state.selectedId) : null} searchValue={searchValue} searchFilter={searchFilter} />
      }
      {
        state.createUserModalOpen  &&
        <CreateUser user={state.selectedId ? state.users.find(user => user.id == state.selectedId) : null} />
      }
      {
        state.deleteUserModalOpen  &&
        <ConfirmDelete user={state.selectedId ? state.users.find(user => user.id == state.selectedId) : null} />
      }
      {
        state.createWithdrawalModalOpen  &&
        <CreateWithdrawal user={state.selectedId ? state.users.find(user => user.id == state.selectedId) : null} />
      }
      {
        state.createNotficationModalOpen  &&
        <CreateNotification user={state.selectedId ? state.users.find(user => user.id == state.selectedId) : null} />
      }
    </div>
  );
}
