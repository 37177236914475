import { configureStore } from '@reduxjs/toolkit';
import { loadingBarReducer } from 'react-redux-loading'
import loginReducer from '../features/login/loginSlice';
import homeReducer from '../features/home/homeSlice';
import paymentReducer from '../features/payment/paymentSlice';
import weeklyPaymentReducer from '../features/weeklyPayment/weeklyPaymentSlice';
import uploadedFileReducer from '../features/uploadedFile/uploadedFileSlice';
import userReducer from '../features/user/userSlice';
import userPaymentReducer from '../features/userPayment/userPaymentSlice';
import beepDeliveryRateReducer from '../features/beepDeliveryRate/beepDeliveryRateSlice';
import deliveritDeliveryRateReducer from '../features/deliveritDeliveryRate/deliveritDeliveryRateSlice';
import shiftGroupReducer from '../features/shiftGroup/shiftGroupSlice';
import shiftReducer from '../features/shift/shiftSlice';
import batchNotificationReducer from '../features/batchNotification/batchNotificationSlice';
import hotspotGroupReducer from '../features/hotspotGroup/hotspotGroupSlice';
import hotspotReducer from '../features/hotspot/hotspotSlice';
import withdrawalReducer from '../features/withdrawal/withdrawalSlice';
import archivedDailyWorkReducer from '../features/archivedDailyWork/archivedDailyWorkSlice';
import redeemReducer from '../features/redeem/redeemSlice';
import shiftTableReducer from '../features/shiftTable/shiftTableSlice';

export const store = configureStore({
  reducer: {
    login: loginReducer,
    home: homeReducer,
    payment: paymentReducer,
    weeklyPayment: weeklyPaymentReducer,
    userPayment: userPaymentReducer,
    uploadedFile: uploadedFileReducer,
    user: userReducer,
    beepDeliveryRate: beepDeliveryRateReducer,
    deliveritDeliveryRate: deliveritDeliveryRateReducer,
    shiftGroup: shiftGroupReducer,
    shift: shiftReducer,
    batchNotification: batchNotificationReducer,
    hotspotGroup: hotspotGroupReducer,
    hotspot: hotspotReducer,
    withdrawal: withdrawalReducer,
    archivedDailyWork: archivedDailyWorkReducer,
    redeem: redeemReducer,
    shiftTable: shiftTableReducer,
    loadingBar: loadingBarReducer,
  },
});
