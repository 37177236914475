import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox, TablePagination, TableFooter, InputLabel, FormControl, MenuItem, Select } from "@material-ui/core";
import { EditBeepDeliveryRate } from './EditBeepDeliveryRate';
import { TableToolbar } from './TableToolbar';
import { openEditBeepDeliveryRateModal, handleGetBeepDeliveryRates, selectState, setSelectedId, setPage, setRowsPerPage } from './beepDeliveryRateSlice';
import { getDeliveryPlatforms } from "../../app/Api";
import TablePaginationActions from './TablePaginationActions';
import styles from './BeepDeliveryRate.module.css';

export function BeepDeliveryRate(props) {
  const state = useSelector(selectState);
  const dispatch = useDispatch();
  
  useEffect(() => {
    if (state.status === 'idle') {
      dispatch(handleGetBeepDeliveryRates());
    }
  });

  const handleChangePage = (event, newPage) => {
    dispatch(setPage(newPage));
    dispatch(handleGetBeepDeliveryRates());
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setRowsPerPage(parseInt(event.target.value, 10)));
    dispatch(setPage(0));
    dispatch(handleGetBeepDeliveryRates());
  };

  const deliveryPlatformOnChange = (platform) => {
    window.location = `/app/${platform.toLowerCase()}-delivery-rates`
  };

  return (
    <div className={styles.container}>
      <FormControl variant="outlined" style={{marginBottom: 20, minWidth: 200}}>
        <InputLabel id="platform-label">Delivery Platform</InputLabel>
        <Select
          labelId="platform-label"
          id="delivery-platform"
          value="BEEP"
          label="Delivery Platform"
          onChange={e => deliveryPlatformOnChange(e.target.value) }
        >
          {
            getDeliveryPlatforms().map((platform) => (
              <MenuItem value={platform}>{platform}</MenuItem>
            ))
          }
        </Select>
      </FormControl>
      <TableContainer component={Paper} className={styles.denseTable}>
        <TableToolbar 
          numSelected={state.selectedId == null ? 0 : 1}
          editBeepDeliveryRate={() => dispatch(openEditBeepDeliveryRateModal())}
        />
        <Table size="small">
          <TableHead className={styles.tableHeader}>
            <TableRow>
              <TableCell align="left" className={styles.tableHeader}>{" "}</TableCell>
              <TableCell align="left" className={styles.tableHeaderNarrow}>ID</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Name</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Minimum Order</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Minimum Order Base Rate (RM)</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Subsequent Order After Minimum Order (RM)</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Bonus Rate per KM (RM)</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Bonus Min Distance (KM)</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Bonus Max Distance (KM)</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Average Order An Hour Threshold</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              state.beepDeliveryRates.length > 0 ? (
                state.beepDeliveryRates.map((beepDeliveryRate) => (
                  <TableRow 
                    key={beepDeliveryRate.id}
                    hover
                    onClick={() => dispatch(setSelectedId(state.selectedId == beepDeliveryRate.id ? null : beepDeliveryRate.id))}
                    role="checkbox"
                    aria-checked={state.selectedId == beepDeliveryRate.id ? true : false}
                    selected={state.selectedId == beepDeliveryRate.id ? true : false}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox 
                        color="primary"
                        checked={state.selectedId == beepDeliveryRate.id ? true : false}
                      />
                    </TableCell>
                    <TableCell align="left" className={styles.tableCellNarrow}>{beepDeliveryRate.id}</TableCell>
                    <TableCell align="left">{beepDeliveryRate.name}</TableCell>
                    <TableCell align="left">{beepDeliveryRate.tier1Count}</TableCell>
                    <TableCell align="left">{beepDeliveryRate.tier1Total / 100}</TableCell>
                    <TableCell align="left">{beepDeliveryRate.tier2Rate / 100}</TableCell>
                    <TableCell align="left">{beepDeliveryRate.bonusRate / 100}</TableCell>
                    <TableCell align="left">{beepDeliveryRate.bonusMinDistance}</TableCell>
                    <TableCell align="left">{beepDeliveryRate.bonusMaxDistance}</TableCell>
                    <TableCell align="left">{beepDeliveryRate.averageOrderAnHourThreshold}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={9} align="center">
                    <p>No Beep Delivery Rates</p>
                  </TableCell>
                </TableRow>
              )
            }
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                colSpan={7}
                count={state.pagination ? state.pagination.totalElements ? state.pagination.totalElements : 0 : 0}
                rowsPerPage={state.rowsPerPage}
                page={state.page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      {
        state.editBeepDeliveryRateModalOpen  &&
        <EditBeepDeliveryRate beepDeliveryRate={state.selectedId ? state.beepDeliveryRates.find(beepDeliveryRate => beepDeliveryRate.id == state.selectedId) : null} />
      }
    </div>
  );
}
