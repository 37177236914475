import { createSlice } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading';
import { format } from 'date-fns'
import * as Api from "../../app/Api";

export const shiftGroupSlice = createSlice({
  name: 'shiftGroup',
  initialState: {
    shiftGroups: [],
    status: 'idle',
    createShiftGroupModalOpen: false,
    editShiftGroupModalOpen: false,
    deleteShiftGroupModalOpen: false,
    pagination: {},
    page: 0,
    rowsPerPage: 10,
    selectedId: null
  },
  reducers: {
    openCreateShiftGroupModal: (state) => {
      state.createShiftGroupModalOpen = true;
    },
    closeCreateShiftGroupModal: (state) => {
      state.createShiftGroupModalOpen = false;
    },
    openEditShiftGroupModal: (state) => {
      state.editShiftGroupModalOpen = true;
    },
    closeEditShiftGroupModal: (state) => {
      state.editShiftGroupModalOpen = false;
    },
    openDeleteShiftGroupModal: (state) => {
      state.deleteShiftGroupModalOpen = true;
    },
    closeDeleteShiftGroupModal: (state) => {
      state.deleteShiftGroupModalOpen = false;
    },
    setSelectedId: (state, action) => {
      state.selectedId = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload;
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
    getShiftGroups: (state, action) => {
      state.shiftGroups = action.payload;
      state.selectedId = null;
      state.status = 'success'
    },
  },
});

export const { openCreateShiftGroupModal, closeCreateShiftGroupModal, openEditShiftGroupModal, closeEditShiftGroupModal, openDeleteShiftGroupModal, closeDeleteShiftGroupModal, setSelectedId, setPage, setRowsPerPage, setPagination, getShiftGroups } = shiftGroupSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.shiftGroup.value)`
export const selectState = state => state.shiftGroup;

export const handleGetShiftGroups = () => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();
  try {
    const res = await Api.getShiftGroups(state.shiftGroup.page, state.shiftGroup.rowsPerPage, format(new Date(new Date().setFullYear(new Date().getFullYear() - 1)), 'yyyy-MM-dd'), format(new Date(new Date().setFullYear(new Date().getFullYear() + 1)), 'yyyy-MM-dd'));
    if (res.status === 401 || res.status == 403) {
      Api.logout();
      throw res.error;
    } else if (res.error) {
      throw res.error;
    };
    
    const { content, ...pagination } = res;
    let shiftGroups = content;

    dispatch(setPagination(pagination));
    dispatch(getShiftGroups(shiftGroups));
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(hideLoading());
  }
};


export default shiftGroupSlice.reducer;
