import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import styles from './Sidebar.module.css';
import { Paper, Drawer, List, ListItem, ListItemText } from "@material-ui/core";

const useStyles = makeStyles({
  list: {
    width: 250,
  }
});

export function Sidebar() {
  const classes = useStyles();
  const [isSideNavOpen, setSideNavOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setSideNavOpen(open);
  };

  const structure = [
    { label: 'Dashboard', link: '/app/home', isSubmenu: false},
    { label: 'Payments', link: '/app/payments', isSubmenu: false},
    { label: 'Weekly Payments', link: '/app/weekly-payments', isSubmenu: false},
    { label: 'Uploaded Files', link: '/app/uploaded-files', isSubmenu: false},
    { label: 'Cash Outs', link: '/app/withdrawals', isSubmenu: false},
    { label: 'Cash Out History', link: '/app/withdrawal-history', isSubmenu: false},
    { label: 'Rejected Cash Out', link: '/app/withdrawal-rejected', isSubmenu: false},
    { label: 'Shift Groups', link: '/app/shift-groups', isSubmenu: false},
    { label: 'Hotspot Groups', link: '/app/hotspot-groups', isSubmenu: false},
    { label: 'Batch Notifications', link: '/app/batch-notifications', isSubmenu: false},
    { label: 'Delivery Rates', link: '/app/deliverit-delivery-rates', isSubmenu: false},
    { label: 'Users', link: '/app/users', isSubmenu: false },
    { label: 'Archived Daily Work', link: '/app/archived-daily-works', isSubmenu: false},
    { label: 'Redeems', link: '/app/redeems', isSubmenu: false},
    { label: 'Shift Table', link: '/app/shift-table', isSubmenu: false},
  ];

  const drawerList = () => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {
          structure.map((l, index) => (
            <ListItem button key={"drawer" + index} onClick={() => window.location = l.link}>
              <ListItemText primary={l.label} />
            </ListItem>
          ))
        }
      </List>
    </div>
  );

  const logout = () => {
    localStorage.removeItem("auth");
    window.location = "/";
  }
  
  return (
    <div>
      <a className={styles.sidebarTrigger} onClick={toggleDrawer(true)}>
        <i className="fa fa-bars fa-lg" style={{lineHeight: "100px"}}></i>
      </a>
      <Drawer anchor={"left"} open={isSideNavOpen} onClose={toggleDrawer(false)}>
        {drawerList()}
      </Drawer>
      <Paper className={styles.sidebarContainer}>
        <ul className={styles.sidebarItemContainer}>
          {
            structure.map((l, index) => 
              <li 
                onClick={() => window.location = l.link}
                key={index}
                className={new RegExp("^(" + l.link + "(.*))$").test(window.location.pathname) ? styles.sidebarActiveItem : styles.sidebarItem}
              >
                {l.label}
              </li>
            )
          }
          <li onClick={() => logout()} key="logout" className={styles.sidebarItem}>Logout</li>
        </ul>
      </Paper>
    </div>
  );
}