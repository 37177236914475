import { createSlice } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading';
import * as Api from "../../app/Api";

export const beepDeliveryRateSlice = createSlice({
  name: 'beepDeliveryRate',
  initialState: {
    beepDeliveryRates: [],
    status: 'idle',
    editBeepDeliveryRateModalOpen: false,
    pagination: {},
    page: 0,
    rowsPerPage: 10,
    selectedId: null
  },
  reducers: {
    openEditBeepDeliveryRateModal: (state) => {
      state.editBeepDeliveryRateModalOpen = true;
    },
    closeEditBeepDeliveryRateModal: (state) => {
      state.editBeepDeliveryRateModalOpen = false;
    },
    setSelectedId: (state, action) => {
      state.selectedId = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload;
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
    getBeepDeliveryRates: (state, action) => {
      state.beepDeliveryRates = action.payload;
      state.status = 'success'
    },
  },
});

export const { openEditBeepDeliveryRateModal, closeEditBeepDeliveryRateModal, setSelectedId, setPage, setRowsPerPage, setPagination, getBeepDeliveryRates } = beepDeliveryRateSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.beepDeliveryRate.value)`
export const selectState = state => state.beepDeliveryRate;

export const handleGetBeepDeliveryRates = () => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();

  try {
    const res = await Api.getBeepDeliveryRates(state.beepDeliveryRate.page, state.beepDeliveryRate.rowsPerPage);
    if (res.status === 401 || res.status == 403) {
      Api.logout();
      throw res.error;
    } else if (res.error) {
      throw res.error;
    };
    
    const { content, ...pagination } = res;
    let beepDeliveryRates = content;

    dispatch(setPagination(pagination));
    dispatch(getBeepDeliveryRates(beepDeliveryRates));
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(hideLoading());
  }
};


export default beepDeliveryRateSlice.reducer;
