/*global google*/
import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { Modal, Paper, Grid, Fade, Button, FormGroup, FormControlLabel, Switch } from "@material-ui/core";
import { closeCreateBatchNotificationModal, handleGetBatchNotifications } from './batchNotificationSlice';
import * as Api from "../../app/Api";
import { Close } from "@material-ui/icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styles from './BatchNotification.module.css';

export function CreateBatchNotification(props) {
  const dispatch = useDispatch();
  const initialState = {
    form: {
      messageTitle: "",
      messageBody: "",
      sendAt: ""
    },
    scheduled: false,
    error: false,
    errorMessage: ""
  };
  const [batchNotificationState, _setBatchNotificationState] = useStateWithCallbackLazy({
    ...initialState
  });

  const batchNotificationStateRef = useRef(batchNotificationState);

  const setBatchNotificationState = data => {
    batchNotificationStateRef.current = data;
    _setBatchNotificationState(data);
  };

  const submit = async () => {
    let error = false;

    if (batchNotificationState.scheduled == false) {
      delete batchNotificationState.form.sendAt;
    }

    for (var key in batchNotificationState.form) {
      if (batchNotificationState.form[key] == null || batchNotificationState.form[key] == "") {
        error = true;
        setBatchNotificationState({...batchNotificationState, error: true, errorMessage: "All fields are required"});
        return;
      }
    }

    if (!error) {
      try {
        let payload = { ...batchNotificationState.form };

        const res = await Api.createNotification(payload);
        if(res.error) throw res.error;

        dispatch(handleGetBatchNotifications(props.selectedTab));
        dispatch(closeCreateBatchNotificationModal());
      } catch (err) {
        setBatchNotificationState({...batchNotificationState, error: true, errorMessage: err});
      }
    }
  }

  return (
    <Modal
      open={props.createBatchNotificationModalOpen}
      aria-labelledby="add-hotspot-group-modal"
      className={styles.modal}
    >
      <Paper className={styles.modalContainer}>
        <div className={styles.container}>
          <Close className={styles.closeButton} onClick={() => dispatch(closeCreateBatchNotificationModal())}/>
          <h3 className={styles.subtitle}>Broadcast Notification <em style={{fontSize: 12}}>(All fields are required)</em></h3>
          <Fade in={batchNotificationState.error}>
            <p className={styles.errorMessage}>{batchNotificationState.errorMessage}</p>
          </Fade>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <p className={styles.inputLabel}>Title</p>
              <Paper className={styles.textInputContainer}>
                <input
                  id="messageTitle"
                  name="messageTitle"
                  value={batchNotificationState.form.messageTitle}
                  onChange={e => setBatchNotificationState({
                    ...batchNotificationState,
                    form: {
                      ...batchNotificationState.form,
                      messageTitle: e.target.value
                    }
                  })}
                  placeholder="Notification Title"
                  className={styles.textInput}
                />
              </Paper>
              <p className={styles.inputLabel}>Body</p>
              <Paper className={styles.textInputContainer}>
                <input
                  id="messageBody"
                  name="messageBody"
                  value={batchNotificationState.form.messageBody}
                  onChange={e => setBatchNotificationState({
                    ...batchNotificationState,
                    form: {
                      ...batchNotificationState.form,
                      messageBody: e.target.value
                    }
                  })}
                  placeholder="Notification Body"
                  className={styles.textInput}
                />
              </Paper>
              <FormGroup row>
                <FormControlLabel
                  control={<Switch checked={batchNotificationState.scheduled} 
                  onChange={e => setBatchNotificationState({
                    ...batchNotificationState,
                    scheduled: e.target.checked
                  })}
                  name="scheduled"
                  color="primary" />}
                  label="Scheduled"
                />
              </FormGroup>
              {
                batchNotificationState.scheduled == true &&
                <>
                  <p className={styles.inputLabel}>Schedule Send At</p>
                  <Paper className={styles.textInputContainer}>
                    <DatePicker
                      selected={batchNotificationState.form.sendAt}
                      value={batchNotificationState.form.sendAt}
                      minDate={new Date()}
                      style={styles.textInput}
                      onChange={date => setBatchNotificationState({
                        ...batchNotificationState,
                        form: {
                          ...batchNotificationState.form,
                          sendAt: date,
                        }
                      })}
                      placeholderText="yyyy-MM-dd hh:mm:ss"
                      dateFormat="yyyy-MM-dd hh:mm:ss"
                      name="sendAt"
                      autoComplete="off"
                      showTimeSelect
                      required
                    />
                  </Paper>
                </>
              }
              <div className={styles.submitButtonContainer}>
                <Button onClick={() => submit()} className={styles.gradientButton}>Create</Button>
              </div>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Modal>
  );
}
