import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox, TablePagination, TableFooter, Button } from "@material-ui/core";
import { CreateHotspotGroup } from './CreateHotspotGroup';
import { EditHotspotGroup } from './EditHotspotGroup';
import { ConfirmDelete } from './ConfirmDelete';
import { TableToolbar } from './TableToolbar';
import { openCreateHotspotGroupModal, openEditHotspotGroupModal, openDeleteHotspotGroupModal, handleGetHotspotGroups, selectState, setSelectedId, setPage, setRowsPerPage } from './hotspotGroupSlice';
import TablePaginationActions from './TablePaginationActions';
import styles from './HotspotGroup.module.css';

export function HotspotGroup(props) {
  const state = useSelector(selectState);
  const dispatch = useDispatch();
  
  useEffect(() => {
    if (state.status === 'idle') {
      dispatch(handleGetHotspotGroups());
    }
  });

  const handleChangePage = (event, newPage) => {
    dispatch(setPage(newPage));
    dispatch(handleGetHotspotGroups());
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setRowsPerPage(parseInt(event.target.value, 10)));
    dispatch(setPage(0));
    dispatch(handleGetHotspotGroups());
  };

  return (
    <div className={styles.container}>
      <TableContainer component={Paper} className={styles.denseTable}>
        <TableToolbar 
          numSelected={state.selectedId == null ? 0 : 1}
          createHotspotGroup={() => dispatch(openCreateHotspotGroupModal())}
          editHotspotGroup={() => dispatch(openEditHotspotGroupModal())}
          deleteHotspotGroup={() => dispatch(openDeleteHotspotGroupModal())}
        />
        <Table size="small">
          <TableHead className={styles.tableHeader}>
            <TableRow>
              <TableCell align="left" className={styles.tableHeader}>{" "}</TableCell>
              <TableCell align="left" className={styles.tableHeaderNarrow}>ID</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Name</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Address</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Postal Code</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Latitude</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Longitude</TableCell>
              <TableCell align="left" className={styles.tableHeader}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              state.hotspotGroups.length > 0 ? (
                state.hotspotGroups.map((hotspotGroup) => (
                  <TableRow 
                    key={hotspotGroup.id}
                    hover
                    onClick={() => dispatch(setSelectedId(state.selectedId == hotspotGroup.id ? null : hotspotGroup.id))}
                    role="checkbox"
                    aria-checked={state.selectedId == hotspotGroup.id ? true : false}
                    selected={state.selectedId == hotspotGroup.id ? true : false}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox 
                        color="primary"
                        checked={state.selectedId == hotspotGroup.id ? true : false}
                      />
                    </TableCell>
                    <TableCell align="left" className={styles.tableCellNarrow}>{hotspotGroup.id}</TableCell>
                    <TableCell align="left">{hotspotGroup.location.name}</TableCell>
                    <TableCell align="left">{hotspotGroup.location.address}</TableCell>
                    <TableCell align="left">{hotspotGroup.location.postalCode}</TableCell>
                    <TableCell align="left">{hotspotGroup.location.coordinate.latitude}</TableCell>
                    <TableCell align="left">{hotspotGroup.location.coordinate.longitude}</TableCell>
                    <TableCell align="left">
                      <a target="_blank" className={styles.tableLink} onClick={() => window.open("/app/hotspot-groups/" + hotspotGroup.id, '_blank')}>
                        View Hotspots
                      </a>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={9} align="center">
                    <p>No Hotspot Groups</p>
                    <Button onClick={() => dispatch(openCreateHotspotGroupModal())} className={styles.gradientButton} style={{marginBottom: 20}}>Create Hotspot Group</Button>
                  </TableCell>
                </TableRow>
              )
            }
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                colSpan={7}
                count={state.pagination ? state.pagination.totalElements ? state.pagination.totalElements : 0 : 0}
                rowsPerPage={state.rowsPerPage}
                page={state.page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      {
        state.createHotspotGroupModalOpen  &&
        <CreateHotspotGroup createHotspotGroupModalOpen={state.createHotspotGroupModalOpen} />
      }
      {
        state.editHotspotGroupModalOpen  &&
        <EditHotspotGroup hotspotGroup={state.selectedId ? state.hotspotGroups.find(hotspotGroup => hotspotGroup.id == state.selectedId) : null} />
      }
      {
        state.deleteHotspotGroupModalOpen  &&
        <ConfirmDelete hotspotGroup={state.selectedId ? state.hotspotGroups.find(hotspotGroups => hotspotGroups.id == state.selectedId) : null} />
      }
    </div>
  );
}
