import { createSlice } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading';
import * as Api from "../../app/Api";

export const hotspotSlice = createSlice({
  name: 'hotspot',
  initialState: {
    hotspotGroup: null,
    status: 'idle',
    createHotspotModalOpen: false,
    editHotspotModalOpen: false,
    deleteHotspotModalOpen: false,
    selectedId: null
  },
  reducers: {
    openCreateHotspotModal: (state) => {
      state.createHotspotModalOpen = true;
    },
    closeCreateHotspotModal: (state) => {
      state.createHotspotModalOpen = false;
    },
    openEditHotspotModal: (state) => {
      state.editHotspotModalOpen = true;
    },
    closeEditHotspotModal: (state) => {
      state.editHotspotModalOpen = false;
    },
    openDeleteHotspotModal: (state) => {
      state.deleteHotspotModalOpen = true;
    },
    closeDeleteHotspotModal: (state) => {
      state.deleteHotspotModalOpen = false;
      state.selectedId = null;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setSelectedId: (state, action) => {
      state.selectedId = action.payload;
    },
    getHotspots: (state, action) => {
      state.hotspotGroup = {
        ...action.payload,
        hotspots: [
          ...action.payload.hotspots
        ]
      };
    },
  },
});

export const { openCreateHotspotModal, closeCreateHotspotModal, openEditHotspotModal, closeEditHotspotModal, openDeleteHotspotModal, closeDeleteHotspotModal, setStatus, setSelectedId, getHotspots } = hotspotSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.hotspot.value)`
export const selectState = state => state.hotspot;

export const handleGetHotspotsByHotspotGroup = (id) => async (dispatch, getState) => {
  dispatch(showLoading());
  dispatch(setStatus("success"));
  try {
    const res = await Api.getHotspotsByHotspotGroup(id);
    if (res.status == 401) {
      Api.logout();
      throw res.error;
    }
    dispatch(getHotspots(res));
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(hideLoading());
  }
};

export const getPlaceLongName = (address_components, type) => {
  var component = address_components.find(c => c.types.includes(type))
  if (component != null) {
    return component.long_name
  }
  return null
}


export default hotspotSlice.reducer;
