import React from 'react';
import styles from './Header.module.css';
import logo from "../../images/logo.png";

const getTitle = () => {
	let title = "";
	switch(window.location.pathname) {
        case '/app/home':
            title = "Rider Admin";
            break;
        default:
			title = "Rider Admin";
			break;
    }
	return title;
}

export function Header() {
  
  return (
    <div className={styles.headerContainer}>
        <img src={logo} alt="logo" className={styles.logo} />
        <div className={styles.titleContainer}>
            <span className={styles.title}>{getTitle()}</span>
        </div>
    </div>
  );
}
