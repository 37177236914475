import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox, Button, Breadcrumbs, Typography, Link, Toolbar } from "@material-ui/core";
import { CreateHotspot } from './CreateHotspot';
import { EditHotspot } from './EditHotspot';
import { ConfirmDelete } from './ConfirmDelete';
import { TableToolbar } from './TableToolbar';
import { openCreateHotspotModal, openEditHotspotModal, handleGetHotspotsByHotspotGroup, selectState, setSelectedId, openDeleteHotspotModal } from './hotspotSlice';
import styles from './Hotspot.module.css';

export function Hotspot(props) {
  const state = useSelector(selectState);
  const dispatch = useDispatch();
  const { id } = useParams();
  useEffect(() => { 
    if (state.status === 'idle') {
      dispatch(handleGetHotspotsByHotspotGroup(id));
    }
  });

  return (
    <div className={styles.container}>
      {
        state.hotspotGroup && 
        <Breadcrumbs separator="›" aria-label="breadcrumb" className={styles.breadcrumb}>
          <Link color="inherit" href="/app/hotspot-groups">
            Hotspot Groups
          </Link>
          <Typography color="textPrimary">View Hotspots</Typography>
        </Breadcrumbs>
      }
      <TableContainer component={Paper} className={styles.denseTable}>
        <Toolbar>
          <Typography variant="h6" id="tableTitle" component="div" style={{flex: '1 1 100%'}}>
            Hotspot Group
          </Typography>
        </Toolbar>
        <Table size="small">
          <TableHead className={styles.tableHeader}>
            <TableRow>
              <TableCell align="left" className={styles.tableHeaderNarrow}>ID</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Address</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Postal Code</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Latitude</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Longitude</TableCell>
            </TableRow>
          </TableHead>
          {
            state.hotspotGroup &&
            <TableBody>
              <TableRow>
                <TableCell align="left" className={styles.tableCellNarrow}>{state.hotspotGroup.id}</TableCell>
                <TableCell align="left">{state.hotspotGroup.location.address}</TableCell>
                <TableCell align="left">{state.hotspotGroup.location.postalCode}</TableCell>
                <TableCell align="left">{state.hotspotGroup.location.coordinate.latitude}</TableCell>
                <TableCell align="left">{state.hotspotGroup.location.coordinate.longitude}</TableCell>
              </TableRow>
            </TableBody>
          }
        </Table>
      </TableContainer>
      <TableContainer component={Paper} className={styles.denseTable}>
        <TableToolbar 
          numSelected={state.selectedId == null ? 0 : 1}
          createHotspot={() => dispatch(openCreateHotspotModal())}
          editHotspot={() => dispatch(openEditHotspotModal())}
          deleteHotspot={() => dispatch(openDeleteHotspotModal())}
        />
        <Table size="small">
          <TableHead className={styles.tableHeader}>
            <TableRow>
              <TableCell align="left" className={styles.tableHeader}>{" "}</TableCell>
              <TableCell align="left" className={styles.tableHeaderNarrow}>ID</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Name</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Address</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Postal Code</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Latitude</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Longitude</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              state.hotspotGroup && state.hotspotGroup.hotspots.length > 0 ? (
                state.hotspotGroup.hotspots.map((hotspot) => (
                  <TableRow 
                    key={hotspot.id}
                    hover
                    onClick={() => dispatch(setSelectedId(state.selectedId == hotspot.id ? null : hotspot.id))}
                    role="checkbox"
                    aria-checked={state.selectedId == hotspot.id ? true : false}
                    selected={state.selectedId == hotspot.id ? true : false}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox 
                        color="primary"
                        checked={state.selectedId == hotspot.id ? true : false}
                      />
                    </TableCell>
                    <TableCell align="left" className={styles.tableCellNarrow}>{hotspot.id}</TableCell>
                    <TableCell align="left">{hotspot.location.name}</TableCell>
                    <TableCell align="left">{hotspot.location.address}</TableCell>
                    <TableCell align="left">{hotspot.location.postalCode}</TableCell>
                    <TableCell align="left">{hotspot.location.coordinate.latitude}</TableCell>
                    <TableCell align="left">{hotspot.location.coordinate.longitude}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={9} align="center">
                    <p>No Hotspot</p>
                    <Button onClick={() => dispatch(openCreateHotspotModal())} className={styles.gradientButton} style={{marginBottom: 20}}>Create Hotspot</Button>
                  </TableCell>
                </TableRow>
              )
            }
          </TableBody>
        </Table>
      </TableContainer>
      {
        state.createHotspotModalOpen  &&
        <CreateHotspot createHotspotModalOpen={state.createHotspotModalOpen} hotspotGroup={state.hotspotGroup} />
      }
      {
        state.editHotspotModalOpen  &&
        <EditHotspot hotspot={state.selectedId ? state.hotspotGroup.hotspots.find(hotspot => hotspot.id == state.selectedId) : null} hotspotGroup={state.hotspotGroup} />
      }
      {
        state.deleteHotspotModalOpen &&
        <ConfirmDelete hotspot={state.selectedId ? state.hotspotGroup.hotspots.find(hotspot => hotspot.id == state.selectedId) : null} hotspotGroup={state.hotspotGroup} />
      }
    </div>
  );
}
