import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox, TablePagination, TableFooter, InputLabel, FormControl, MenuItem, Select } from "@material-ui/core";
import { EditDeliveritDeliveryRate } from './EditDeliveritDeliveryRate';
import { TableToolbar } from './TableToolbar';
import { openEditDeliveritDeliveryRateModal, handleGetDeliveritDeliveryRates, selectState, setSelectedId, setPage, setRowsPerPage } from './deliveritDeliveryRateSlice';
import { getDeliveryPlatforms } from "../../app/Api";
import TablePaginationActions from './TablePaginationActions';
import styles from './DeliveritDeliveryRate.module.css';

export function DeliveritDeliveryRate(props) {
  const state = useSelector(selectState);
  const dispatch = useDispatch();
  
  useEffect(() => {
    if (state.status === 'idle') {
      dispatch(handleGetDeliveritDeliveryRates());
    }
  });

  const handleChangePage = (event, newPage) => {
    dispatch(setPage(newPage));
    dispatch(handleGetDeliveritDeliveryRates());
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setRowsPerPage(parseInt(event.target.value, 10)));
    dispatch(setPage(0));
    dispatch(handleGetDeliveritDeliveryRates());
  };

  const deliveryPlatformOnChange = (platform) => {
    window.location = `/app/${platform.toLowerCase()}-delivery-rates`
  };

  return (
    <div className={styles.container}>
      <FormControl variant="outlined" style={{marginBottom: 20, minWidth: 200}}>
        <InputLabel id="platform-label">Delivery Platform</InputLabel>
        <Select
          labelId="platform-label"
          id="delivery-platform"
          value="DELIVERIT"
          label="Delivery Platform"
          onChange={e => deliveryPlatformOnChange(e.target.value) }
        >
          {
            getDeliveryPlatforms().map((platform) => (
              <MenuItem value={platform}>{platform}</MenuItem>
            ))
          }
        </Select>
      </FormControl>
      <TableContainer component={Paper} className={styles.denseTable}>
        <TableToolbar 
          numSelected={state.selectedId == null ? 0 : 1}
          editDeliveritDeliveryRate={() => dispatch(openEditDeliveritDeliveryRateModal())}
        />
        <Table size="small">
          <TableHead className={styles.tableHeader}>
            <TableRow>
              <TableCell align="left" className={styles.tableHeader}>{" "}</TableCell>
              <TableCell align="left" className={styles.tableHeaderNarrow}>ID</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Name</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Base Rate (RM)</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Base Tier2 Rate (RM)</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Dropoff Tier 1 (RM)</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Bonus Rate per KM (RM)</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Bonus Min Distance (KM)</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Bonus Max Distance (KM)</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Average Order An Hour Threshold</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              state.deliveritDeliveryRates.length > 0 ? (
                state.deliveritDeliveryRates.map((deliveritDeliveryRate) => (
                  <TableRow 
                    key={deliveritDeliveryRate.id}
                    hover
                    onClick={() => dispatch(setSelectedId(state.selectedId == deliveritDeliveryRate.id ? null : deliveritDeliveryRate.id))}
                    role="checkbox"
                    aria-checked={state.selectedId == deliveritDeliveryRate.id ? true : false}
                    selected={state.selectedId == deliveritDeliveryRate.id ? true : false}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox 
                        color="primary"
                        checked={state.selectedId == deliveritDeliveryRate.id ? true : false}
                      />
                    </TableCell>
                    <TableCell align="left" className={styles.tableCellNarrow}>{deliveritDeliveryRate.id}</TableCell>
                    <TableCell align="left">{deliveritDeliveryRate.name}</TableCell>
                    <TableCell align="left">{deliveritDeliveryRate.base / 100}</TableCell>
                    <TableCell align="left">{deliveritDeliveryRate.baseTier2 / 100}</TableCell>
                    <TableCell align="left">{deliveritDeliveryRate.dropOffTier1 / 100}</TableCell>
                    <TableCell align="left">{deliveritDeliveryRate.bonusRate / 100}</TableCell>
                    <TableCell align="left">{deliveritDeliveryRate.bonusMinDistance}</TableCell>
                    <TableCell align="left">{deliveritDeliveryRate.bonusMaxDistance}</TableCell>
                    <TableCell align="left">{deliveritDeliveryRate.averageOrderAnHourThreshold}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={9} align="center">
                    <p>No Deliverit Delivery Rates</p>
                  </TableCell>
                </TableRow>
              )
            }
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                colSpan={7}
                count={state.pagination ? state.pagination.totalElements ? state.pagination.totalElements : 0 : 0}
                rowsPerPage={state.rowsPerPage}
                page={state.page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      {
        state.editDeliveritDeliveryRateModalOpen  &&
        <EditDeliveritDeliveryRate deliveritDeliveryRate={state.selectedId ? state.deliveritDeliveryRates.find(deliveritDeliveryRate => deliveritDeliveryRate.id == state.selectedId) : null} />
      }
    </div>
  );
}
