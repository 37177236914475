import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox, TablePagination, TableFooter, Button, Tabs, Tab } from "@material-ui/core";
import { CreateBatchNotification } from './CreateBatchNotification';
import { ConfirmDelete } from './ConfirmDelete';
import { TableToolbar } from './TableToolbar';
import { openCreateBatchNotificationModal, openDeleteBatchNotificationModal, handleGetBatchNotifications, selectState, setSelectedId, setPage, setRowsPerPage } from './batchNotificationSlice';
import TablePaginationActions from './TablePaginationActions';
import Moment from 'moment';
import styles from './BatchNotification.module.css';

export function BatchNotification(props) {
  const state = useSelector(selectState);
  const [selectedTab, setSelectedTab] = useState(0);
  const dispatch = useDispatch();
  
  useEffect(() => {
    if (state.status === 'idle') {
      dispatch(handleGetBatchNotifications(selectedTab));
    }
  });

  const handleTabChange = (event, tabIndex) => {
    setSelectedTab(tabIndex);
    dispatch(setSelectedId(null));
    dispatch(handleGetBatchNotifications(tabIndex));
  };

  const handleChangePage = (event, newPage) => {
    dispatch(setPage(newPage));
    dispatch(handleGetBatchNotifications(selectedTab));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setRowsPerPage(parseInt(event.target.value, 10)));
    dispatch(setPage(0));
    dispatch(handleGetBatchNotifications(selectedTab));
  };

  return (
    <div className={styles.container}>
      <Paper className={styles.mb10}>
        <Tabs
          onChange={handleTabChange}
          value={selectedTab}
          variant="fullWidth"
          aria-label="Scheduled / Archived Tab"
        >
          <Tab label="Scheduled" />
          <Tab label="Archived" />
        </Tabs>
      </Paper>
      <TableContainer component={Paper} className={styles.denseTable}>
        <TableToolbar 
          numSelected={state.selectedId == null ? 0 : 1}
          selectedTab={selectedTab}
          createBatchNotification={() => dispatch(openCreateBatchNotificationModal())}
          deleteBatchNotification={() => dispatch(openDeleteBatchNotificationModal())}
        />
        <Table size="small">
          <TableHead className={styles.tableHeader}>
            <TableRow>
              <TableCell align="left" className={styles.tableHeader}>{" "}</TableCell>
              <TableCell align="left" className={styles.tableHeaderNarrow}>ID</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Title</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Body</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Created At</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Send At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              state.batchNotifications.length > 0 ? (
                state.batchNotifications.map((notification) => (
                  <TableRow 
                    key={notification.id}
                    hover
                    onClick={() => dispatch(setSelectedId(state.selectedId == notification.id ? null : notification.id))}
                    role="checkbox"
                    aria-checked={state.selectedId == notification.id ? true : false}
                    selected={state.selectedId == notification.id ? true : false}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox 
                        color="primary"
                        checked={state.selectedId == notification.id ? true : false}
                      />
                    </TableCell>
                    <TableCell align="left" className={styles.tableCellNarrow}>{notification.id}</TableCell>
                    <TableCell align="left">{selectedTab == 1 ? notification.pushNotification?.messageTitle : notification.messageTitle }</TableCell>
                    <TableCell align="left">{selectedTab == 1 ? notification.pushNotification?.messageBody : notification.messageBody}</TableCell>
                    <TableCell align="left">{selectedTab == 1 ? Moment(notification.pushNotification?.createdAt).toString() : Moment(notification.createdAt).toString()}</TableCell>
                    <TableCell align="left">{selectedTab == 1 ? notification.pushNotification?.sendAt ? Moment(notification.pushNotification?.sendAt).toString() : "Instant" : Moment(notification.sendAt).toString()}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={9} align="center">
                    <p>No Notification</p>
                    <Button onClick={() => dispatch(openCreateBatchNotificationModal())} className={styles.gradientButton} style={{marginBottom: 20}}>Broadcast Notification</Button>
                  </TableCell>
                </TableRow>
              )
            }
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                colSpan={7}
                count={state.pagination ? state.pagination.totalElements ? state.pagination.totalElements : 0 : 0}
                rowsPerPage={state.rowsPerPage}
                page={state.page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      {
        state.createBatchNotificationModalOpen  &&
        <CreateBatchNotification createBatchNotificationModalOpen={state.createBatchNotificationModalOpen} />
      }
      {
        state.deleteBatchNotificationModalOpen  &&
        <ConfirmDelete batchNotification={state.selectedId ? state.batchNotifications.find(batchNotification => batchNotification.id == state.selectedId) : null} />
      }
    </div>
  );
}
