import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox, TablePagination, TableFooter, Breadcrumbs, Typography, Link } from "@material-ui/core";
import { selectState, handleGetShiftsThisWeek, handlePreviousWeek, handleNextWeek, handleGetShiftTableCsv } from './shiftTableSlice';
import styles from './ShiftTable.module.css';
import { TableToolbar } from './TableToolbar';

export function ShiftTable(props) {
  const state = useSelector(selectState);
  const dispatch = useDispatch();

  useEffect(() => { 
    if (state.status === 'idle') {
      dispatch(handleGetShiftsThisWeek());
    }
  });

  return (
    <div className={styles.container}>
      <TableContainer component={Paper} className={styles.denseTable}>
        <TableToolbar
          changeToPreviousWeek={() => dispatch(handlePreviousWeek())}
          changeToNextWeek={() => dispatch(handleNextWeek())}
          downloadCsv={() => dispatch(handleGetShiftTableCsv())}
        />
        <Table size="small">
          <TableHead className={styles.tableHeader}>
            <TableRow>
              <TableCell align="left" className={styles.tableHeader}>BeepId</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Name</TableCell>
              {
                state.shiftSlots.length > 0 &&
                  state.shiftSlots.map((shiftSlot) => (
                    <TableCell key={'header'+shiftSlot.id} align="left" className={styles.tableHeader}>{shiftSlot.date + ' ' + shiftSlot.dayOfWeek + ' ' + shiftSlot.startTime + '-' + shiftSlot.endTime}</TableCell>
                ))
              }
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key='subtotal'>
              <TableCell align="left"></TableCell>
              <TableCell align="left">Total Count</TableCell>
              {
                state.shiftSlots.length > 0 &&
                  state.shiftSlots.map((shiftSlot) => (
                    <TableCell  key={'count'+shiftSlot.id} align="left">{state.shiftCount[shiftSlot.day]}</TableCell>
                  ))
              }
            </TableRow>
            {
              state.shiftRows.length > 0 ? (
                state.shiftRows.map((shiftRow) => (
                  <TableRow key={shiftRow.beepId}>
                    <TableCell align="left" className={styles.tableCellNarrow}>{shiftRow.id}</TableCell>
                    <TableCell align="left">{shiftRow.name}</TableCell>
                    {
                      state.shiftSlots.length > 0 &&
                        state.shiftSlots.map((shiftSlot) => (
                          <TableCell key={'row'+shiftSlot.id} align="left">{shiftRow[shiftSlot.day]}</TableCell>
                        ))
                    }
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={9} align="center">
                    <p>No Shifts</p>
                  </TableCell>
                </TableRow>
              )
            }
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
