import { createSlice } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading';
import * as Api from "../../app/Api";

export const withdrawalSlice = createSlice({
  name: 'withdrawal',
  initialState: {
    withdrawals: [],
    status: 'idle',
    pagination: {},
    page: 0,
    rowsPerPage: 10,
    selectedIds: []
  },
  reducers: {
    openConfirmWithdrawModal: (state) => {
      state.confirmWithdrawModalOpen = true;
    },
    closeConfirmWithdrawModal: (state) => {
      state.confirmWithdrawModalOpen = false;
      state.selectedIds = [];
    },
    openRejectWithdrawModal: (state) => {
      state.rejectWithdrawModalOpen = true;
    },
    closeRejectWithdrawModal: (state) => {
      state.rejectWithdrawModalOpen = false;
      state.selectedIds = [];
    },
    openDownloadReportModal: (state) => {
      state.downloadReportModalOpen = true;
    },
    closeDownloadReportModal: (state) => {
      state.downloadReportModalOpen = false;
    },
    openDownloadCashedOutReportModal: (state) => {
      state.downloadCashedOutReportModalOpen = true;
    },
    closeDownloadCashedOutReportModal: (state) => {
      state.downloadCashedOutReportModalOpen = false;
    },
    setSelectedIds: (state, action) => {
      state.selectedIds = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload;
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
    getWithdrawals: (state, action) => {
      state.withdrawals = action.payload;
      state.status = 'success'
    },
  },
});

export const { openConfirmWithdrawModal, closeConfirmWithdrawModal, openRejectWithdrawModal, closeRejectWithdrawModal, openDownloadReportModal, closeDownloadReportModal, openDownloadCashedOutReportModal, closeDownloadCashedOutReportModal, setSelectedIds, setPage, setRowsPerPage, setPagination, getWithdrawals } = withdrawalSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.withdrawal.value)`
export const selectState = state => state.withdrawal;

export const handleGetWithdrawals = (withdrawStatus) => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();

  try {
    const res = await Api.getWithdrawals(state.withdrawal.page, state.withdrawal.rowsPerPage, withdrawStatus);
    if (res.status === 401 || res.status == 403) {
      Api.logout();
      throw res.error;
    } else if (res.error) {
      throw res.error;
    };
    
    const { content, ...pagination } = res;
    let withdrawals = content;

    dispatch(setPagination(pagination));
    dispatch(getWithdrawals(withdrawals));
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(hideLoading());
  }
};

export default withdrawalSlice.reducer;
