import { createSlice } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading';
import * as Api from "../../app/Api";

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    users: [],
    status: 'idle',
    editUserModalOpen: false,
    createUserModalOpen: false,
    deleteUserModalOpen: false,
    createWithdrawalModalOpen: false,
    createNotficationModalOpen: false,
    pagination: {},
    page: 0,
    rowsPerPage: 10,
    selectedId: null
  },
  reducers: {
    openEditUserModal: (state) => {
      state.editUserModalOpen = true;
    },
    closeEditUserModal: (state) => {
      state.editUserModalOpen = false;
    },
    openCreateUserModal: (state) => {
      state.createUserModalOpen = true;
    },
    closeCreateUserModal: (state) => {
      state.createUserModalOpen = false;
    },
    openDeleteUserModal: (state) => {
      state.deleteUserModalOpen = true;
    },
    closeDeleteUserModal: (state) => {
      state.deleteUserModalOpen = false;
    },
    openCreateWithdrawalModal: (state) => {
      state.createWithdrawalModalOpen = true;
    },
    closeCreateWithdrawalModal: (state) => {
      state.createWithdrawalModalOpen = false;
    },
    openCreateNotificationModal: (state) => {
      state.createNotficationModalOpen = true;
    },
    closeCreateNotificationModal: (state) => {
      state.createNotficationModalOpen = false;
    },
    setSelectedId: (state, action) => {
      state.selectedId = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload;
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
    getUsers: (state, action) => {
      state.users = action.payload;
      state.status = 'success'
    },
  },
});

export const { openEditUserModal, closeEditUserModal, openCreateUserModal, closeCreateUserModal, openDeleteUserModal, closeDeleteUserModal, openCreateWithdrawalModal, closeCreateWithdrawalModal, openCreateNotificationModal, closeCreateNotificationModal, setSelectedId, setPage, setRowsPerPage, setPagination, getUsers } = userSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.user.value)`
export const selectState = state => state.user;

export const handleGetUsers = () => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();

  try {
    const res = await Api.getUsers(state.user.page, state.user.rowsPerPage);
    if (res.status === 401 || res.status == 403) {
      Api.logout();
      throw res.error;
    } else if (res.error) {
      throw res.error;
    };
    
    const { content, ...pagination } = res;
    let users = content;

    dispatch(setPagination(pagination));
    dispatch(getUsers(users));
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(hideLoading());
  }
};

export const handleSearchUsers = (searchValue, filterName) => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();

  try {
    const res = filterName == "platformId" ? await Api.searchUsersByPlatformId(searchValue, state.user.page, state.user.rowsPerPage) : await Api.searchUsers(searchValue, state.user.page, state.user.rowsPerPage);
    if (res.status === 401 || res.status == 403) {
      Api.logout();
      throw res.error;
    } else if (res.error) {
      throw res.error;
    };
    
    const { content, ...pagination } = res;
    let users = content;

    dispatch(setPagination(pagination));
    dispatch(getUsers(users));
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(hideLoading());
  }
};


export default userSlice.reducer;
