import React from 'react';
import styles from './Home.module.css';
import { Grid } from "@material-ui/core";

export function Home() {
  return (
    <div className={styles.container}>
      <h3 className={styles.subtitle}>Dashboard</h3>
      <Grid container spacing={5}>
        <Grid item xs={12} sm={6} md={4}>
        </Grid>
      </Grid>
    </div>
  );
}
