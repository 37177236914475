import { createSlice } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading';
import * as Api from "../../app/Api";

export const loginSlice = createSlice({
  name: 'login',
  initialState: {
    userName: "",
    password: "",
    error: false,
    errorMessage: "",
    user: null
  },
  reducers: {
    onValueChange: (state, action) => {
      state[action.payload.name] = action.payload.value;
    },
    validateForm: (state) => {
      if (state.userName == "" || state.password == "") {
        state.error = true;
        state.errorMessage = "All fields are required";
        return;
      }
      state.error = false;
      state.errorMessage = "";
    },
    loginSuccess: (state, action) => {
      state.user = action.payload.value;
    },
    showError: (state, action) => {
      state.error = true;
      state.errorMessage = action.payload;
    },
  },
});

export const { onValueChange, validateForm, loginSuccess, showError } = loginSlice.actions;

export const handleLogin = (data) => async (dispatch) => {
  try {
    const res = await Api.login(data);
    if (res.status == 400) {
      try {
        const errorMessage = await res.text();
        dispatch(showError(errorMessage));
      } catch (e) {
        dispatch(showError("There was an error processing your request"));
      }
    } else if (res.status == 401) {
      dispatch(showError("Email and password does not match"));
    } else if (res.error) {
      throw res.error;
    } else {
      const resObject = await res.json();
      if (resObject.accessToken) {
        localStorage.setItem("auth", JSON.stringify(resObject));
        dispatch(loginSuccess(resObject));
        dispatch(handleGetUserInfo());
      } else {
        dispatch(showError("Login failed"));
      }
    }
  } catch (err) {
    dispatch(showError(err));
  }
};

export const handleGetUserInfo = () => async (dispatch) => {
  try {
    const res = await Api.getUserInfo();
    if (res.error) {
      throw res.error;
    } 
    
    const resObject = await res.json();
    const currentAuth = JSON.parse(localStorage.auth);
    let newAuth = {
      ...currentAuth,
      ...resObject
    };
    localStorage.setItem("auth", JSON.stringify(newAuth));
    dispatch(loginSuccess(newAuth));
  } catch (err) {
    dispatch(showError(err));
  }
};

export const handleLoginFormSubmit = (history) => async (dispatch, getState) => {
  dispatch(showLoading());
  dispatch(validateForm());
  const state = getState();
  if (!state.login.error) {
    await dispatch(handleLogin({userName: state.login.userName, password: state.login.password}));
  }
  dispatch(hideLoading());
  history.push(`/`);
};

export const selectState = state => state.login;

export default loginSlice.reducer;
