import { Toolbar, Typography, Tooltip, IconButton, Button } from "@material-ui/core";
import { lighten, makeStyles } from '@material-ui/core/styles';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

const useToolbarStyles = makeStyles((theme) => ({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      flex: '1 1 100%',
    },
    toolTipContainer: {
        position: "absolute",
        right: 10
    }
}));

export const TableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { changeToPreviousWeek, changeToNextWeek, downloadCsv } = props;
    return (
        <Toolbar>
            <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                View Shifts Table
            </Typography>
            <div className={classes.toolTipContainer}>
                <Tooltip title="Previous Week" onClick={changeToPreviousWeek}>
                    <IconButton aria-label="previous">
                        <SkipPreviousIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Next Week" onClick={changeToNextWeek}>
                    <IconButton aria-label="next">
                        <SkipNextIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Download CSV" onClick={downloadCsv}>
                    <IconButton aria-label="download">
                        <CloudDownloadIcon />
                    </IconButton>
                </Tooltip>


            </div>
        </Toolbar>
    );
};