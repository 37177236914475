import React, { useState }  from 'react';
import { useDispatch } from 'react-redux';
import { showLoading, hideLoading } from 'react-redux-loading';
import * as Api from "../../app/Api";
import { closeRollbackUploadedFileModal, handleGetUploadedFiles } from './uploadedFileSlice';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText } from '@material-ui/core';

export function ConfirmRollback(props) {
    const { uploadedFile } = props;
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const submit = async () => {
        dispatch(showLoading());
        setLoading(true);
        try {
            const res = await Api.rollbackUploadedFile(uploadedFile.filename);
            if(res.error) throw res.error;
    
            dispatch(hideLoading());
            setLoading(false);
            dispatch(closeRollbackUploadedFileModal());
            dispatch(handleGetUploadedFiles());
        } catch (err) {
            alert(err);
        }
    }

    return (
        <div>
        <Dialog open={true} onClose={() => dispatch(closeRollbackUploadedFileModal())} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Rollback Uploaded File</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to rollback this file?
                </DialogContentText>  
            </DialogContent>
            <DialogActions>
                <Button onClick={() =>  dispatch(closeRollbackUploadedFileModal())} color="primary" disabled={loading} style={{opacity: loading ? 0.5 : 1}}>
                    Cancel
                </Button>
                <Button onClick={() => submit()} color="primary" disabled={loading} style={{opacity: loading ? 0.5 : 1}}>
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
        </div>
    );
}