import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { Modal, Paper, Grid, Fade, Button, Chip } from "@material-ui/core";
import { closeEditDeliveritDeliveryRateModal, handleGetDeliveritDeliveryRates } from './deliveritDeliveryRateSlice';
import * as Api from "../../app/Api";
import { Close } from "@material-ui/icons";
import styles from './DeliveritDeliveryRate.module.css';

export function EditDeliveritDeliveryRate(props) {
  const dispatch = useDispatch();
  const initialState = {
    form: {
      ...props.deliveritDeliveryRate,
      base: props.deliveritDeliveryRate.base / 100,
      baseTier2: props.deliveritDeliveryRate.baseTier2 / 100,
      dropOffTier1: props.deliveritDeliveryRate.dropOffTier1 / 100,
      perOrderBikeRate: props.deliveritDeliveryRate.perOrderBikeRate / 100,
      perOrderCarRate: props.deliveritDeliveryRate.perOrderCarRate / 100,
      bonusRate: props.deliveritDeliveryRate.bonusRate / 100
    },
    initDeliveritDeliveryRateInfo: false,
    error: false,
    errorMessage: ""
  };
  const [deliveritDeliveryRateState, _setDeliveritDeliveryRateState] = useStateWithCallbackLazy({
    ...initialState
  });
  const deliveritDeliveryRateStateRef = useRef(deliveritDeliveryRateState);

  const setDeliveritDeliveryRateState = data => {
    deliveritDeliveryRateStateRef.current = data;
    _setDeliveritDeliveryRateState(data);
  };

  const onFormValueChange = (data) => {
    setDeliveritDeliveryRateState({
      ...deliveritDeliveryRateState,
      form: {
        ...deliveritDeliveryRateState.form,
        [data.name]: data.value
      }
    })
  }

  const submit = async () => {
    let error = false;

    delete deliveritDeliveryRateState.form.version;
    for (var key in deliveritDeliveryRateState.form) {
      if (deliveritDeliveryRateState.form[key] == null) {
        error = true;
        setDeliveritDeliveryRateState({...deliveritDeliveryRateState, error: true, errorMessage: "All fields are required"});
        return;
      }
    }

    let payload = {
      ...deliveritDeliveryRateState.form,
      base: deliveritDeliveryRateState.form.base * 100,
      baseTier2: deliveritDeliveryRateState.form.baseTier2 * 100,
      dropOffTier1: deliveritDeliveryRateState.form.dropOffTier1 * 100,
      perOrderBikeRate: deliveritDeliveryRateState.form.perOrderBikeRate * 100,
      perOrderCarRate: deliveritDeliveryRateState.form.perOrderCarRate * 100,
      bonusRate: deliveritDeliveryRateState.form.bonusRate * 100
    }

    if (!error) {
      try {
        const res = await Api.updateDeliveritDeliveryRate(payload, deliveritDeliveryRateState.form.id);
        if(res.error) throw res.error;
        
        dispatch(handleGetDeliveritDeliveryRates());
        dispatch(closeEditDeliveritDeliveryRateModal());
      } catch (err) {
        setDeliveritDeliveryRateState({...deliveritDeliveryRateState, error: true, errorMessage: err});
      }
    }
  }

  return (
    <Modal
      open={true}
      aria-labelledby="add-order-group-modal"
      className={styles.modal}
    >
      <Paper className={styles.modalContainer}>
        <div className={styles.container}>
          <Close className={styles.closeButton} onClick={() => dispatch(closeEditDeliveritDeliveryRateModal())}/>
          <h3 className={styles.subtitle}>Edit Deliverit Delivery Rate</h3>
          <Fade in={deliveritDeliveryRateState.error}>
            <p className={styles.errorMessage}>{deliveritDeliveryRateState.errorMessage}</p>
          </Fade>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <p>Name</p>
              <Paper className={styles.textInputContainer}>
                <input
                  id="name"
                  name="name"
                  value={deliveritDeliveryRateState.form.name}
                  onChange={e => onFormValueChange({name: "name", value: e.target.value})}
                  placeholder="Name"
                  className={styles.textInput}
                />
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <p>Base Rate</p>
              <Paper className={styles.textInputContainer}>
                <input
                  id="base"
                  name="base"
                  value={deliveritDeliveryRateState.form.base}
                  onChange={e => onFormValueChange({name: "base", value: e.target.value})}
                  placeholder="Base Rate (RM)"
                  className={styles.textInput}
                />
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <p>Base Tier2 Rate</p>
              <Paper className={styles.textInputContainer}>
                <input
                  id="baseTier2"
                  name="baseTier2"
                  value={deliveritDeliveryRateState.form.baseTier2}
                  onChange={e => onFormValueChange({name: "baseTier2", value: e.target.value})}
                  placeholder="Base Tier2 Rate (RM)"
                  className={styles.textInput}
                />
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <p>Average Order An Hour Threshold</p>
              <Paper className={styles.textInputContainer}>
                <input
                  id="averageOrderAnHourThreshold"
                  name="averageOrderAnHourThreshold"
                  value={deliveritDeliveryRateState.form.averageOrderAnHourThreshold}
                  onChange={e => onFormValueChange({name: "averageOrderAnHourThreshold", value: e.target.value})}
                  placeholder="Average Order An Hour Threshold"
                  className={styles.textInput}
                />
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <p>Dropoff Tier 1</p>
              <Paper className={styles.textInputContainer}>
                <input
                  id="dropOffTier1"
                  name="dropOffTier1"
                  value={deliveritDeliveryRateState.form.dropOffTier1}
                  onChange={e => onFormValueChange({name: "dropOffTier1", value: e.target.value})}
                  placeholder="Dropoff Tier 1 (RM)"
                  className={styles.textInput}
                />
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <p>Bike Rate Per Order</p>
              <Paper className={styles.textInputContainer}>
                <input
                  id="perOrderBikeRate"
                  name="perOrderBikeRate"
                  value={deliveritDeliveryRateState.form.perOrderBikeRate}
                  onChange={e => onFormValueChange({name: "perOrderBikeRate", value: e.target.value})}
                  placeholder="Bike Rate Per Order (RM)"
                  className={styles.textInput}
                />
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <p>Car Rate Per Order</p>
              <Paper className={styles.textInputContainer}>
                <input
                  id="perOrderCarRate"
                  name="perOrderCarRate"
                  value={deliveritDeliveryRateState.form.perOrderCarRate}
                  onChange={e => onFormValueChange({name: "perOrderCarRate", value: e.target.value})}
                  placeholder="Car Rate Per Order (RM)"
                  className={styles.textInput}
                />
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <p>Bonus Min Distance (KM)</p>
              <Paper className={styles.textInputContainer}>
                <input
                  id="bonusMinDistance"
                  name="bonusMinDistance"
                  value={deliveritDeliveryRateState.form.bonusMinDistance}
                  onChange={e => onFormValueChange({name: "bonusMinDistance", value: e.target.value})}
                  placeholder="Bonus Min Distance (KM)"
                  className={styles.textInput}
                />
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <p>Bonus Max Distance (KM)</p>
              <Paper className={styles.textInputContainer}>
                <input
                  id="bonusMaxDistance"
                  name="bonusMaxDistance"
                  value={deliveritDeliveryRateState.form.bonusMaxDistance}
                  onChange={e => onFormValueChange({name: "bonusMaxDistance", value: e.target.value})}
                  placeholder="Bonus Max Distance (KM)"
                  className={styles.textInput}
                />
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <p>Bonus Rate per KM (RM)</p>
              <Paper className={styles.textInputContainer}>
                <input
                  id="bonusRate"
                  name="bonusRate"
                  value={deliveritDeliveryRateState.form.bonusRate}
                  onChange={e => onFormValueChange({name: "bonusRate", value: e.target.value})}
                  placeholder="Bonus Rate per KM (RM)"
                  className={styles.textInput}
                />
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <div className={styles.submitButtonContainer}>
                <Button onClick={() => submit()} className={styles.gradientButton}>Submit</Button>
              </div>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Modal>
  );
}
