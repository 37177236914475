import React, { Fragment } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import styles from './Layout.module.css';
import { Header } from '../header/Header';
import { Sidebar } from '../sidebar/Sidebar';
import { Home } from '../../features/home/Home';
import { Payment } from '../../features/payment/Payment';
import { WeeklyPayment } from '../../features/weeklyPayment/WeeklyPayment';
import { UploadedFile } from '../../features/uploadedFile/UploadedFile';
import { User } from '../../features/user/User';
import { BeepDeliveryRate } from '../../features/beepDeliveryRate/BeepDeliveryRate';
import { DeliveritDeliveryRate } from '../../features/deliveritDeliveryRate/DeliveritDeliveryRate';
import { ShiftGroup } from '../../features/shiftGroup/ShiftGroup';
import { Shift } from '../../features/shift/Shift';
import { BatchNotification } from '../../features/batchNotification/BatchNotification';
import { HotspotGroup } from '../../features/hotspotGroup/HotspotGroup';
import { Hotspot } from '../../features/hotspot/Hotspot';
import { Withdrawal } from '../../features/withdrawal/Withdrawal';
import { UserPayment } from '../../features/userPayment/UserPayment';
import { ArchivedDailyWork } from '../../features/archivedDailyWork/ArchivedDailyWork';
import { Redeem } from '../../features/redeem/Redeem';
import { ShiftTable } from '../../features/shiftTable/ShiftTable';

export function Layout() {
  return (
    <div className={styles.root}>
		<Router>
			<Fragment>
				<Header />
				<Sidebar />
				<div className={styles.content}>
					<Switch>
						<Route exact path="/app/home" component={Home} />
						<Route exact path="/app/payments" component={Payment} />
						<Route exact path="/app/weekly-payments" component={WeeklyPayment} />
						<Route exact path="/app/uploaded-files" component={UploadedFile} />
						<Route exact path="/app/withdrawals" component={props => (<Withdrawal {...props} withdrawStatus={"CASHING_OUT"} />)} />
						<Route exact path="/app/withdrawal-history" component={props => (<Withdrawal {...props} withdrawStatus={"CASHED_OUT"} />)} />
						<Route exact path="/app/withdrawal-rejected" component={props => (<Withdrawal {...props} withdrawStatus={"REJECTED"} />)} />
						<Route exact path="/app/shift-groups" component={ShiftGroup} />
						<Route exact path="/app/shift-groups/:id" component={Shift} />
						<Route exact path="/app/batch-notifications" component={BatchNotification} />
						<Route exact path="/app/hotspot-groups" component={HotspotGroup} />
						<Route exact path="/app/hotspot-groups/:id" component={Hotspot} />
						<Route exact path="/app/beep-delivery-rates" component={BeepDeliveryRate} />
						<Route exact path="/app/deliverit-delivery-rates" component={DeliveritDeliveryRate} />
						<Route exact path="/app/users" component={User} />
						<Route exact path="/app/user-payments/:id" component={UserPayment} />
						<Route exact path="/app/archived-daily-works" component={ArchivedDailyWork} />
						<Route exact path="/app/redeems" component={Redeem} />
						<Route exact path="/app/shift-table" component={ShiftTable} />
					</Switch>
				</div>
			</Fragment>
		</Router>
    </div>
  );
}
