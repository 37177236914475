import { createSlice } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading';
import { format } from 'date-fns'
import * as Api from "../../app/Api";

export const archivedDailyWorkSlice = createSlice({
  name: 'archivedDailyWork',
  initialState: {
    archivedDailyWorks: [],
    status: 'idle',
    pagination: {},
    page: 0,
    rowsPerPage: 10,
    selectedId: null
  },
  reducers: {
    setSelectedId: (state, action) => {
      state.selectedId = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload;
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
    getArchivedDailyWorks: (state, action) => {
      state.archivedDailyWorks = action.payload;
      state.selectedId = null;
      state.status = 'success'
    },
  },
});

export const { setSelectedId, setPage, setRowsPerPage, setPagination, getArchivedDailyWorks } = archivedDailyWorkSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.archivedDailyWork.value)`
export const selectState = state => state.archivedDailyWork;

export const handleGetArchivedDailyWorks = () => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();
  try {
    const res = await Api.getArchivedDailyWorks(state.archivedDailyWork.page, state.archivedDailyWork.rowsPerPage, format(new Date(new Date().setFullYear(new Date().getFullYear() - 1)), 'yyyy-MM-dd'), format(new Date(new Date().setFullYear(new Date().getFullYear() + 1)), 'yyyy-MM-dd'));
    if (res.status === 401 || res.status == 403) {
      Api.logout();
      throw res.error;
    } else if (res.error) {
      throw res.error;
    };
    
    const { content, ...pagination } = res;
    let archivedDailyWorks = content;

    dispatch(setPagination(pagination));
    dispatch(getArchivedDailyWorks(archivedDailyWorks));
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(hideLoading());
  }
};


export default archivedDailyWorkSlice.reducer;
