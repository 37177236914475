import { createSlice } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading';
import * as Api from "../../app/Api";

export const batchNotificationSlice = createSlice({
  name: 'batchNotification',
  initialState: {
    batchNotifications: [],
    status: 'idle',
    createBatchNotificationModalOpen: false,
    editBatchNotificationModalOpen: false,
    deleteBatchNotificationModalOpen: false,
    pagination: {},
    page: 0,
    rowsPerPage: 10,
    selectedId: null
  },
  reducers: {
    openCreateBatchNotificationModal: (state) => {
      state.createBatchNotificationModalOpen = true;
    },
    closeCreateBatchNotificationModal: (state) => {
      state.createBatchNotificationModalOpen = false;
    },
    openEditBatchNotificationModal: (state) => {
      state.editBatchNotificationModalOpen = true;
    },
    closeEditBatchNotificationModal: (state) => {
      state.editBatchNotificationModalOpen = false;
    },
    openDeleteBatchNotificationModal: (state) => {
      state.deleteBatchNotificationModalOpen = true;
    },
    closeDeleteBatchNotificationModal: (state) => {
      state.deleteBatchNotificationModalOpen = false;
    },
    setSelectedId: (state, action) => {
      state.selectedId = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload;
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
    getBatchNotifications: (state, action) => {
      state.batchNotifications = action.payload;
      state.status = 'success'
    },
  },
});

export const { openCreateBatchNotificationModal, closeCreateBatchNotificationModal, openEditBatchNotificationModal, closeEditBatchNotificationModal, openDeleteBatchNotificationModal, closeDeleteBatchNotificationModal, setSelectedId, setPage, setRowsPerPage, setPagination, getBatchNotifications } = batchNotificationSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.batchNotification.value)`
export const selectState = state => state.batchNotification;

export const handleGetBatchNotifications = (selectedTab) => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();
  let scheduled = selectedTab == 0 ? true : false;

  try {
    const res = await Api.getBatchNotifications(state.batchNotification.page, state.batchNotification.rowsPerPage, scheduled);
    if (res.status === 401 || res.status == 403) {
      Api.logout();
      throw res.error;
    } else if (res.error) {
      throw res.error;
    };
    
    const { content, ...pagination } = res;
    let batchNotifications = content;

    dispatch(setPagination(pagination));
    dispatch(getBatchNotifications(batchNotifications));
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(hideLoading());
  }
};

export default batchNotificationSlice.reducer;
