import React, { Fragment } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import LoadingBar from "react-redux-loading";
import { Helmet } from "react-helmet";
import { Layout } from './components/layout/Layout';
import { Login } from './features/login/Login';
import './App.css';

const PrivateRoute = ({ component, ...rest }) => {
  return (
    <Route
      {...rest} 
      render={props => (
        localStorage.getItem('auth') ? (React.createElement(component, props)) : (<Redirect to={{ pathname: '/login', state: { from: props.location } }} />)
      )}
    />
  );
};

const PublicRoute = ({ component, ...rest }) => {
  return (
    <Route
      {...rest} 
      render={props => (
        localStorage.getItem('auth') ? (<Redirect to={{pathname: '/'}} />) : (React.createElement(component, props))
      )}
    />
  );
};

function App() {

  return (
    <div className="App">
      <Helmet>
        <meta charSet="utf-8" />
        <title>DeliverIt Rider Admin Portal</title>
        <meta name="description" content="DeliverIt Rider Admin Portal" />
        <meta name="keywords" content="deliverit malaysia,deliverit,deliver it malaysia,deliver it,deliveritmy,delivery solution malaysia,delivery near me" />
        <meta property="og:title" content="DeliverIt Rider Admin Portal" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://parkitstatic.s3-ap-southeast-1.amazonaws.com/media/logo/parkit-logo-horizontal.png" />
        <meta property="og:url" content="https://rider-admin.deliveritmy.com" />
        <link rel="canonical" href="https://rider-admin.deliveritmy.com" />
      </Helmet>
      <Router>
        <Fragment>
          <LoadingBar style={{ backgroundColor: "#e86c6d" }} />
          <Switch>
            <Route exact path="/" render={() => <Redirect to="/app/home" />} />
            <Route exact path="/app" render={() => <Redirect to="/app/home" />} />
            <PublicRoute exact path="/login" component={Login} />
            <PrivateRoute path="/" component={Layout} />
          </Switch>
        </Fragment>
      </Router>
    </div>
  );
}

export default App;