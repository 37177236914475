import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import * as Api from "../../app/Api";
import { closeDownloadCashedOutReportModal, handleGetWithdrawals } from './withdrawalSlice';
import { DateRangePicker } from 'react-date-range';
import { Modal, Paper, Grid, Fade, Button } from "@material-ui/core";
import { format } from 'date-fns'
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { Close } from "@material-ui/icons";
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import styles from './Withdrawal.module.css';

export function DownloadCashedOutReport(props) {
  const { withdrawStatus } = props;
  const dispatch = useDispatch();

  const todayDate = new Date()
  const todayDateFormatted = todayDate.toISOString().split('T')[0]
  
  const initialState = {
    form: {
      from: undefined,
      to: undefined
    },
    error: false,
    errorMessage: ""
  };
  const [downloadCashedOutReportState, _setDownloadCashedOutReportState] = useStateWithCallbackLazy({
    ...initialState
  });
  const downloadReportStateRef = useRef(downloadCashedOutReportState);
  
  const setDownloadReportState = data => {
    downloadReportStateRef.current = data;
    _setDownloadCashedOutReportState(data);
  };
  
  const onDateRangeChange = (start, end) => {
    setDownloadReportState({
      ...downloadCashedOutReportState,
      form: {
        ...downloadCashedOutReportState.form,
        from: start,
        to: end
      }
    })
  }
  
  const submit = async () => {
    let error = false;
    
    if (downloadCashedOutReportState.form.from == "" || downloadCashedOutReportState.form.to == undefined) {
      error = true;
      setDownloadReportState({...downloadCashedOutReportState, error: true, errorMessage: "Start date or end date cannot be undefined."});
      return;
    }
    
    if (!error) {
      try {
        const res = await Api.getCashedOutRequestReports(downloadCashedOutReportState.form.from, downloadCashedOutReportState.form.to);
        if (res.status !== 200) {
          throw res.error;
        }

        let report = await res.blob();
        // 2. Create blob link to download
        const url = window.URL.createObjectURL(new Blob([report]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `cashed_out_report_${downloadCashedOutReportState.form.from}-${downloadCashedOutReportState.form.to}.csv`);
        // 3. Append to html page
        document.body.appendChild(link);
        // 4. Force download
        link.click();
        // 5. Clean up and remove the link
        link.parentNode.removeChild(link);
        
        dispatch(closeDownloadCashedOutReportModal());
        // dispatch(handleGetWithdrawals(withdrawStatus));
      } catch (err) {
        setDownloadReportState({...downloadCashedOutReportState, error: true, errorMessage: err});
      }
    }
  }
  
  return (
    <Modal
      open={props.downloadCashedOutReportModalOpen}
      aria-labelledby="download-report-modal"
      className={styles.modal}
    >
      <Paper className={styles.modalContainer}>
        <div className={styles.container}>
        <Close className={styles.closeButton} onClick={() => dispatch(closeDownloadCashedOutReportModal())}/>

        <h3 className={styles.subtitle}>Download Cashed Out Report <em style={{fontSize: 12}}>(All fields are required)</em></h3>
        
        <Fade in={downloadCashedOutReportState.error}>
        <p className={styles.errorMessage}>{downloadCashedOutReportState.errorMessage}</p>

        </Fade>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <p className={styles.inputLabel}>Date range</p>
              <Paper className={styles.textInputContainer}>
                <DateRangePicker
                  onChange={item => {
                      onDateRangeChange(
                        format(item.selection.startDate, 'yyyy-MM-dd'),
                        format(item.selection.endDate, 'yyyy-MM-dd')
                      )
                  }}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  months={1}
                  shownDate={todayDate}
                  ranges={[{ startDate: new Date(downloadCashedOutReportState.form.from), endDate: new Date(downloadCashedOutReportState.form.to), key: 'selection' }]}
                  scroll={{ enabled: true }}
                  direction="vertical"
                  calendarFocus="backwards"
                />
              </Paper>
            
              <div className={styles.submitButtonContainer}>
                <Button onClick={() => submit()} className={styles.gradientButton}>Download</Button>
              </div>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Modal>
  );
}