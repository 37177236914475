/*global google*/
import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { Modal, Paper, Grid, Fade, Button } from "@material-ui/core";
import { closeCreateHotspotGroupModal, getPlaceLongName } from './hotspotGroupSlice';
import * as Api from "../../app/Api";
import { Close } from "@material-ui/icons";
import styles from './HotspotGroup.module.css';

export function CreateHotspotGroup(props) {
  const dispatch = useDispatch();
  const initialState = {
    form: {
      location: {
        name: "",
        unitNumber: "",
        address: "",
        coordinate:  {
          latitude: "", 
          longitude: "" 
        },
        city: "",
        country: ""
      },
    },
    isPlaceChanged: false,
    autocompleteInit: false,
    error: false,
    errorMessage: ""
  };
  const [hotspotGroupState, _setHotspotGroupState] = useStateWithCallbackLazy({
    ...initialState
  });
  const hotspotGroupStateRef = useRef(hotspotGroupState);

  const setHotspotGroupState = data => {
    hotspotGroupStateRef.current = data;
    _setHotspotGroupState(data);
  };

  useEffect(() => {
    let locationAutocompleteInput = document.getElementById("locationAutocomplete");
    if (!hotspotGroupState.autocompleteInit && locationAutocompleteInput != null) {
      initializeGoogleAutocomplete("locationAutocomplete");
      setHotspotGroupState({
        ...hotspotGroupState,
        form: {
          ...hotspotGroupState.form
        },
        autocompleteInit: true,
      });
    }
  });

  const onPlaceChanged = (autocomplete, elementId) => {
    var place = autocomplete.getPlace();
    if (place) {
      if (elementId == "locationAutocomplete") {
        setHotspotGroupState({
          ...hotspotGroupStateRef.current,
          isPlaceChanged: true,
          form: {
            ...hotspotGroupStateRef.current.form,
            location: {
              ...hotspotGroupStateRef.current.form.location,
              address: `${place.name}, ${place.formatted_address}`,
              coordinate: {
                latitude: place.geometry.location.lat(),
                longitude: place.geometry.location.lng(),
              },
              street: getPlaceLongName(place.address_components, "street_address"),
              apartment: getPlaceLongName(place.address_components, "street_address"),
              state: getPlaceLongName(place.address_components, "administrative_area_level_1"),
              postalCode: getPlaceLongName(place.address_components, "postal_code"),
              city: getPlaceLongName(place.address_components, "locality"),
              country: getPlaceLongName(place.address_components, "country")
            }
          }
        });
      }
    }
  }

  const initializeGoogleAutocomplete = (elementId) => {
    let autocompleteInput = document.getElementById(elementId);
    let autocomplete = new google.maps.places.Autocomplete(
      autocompleteInput,
      {
        types: ["geocode", "establishment"],
        componentRestrictions: { country: ["my"] }
      }
    );

    google.maps.event.addListener(autocomplete, "place_changed", function() {
      onPlaceChanged(autocomplete, elementId);
    });
  }

  const submit = async () => {
    let error = false;

    if (hotspotGroupState.isPlaceChanged == false) {
      error = true;
      setHotspotGroupState({...hotspotGroupState, error: true, errorMessage: "Please select address from the drop down"});
      return;
    }

    for (var key in hotspotGroupState.form) {
      if (hotspotGroupState.form[key] == null || hotspotGroupState.form[key] == "") {
        error = true;
        setHotspotGroupState({...hotspotGroupState, error: true, errorMessage: "All fields are required"});
        return;
      }
    }

    if (hotspotGroupState.form.location.name == null || hotspotGroupState.form.location.name == "") {
      error = true;
      setHotspotGroupState({...hotspotGroupState, error: true, errorMessage: "Hotspot group name is required"});
      return;
    }

    if (hotspotGroupState.form.location.country == null || hotspotGroupState.form.location.country == "") {
      error = true;
      setHotspotGroupState({...hotspotGroupState, error: true, errorMessage: "Please re-select the pick up address from dropdown list"});
      return;
    }

    if (!error) {
      try {
        let payload = { ...hotspotGroupState.form };

        const res = await Api.createHotspotGroup(payload);
        if(res.error) throw res.error;
        window.location = `/app/hotspot-groups/${res.id}` 
      } catch (err) {
        setHotspotGroupState({...hotspotGroupState, error: true, errorMessage: err});
      }
    }
  }

  return (
    <Modal
      open={props.createHotspotGroupModalOpen}
      aria-labelledby="add-hotspot-group-modal"
      className={styles.modal}
    >
      <Paper className={styles.modalContainer}>
        <div className={styles.container}>
          <Close className={styles.closeButton} onClick={() => dispatch(closeCreateHotspotGroupModal())}/>
          <h3 className={styles.subtitle}>Create Hotspot Group <em style={{fontSize: 12}}>(All fields are required)</em></h3>
          <Fade in={hotspotGroupState.error}>
            <p className={styles.errorMessage}>{hotspotGroupState.errorMessage}</p>
          </Fade>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <p className={styles.inputLabel}>Name</p>
              <Paper className={styles.textInputContainer}>
                <input
                  id="name"
                  name="name"
                  value={hotspotGroupState.form.location.name}
                  onChange={e => setHotspotGroupState({
                    ...hotspotGroupState,
                    form: {
                      ...hotspotGroupState.form,
                      location: {
                        ...hotspotGroupState.form.location,
                        name: e.target.value
                      }
                    }
                  })}
                  placeholder="Hotspot Group Name"
                  className={styles.textInput}
                />
              </Paper>
              <p className={styles.inputLabel}>Address</p>
              <Paper className={styles.textInputContainer}>
                <input
                  id="locationAutocomplete"
                  name="locationAddress"
                  value={hotspotGroupState.form.location.address}
                  onChange={e => setHotspotGroupState({
                    ...hotspotGroupState,
                    isPlaceChanged: false,
                    form: {
                      ...hotspotGroupState.form,
                      location: {
                        ...hotspotGroupState.form.location,
                        address: e.target.value
                      }
                    }
                  })}
                  placeholder="Hotspot Group Address"
                  className={styles.textInput}
                />
              </Paper>
              <div className={styles.submitButtonContainer}>
                <Button onClick={() => submit()} className={styles.gradientButton}>Create</Button>
              </div>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Modal>
  );
}
