import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox, TablePagination, TableFooter, IconButton } from "@material-ui/core";
import { TableToolbar } from './TableToolbar';
import { handleGetRedeems, selectState, setSelectedId, setPage, setRowsPerPage, handleDownloadPayslip } from './redeemSlice';
import TablePaginationActions from './TablePaginationActions';
import styles from './Redeem.module.css';
import Moment from 'moment';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

export function Redeem(props) {
  const state = useSelector(selectState);
  const dispatch = useDispatch();
  
  useEffect(() => {
    if (state.status === 'idle') {
      dispatch(handleGetRedeems());
    }
  });

  const handleChangePage = (event, newPage) => {
    dispatch(setPage(newPage));
    dispatch(handleGetRedeems());
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setRowsPerPage(parseInt(event.target.value, 10)));
    dispatch(setPage(0));
    dispatch(handleGetRedeems());
  };

  return (
    <div className={styles.container}>
      <TableContainer component={Paper} className={styles.denseTable}>
        <TableToolbar 
          numSelected={state.selectedId == null ? 0 : 1}
        />
        <Table size="small">
          <TableHead className={styles.tableHeader}>
            <TableRow>
              <TableCell align="left" className={styles.tableHeader}>{" "}</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Created At</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Username</TableCell>
              <TableCell align="left" className={styles.tableHeader}>First Name</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Platform Ids</TableCell>
              <TableCell align="left" className={styles.tableHeaderNarrow}>Completed Order</TableCell>
              <TableCell align="left" className={styles.tableHeaderNarrow}>Peak Hour Time</TableCell>
              <TableCell align="left" className={styles.tableHeaderNarrow}>Transit Time (Hour)</TableCell>
              <TableCell align="left" className={styles.tableHeaderNarrow}>Redeemed Amount (RM)</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Download Payslip</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              state.redeems.length > 0 ? (
                state.redeems.map((redeem) => (
                  <TableRow 
                    key={redeem.id}
                    hover
                    onClick={() => dispatch(setSelectedId(state.selectedId == redeem.id ? null : redeem.id))}
                    role="checkbox"
                    aria-checked={state.selectedId == redeem.id ? true : false}
                    selected={state.selectedId == redeem.id ? true : false}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox 
                        color="primary"
                        checked={state.selectedId == redeem.id ? true : false}
                      />
                    </TableCell>
                    <TableCell align="left" className={styles.tableCell}>{Moment(redeem.createdAt).toString()}</TableCell>
                    <TableCell align="left" className={styles.tableCell}>{redeem.user.userName}</TableCell>
                    <TableCell align="left" className={styles.tableCell}>{redeem.user.firstName}</TableCell>
                    <TableCell align="left" className={styles.tableCell}>{redeem.user.platformIds.join(', ')}</TableCell>
                    <TableCell align="left" className={styles.tableCellNarrow}>{redeem.completedOrder}</TableCell>
                    <TableCell align="left" className={styles.tableCellNarrow}>{redeem.peakHourTime ? redeem.peakHourTime/3600.0 : ''}</TableCell>
                    <TableCell align="left" className={styles.tableCellNarrow}>{redeem.transitTime ? redeem.transitTime/3600.0 : ''}</TableCell>
                    <TableCell align="left" className={styles.tableCellNarrow}>{(redeem.currentRedeemedAmount/100.0).toFixed(2)}</TableCell>
                    <TableCell align="left">
                      <IconButton aria-label="download" onClick={() => dispatch(handleDownloadPayslip(redeem.id))}>
                        <CloudDownloadIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={9} align="center">
                    <p>No Redeems</p>
                  </TableCell>
                </TableRow>
              )
            }
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                colSpan={7}
                count={state.pagination ? state.pagination.totalElements ? state.pagination.totalElements : 0 : 0}
                rowsPerPage={state.rowsPerPage}
                page={state.page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
}
