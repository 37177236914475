import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox, TablePagination, TableFooter } from "@material-ui/core";
import { CreateShiftGroup } from './CreateShiftGroup';
import { EditShiftGroup } from './EditShiftGroup';
import { ConfirmDelete } from './ConfirmDelete';
import { TableToolbar } from './TableToolbar';
import { openEditShiftGroupModal, handleGetShiftGroups, selectState, setSelectedId, setPage, setRowsPerPage, openCreateShiftGroupModal, openDeleteShiftGroupModal } from './shiftGroupSlice';
import TablePaginationActions from './TablePaginationActions';
import { format } from 'date-fns'
import styles from './ShiftGroup.module.css';

export function ShiftGroup(props) {
  const state = useSelector(selectState);
  const dispatch = useDispatch();
  
  useEffect(() => {
    if (state.status === 'idle') {
      dispatch(handleGetShiftGroups());
    }
  });

  const handleChangePage = (event, newPage) => {
    dispatch(setPage(newPage));
    dispatch(handleGetShiftGroups());
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setRowsPerPage(parseInt(event.target.value, 10)));
    dispatch(setPage(0));
    dispatch(handleGetShiftGroups());
  };

  return (
    <div className={styles.container}>
      <TableContainer component={Paper} className={styles.denseTable}>
        <TableToolbar 
          numSelected={state.selectedId == null ? 0 : 1}
          createShiftGroup={() => dispatch(openCreateShiftGroupModal())}
          editShiftGroup={() => dispatch(openEditShiftGroupModal())}
          deleteShiftGroup={() => dispatch(openDeleteShiftGroupModal())}
        />
        <Table size="small">
          <TableHead className={styles.tableHeader}>
            <TableRow>
              <TableCell align="left" className={styles.tableHeader}>{" "}</TableCell>
              <TableCell align="left" className={styles.tableHeaderNarrow}>ID</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Start Time</TableCell>
              <TableCell align="left" className={styles.tableHeader}>End Time</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Published</TableCell>
              <TableCell align="left" className={styles.tableHeader}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              state.shiftGroups.length > 0 ? (
                state.shiftGroups.map((shiftGroup) => (
                  <TableRow 
                    key={shiftGroup.id}
                    hover
                    onClick={() => dispatch(setSelectedId(state.selectedId == shiftGroup.id ? null : shiftGroup.id))}
                    role="checkbox"
                    aria-checked={state.selectedId == shiftGroup.id ? true : false}
                    selected={state.selectedId == shiftGroup.id ? true : false}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox 
                        color="primary"
                        checked={state.selectedId == shiftGroup.id ? true : false}
                      />
                    </TableCell>
                    <TableCell align="left" className={styles.tableCellNarrow}>{shiftGroup.id}</TableCell>
                    <TableCell align="left">{format(new Date(shiftGroup.startTime), 'MMMM d, yyyy h:mm aa')}</TableCell>
                    <TableCell align="left">{format(new Date(shiftGroup.endTime), 'MMMM d, yyyy h:mm aa')}</TableCell>
                    <TableCell align="left">{shiftGroup.published ? "Yes" : "No"}</TableCell>
                    <TableCell align="left">
                      <a target="_blank" className={styles.tableLink} onClick={() => window.open("/app/shift-groups/" + shiftGroup.id, '_blank')}>
                        View Shifts
                      </a>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={9} align="center">
                    <p>No Shift Groups</p>
                  </TableCell>
                </TableRow>
              )
            }
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                colSpan={7}
                count={state.pagination ? state.pagination.totalElements ? state.pagination.totalElements : 0 : 0}
                rowsPerPage={state.rowsPerPage}
                page={state.page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      {
        state.createShiftGroupModalOpen  &&
        <CreateShiftGroup createShiftGroupModalOpen={state.createShiftGroupModalOpen} />
      }
      {
        state.editShiftGroupModalOpen  &&
        <EditShiftGroup shiftGroup={state.selectedId ? state.shiftGroups.find(shiftGroup => shiftGroup.id == state.selectedId) : null} />
      }
      {
        state.deleteShiftGroupModalOpen  &&
        <ConfirmDelete shiftGroup={state.selectedId ? state.shiftGroups.find(shiftGroups => shiftGroups.id == state.selectedId) : null} />
      }
    </div>
  );
}
