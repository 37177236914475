import { createSlice } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading';
import * as Api from "../../app/Api";

export const shiftSlice = createSlice({
  name: 'shift',
  initialState: {
    shiftGroup: null,
    shifts: [],
    status: 'idle',
    pagination: {},
    page: 0,
    rowsPerPage: 10,
    selectedId: null
  },
  reducers: {
    setSelectedId: (state, action) => {
      state.selectedId = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload;
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
    getShiftGroup: (state, action) => {
      state.shiftGroup = action.payload;
    },
    getShifts: (state, action) => {
      state.shifts = action.payload;
      state.selectedId = null;
      state.status = 'success'
    },
  },
});

export const { setSelectedId, setPage, setRowsPerPage, setPagination, getShiftGroup, getShifts } = shiftSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.shift.value)`
export const selectState = state => state.shift;

export const handleGetShiftGroup = (shiftGroupId) => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();
  try {
    const res = await Api.getShiftGroup(shiftGroupId);
    if (res.status === 401 || res.status == 403) {
      Api.logout();
      throw res.error;
    } else if (res.error) {
      throw res.error;
    };
    console.log(res);
    
    dispatch(getShiftGroup(res));
    dispatch(handleGetShiftsByShiftGroup(shiftGroupId));
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(hideLoading());
  }
};

export const handleGetShiftsByShiftGroup = (shiftGroupId) => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();
  try {
    const res = await Api.getShiftsByShiftGroup(shiftGroupId, state.shift.page, state.shift.rowsPerPage);
    if (res.status === 401 || res.status == 403) {
      Api.logout();
      throw res.error;
    } else if (res.error) {
      throw res.error;
    };
    
    console.log(res);
    const { content, ...pagination } = res;
    let shifts = content;

    dispatch(setPagination(pagination));
    dispatch(getShifts(shifts));
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(hideLoading());
  }
};

export const handleGetShiftsByShiftGroupAndUser = (shiftGroupId, userId, shiftGroupStartTime, shiftGroupEndTime) => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();
  try {
    const res = await Api.getShiftsByShiftGroupAndUser(shiftGroupId, state.shift.page, state.shift.rowsPerPage, userId, shiftGroupStartTime, shiftGroupEndTime);
    if (res.status === 401 || res.status == 403) {
      Api.logout();
      throw res.error;
    } else if (res.error) {
      throw res.error;
    };
    
    console.log(res);
    const { content, ...pagination } = res;
    let shifts = content;

    dispatch(setPagination(pagination));
    dispatch(getShifts(shifts));
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(hideLoading());
  }
};


export default shiftSlice.reducer;
