import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox, TablePagination, TableFooter, Breadcrumbs, Typography, Link, Toolbar, Tooltip, IconButton } from "@material-ui/core";
import { TableToolbar } from './TableToolbar';
import { handleGetShiftGroup, handleGetShiftsByShiftGroup, selectState, setSelectedId, setPage, setRowsPerPage, handleGetShiftsByShiftGroupAndUser } from './shiftSlice';
import TablePaginationActions from './TablePaginationActions';
import { format } from 'date-fns'
import styles from './Shift.module.css';
import FilterListIcon from '@material-ui/icons/FilterList';

export function Shift(props) {
  const state = useSelector(selectState);
  const [filterUserId, setFilterUserId] = useState("");
  const dispatch = useDispatch();
  const { id } = useParams();
  useEffect(() => { 
    if (state.status === 'idle') {
      dispatch(handleGetShiftGroup(id));
    }
  });

  const handleChangePage = (event, newPage) => {
    dispatch(setPage(newPage));
    dispatch(handleGetShiftsByShiftGroup(id));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setRowsPerPage(parseInt(event.target.value, 10)));
    dispatch(setPage(0));
    dispatch(handleGetShiftsByShiftGroup(id));
  };

  return (
    <div className={styles.container}>
      {
        state.hotspotGroup && 
        <Breadcrumbs separator="›" aria-label="breadcrumb" className={styles.breadcrumb}>
          <Link color="inherit" href="/app/shift-groups">
            Shift Groups
          </Link>
          <Typography color="textPrimary">View Shifts</Typography>
        </Breadcrumbs>
      }
      <TableContainer component={Paper} className={styles.denseTable}>
        <Toolbar>
          <Typography variant="h6" id="tableTitle" component="div" style={{flex: '1 1 100%'}}>
            Shift Group
          </Typography>
        </Toolbar>
        <Table size="small">
          <TableHead className={styles.tableHeader}>
            <TableRow>
              <TableCell align="left" className={styles.tableHeaderNarrow}>ID</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Start Time</TableCell>
              <TableCell align="left" className={styles.tableHeader}>End Time</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Published</TableCell>
            </TableRow>
          </TableHead>
          {
            state.shiftGroup &&
            <TableBody>
              <TableRow>
                <TableCell align="left" className={styles.tableCellNarrow}>{state.shiftGroup.id}</TableCell>
                <TableCell align="left">{format(new Date(state.shiftGroup.startTime), 'MMMM d, yyyy h:mm aa')}</TableCell>
                <TableCell align="left">{format(new Date(state.shiftGroup.endTime), 'MMMM d, yyyy h:mm aa')}</TableCell>
                <TableCell align="left">{state.shiftGroup.published ? "Yes" : "No"}</TableCell>
              </TableRow>
            </TableBody>
          }
        </Table>
      </TableContainer>
      <Paper className={styles.textInputContainer} style={{display: "flex", flexDirection: "row"}}>
        <input
          id="filterByUser"
          name="filterByUser"
          value={filterUserId}
          onChange={e => setFilterUserId(e.target.value)}
          placeholder="Filter by User's Id"
          className={styles.textInput}
        />
        <Tooltip title="Filter By User ID" onClick={() => 
          dispatch(handleGetShiftsByShiftGroupAndUser(id, filterUserId, format(new Date(new Date().setFullYear(new Date().getFullYear() - 1)), 'yyyy-MM-dd'), format(new Date(new Date().setFullYear(new Date().getFullYear() + 1)), 'yyyy-MM-dd')))}>
          <IconButton aria-label="filter" style={{padding: 10}}>
              <FilterListIcon />
          </IconButton>
        </Tooltip>
      </Paper>
      <TableContainer component={Paper} className={styles.denseTable}>
        <TableToolbar 
          numSelected={state.selectedId == null ? 0 : 1}
        />
        <Table size="small">
          <TableHead className={styles.tableHeader}>
            <TableRow>
              <TableCell align="left" className={styles.tableHeader}>{" "}</TableCell>
              <TableCell align="left" className={styles.tableHeaderNarrow}>ID</TableCell>
              <TableCell align="left" className={styles.tableHeaderNarrow}>User ID</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Platform IDs</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Email</TableCell>
              <TableCell align="left" className={styles.tableHeader}>First Name</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Last Name</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Contact Number</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              state.shifts.length > 0 ? (
                state.shifts.map((shift) => (
                  <TableRow 
                    key={shift.id}
                    hover
                    onClick={() => dispatch(setSelectedId(state.selectedId == shift.id ? null : shift.id))}
                    role="checkbox"
                    aria-checked={state.selectedId == shift.id ? true : false}
                    selected={state.selectedId == shift.id ? true : false}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox 
                        color="primary"
                        checked={state.selectedId == shift.id ? true : false}
                      />
                    </TableCell>
                    <TableCell align="left" className={styles.tableCellNarrow}>{shift.id}</TableCell>
                    <TableCell align="left" className={styles.tableCellNarrow}>{shift.user.id}</TableCell>
                    <TableCell align="left">{shift.user.platformIds?.join(', ')}</TableCell>
                    <TableCell align="left">{shift.user.userName}</TableCell>
                    <TableCell align="left">{shift.user.firstName}</TableCell>
                    <TableCell align="left">{shift.user.lastName}</TableCell>
                    <TableCell align="left">{shift.user.contactNumber}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={9} align="center">
                    <p>No Rider in this Shift Group</p>
                  </TableCell>
                </TableRow>
              )
            }
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                colSpan={7}
                count={state.pagination ? state.pagination.totalElements ? state.pagination.totalElements : 0 : 0}
                rowsPerPage={state.rowsPerPage}
                page={state.page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
}
