import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { Modal, Paper, Grid, Fade, Button, Chip } from "@material-ui/core";
import { closeEditUserModal, handleGetUsers, handleSearchUsers } from './userSlice';
import * as Api from "../../app/Api";
import { Close } from "@material-ui/icons";
import styles from './User.module.css';

export function EditUser(props) {
  const dispatch = useDispatch();
  const initialState = {
    form: {
      ...props.user,
      platformIds: props.user.platformIds ? props.user.platformIds : []
    },
    platformIdInputValue: "",
    initUserInfo: false,
    error: false,
    errorMessage: ""
  };
  const [userState, _setUserState] = useStateWithCallbackLazy({
    ...initialState
  });
  const userStateRef = useRef(userState);

  const setUserState = data => {
    userStateRef.current = data;
    _setUserState(data);
  };

  const onFormValueChange = (data) => {
    setUserState({
      ...userState,
      form: {
        ...userState.form,
        [data.name]: data.value
      }
    })
  }

  const handleCreateChip = () => {
    let inputValue = userState.platformIdInputValue.replace(/\s/g, '');
    setUserState({
      ...userState,
      form: {
        ...userState.form,
        platformIds: [...userState.form.platformIds.filter(v => v != inputValue), inputValue]
      },
      platformIdInputValue: ""
    });
  };

  const handleDeleteChip = (chipToDelete) => {
    setUserState({
      ...userState,
      form: {
        ...userState.form,
        platformIds: userState.form.platformIds.filter((chip) => chip !== chipToDelete)
      }
    });
  };

  const submit = async () => {
    let error = false;

    delete userState.form.version;
    ["userName", "firstName", "beepId"].forEach(key => {
      if (userState.form[key] == null || userState.form[key] == "") {
        error = true;
        setUserState({...userState, error: true, errorMessage: "All fields are required"});
        return;
      }
    });

    if (userState.form.platformIds.length <= 0) {
      error = true;
      setUserState({...userState, error: true, errorMessage: "Please add Platform ID"});
      return;
    }

    let payload = userState.form;

    if (!error) {
      try {
        const res = await Api.updateUser(payload, userState.form.id);
        if(res.error) throw res.error;
        
        if (res && res.ok === true) {
          if (props.searchValue) {
            dispatch(handleSearchUsers(props.searchValue, props.searchFilter))
          } else {
            dispatch(handleGetUsers());
          }
          dispatch(closeEditUserModal());
        } else {
          let err = await res.json();
          throw err.message;
        }
      } catch (err) {
        setUserState({...userState, error: true, errorMessage: err});
      }
    }
  }

  return (
    <Modal
      open={true}
      aria-labelledby="add-order-group-modal"
      className={styles.modal}
    >
      <Paper className={styles.modalContainer}>
        <div className={styles.container}>
          <Close className={styles.closeButton} onClick={() => dispatch(closeEditUserModal())}/>
          <h3 className={styles.subtitle}>Edit User</h3>
          <Fade in={userState.error}>
            <p className={styles.errorMessage}>{userState.errorMessage}</p>
          </Fade>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <p>Username/Email</p>
              <Paper className={styles.textInputContainer}>
                <input
                  id="userName"
                  name="userName"
                  value={userState.form.userName}
                  onChange={e => onFormValueChange({name: "userName", value: e.target.value.toLowerCase().replace(/\s/g, '')})}
                  placeholder="Username/Email"
                  className={styles.textInput}
                />
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <p>First Name</p>
              <Paper className={styles.textInputContainer}>
                <input
                  id="firstName"
                  name="firstName"
                  value={userState.form.firstName}
                  onChange={e => onFormValueChange({name: "firstName", value: e.target.value})}
                  placeholder="First Name"
                  className={styles.textInput}
                />
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <p>Last Name</p>
              <Paper className={styles.textInputContainer}>
                <input
                  id="lastName"
                  name="lastName"
                  value={userState.form.lastName}
                  onChange={e => onFormValueChange({name: "lastName", value: e.target.value})}
                  placeholder="Last Name"
                  className={styles.textInput}
                />
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <p>Contact Number</p>
              <Paper className={styles.textInputContainer}>
                <input
                  id="contactNumber"
                  name="contactNumber"
                  value={userState.form.contactNumber}
                  onChange={e => onFormValueChange({name: "contactNumber", value: e.target.value})}
                  placeholder="Contact Number"
                  className={styles.textInput}
                />
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <p>Beep ID</p>
              <Paper className={styles.textInputContainer}>
                <input
                  id="beepId"
                  name="beepId"
                  value={userState.form.beepId}
                  onChange={e => onFormValueChange({name: "beepId", value: e.target.value})}
                  placeholder="Beep ID"
                  className={styles.textInput}
                />
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <p>Platform IDs</p>
              <Grid container spacing={1}>
                <Grid item xs={10}>
                  <Paper className={styles.textInputContainer}>
                    <input
                      id="platformIds"
                      name="platformIds"
                      value={userState.platformIdInputValue}
                      onChange={e => setUserState({...userState, platformIdInputValue: e.target.value})}
                      placeholder="Click button to add Platform ID"
                      className={styles.textInput}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={2}>
                  <Button onClick={handleCreateChip} variant="outlined" style={{height: 42}}>Add</Button>
                </Grid>
                <Grid item xs={12} style={{display: "flex"}}>
                  {
                    userState.form.platformIds && userState.form.platformIds.map((data) => {
                      return (
                        <li key={data} className={styles.chipList}>
                          <Chip label={data} onDelete={() => handleDeleteChip(data)} />
                        </li>
                      );
                    })
                  }
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <div className={styles.submitButtonContainer}>
                <Button onClick={() => submit()} className={styles.gradientButton}>Submit</Button>
              </div>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Modal>
  );
}
