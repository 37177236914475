import { Modal, Paper, Grid, InputLabel, FormControl, MenuItem, Select } from "@material-ui/core";
import { DateRangePicker } from 'react-date-range';
import { setDateRange, setDeliveryPlatform, closeDateRangeModal, handleGetPayments } from './paymentSlice';
import { getDeliveryPlatforms } from "../../app/Api";
import { format } from 'date-fns'
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { Close } from "@material-ui/icons";
import styles from './Payment.module.css';

export const DateRangeModal = (props) => {
    const { state, dispatch } = props;
    return (
        <Modal
            open={true}
            aria-labelledby="add-payment-modal"
            className={styles.modal}
        >
            <Paper className={styles.modalContainer}>
                <div className={styles.container}>
                    <Close className={styles.closeButton} onClick={() => dispatch(closeDateRangeModal())}/>
                    <h3 className={styles.subtitle}>Select Platform & Date Range</h3>
                    <Grid container spacing={5}>
                        <Grid item xs={12}>
                            <FormControl variant="outlined" style={{marginBottom: 20, minWidth: 200}}>
                                <InputLabel id="platform-label">Delivery Platform</InputLabel>
                                <Select
                                    labelId="platform-label"
                                    id="delivery-platform"
                                    value={state.deliveryPlatform}
                                    label="Delivery Platform"
                                    onChange={e => {
                                        dispatch(setDeliveryPlatform(e.target.value));
                                        dispatch(handleGetPayments());
                                    }}
                                >
                                    <MenuItem value="ALL">ALL</MenuItem>
                                    {
                                        getDeliveryPlatforms().map((platform) => (
                                            <MenuItem value={platform}>{platform}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <DateRangePicker
                                onChange={item => {
                                    dispatch(setDateRange({startDate: format(item.selection.startDate, 'yyyy-MM-dd'), endDate: format(item.selection.endDate, 'yyyy-MM-dd')}));
                                    dispatch(handleGetPayments());
                                }}
                                showSelectionPreview={true}
                                moveRangeOnFirstSelection={false}
                                months={1}
                                shownDate={new Date(state.startDate)}
                                ranges={[{ startDate: new Date(state.startDate), endDate: new Date(state.endDate), key: 'selection' }]}
                                scroll={{ enabled: true }}
                                direction="vertical"
                                calendarFocus="backwards"
                            />    
                        </Grid>
                    </Grid>
                </div>
            </Paper>
        </Modal>
    );
};