import { createSlice } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading';
import * as Api from "../../app/Api";
const { format } = require('date-fns');

export const shiftTableSlice = createSlice({
  name: 'shiftTable',
  initialState: {
    shiftDate: new Date(),
    shiftSlots: [],
    shiftRows: [],
    shiftCount: {},
    status: 'idle',
    pagination: {},
    page: 0,
    rowsPerPage: 10,
    selectedId: null
  },
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload;
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
    getShiftRows: (state, action) => {
      state.shiftRows = action.payload;
      state.status = 'success'
    },
    getShiftSlots: (state, action) => {
      state.shiftSlots = action.payload;
    },
    getShiftCount: (state, action) => {
      state.shiftCount = action.payload;
    },
    setShiftDate: (state, action) => {
      state.shiftDate = action.payload;
    }
  }
});

export const { setPage, setRowsPerPage, setPagination, getShiftRows, getShiftSlots, getShiftCount, setShiftDate } = shiftTableSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.shift.value)`
export const selectState = state => state.shiftTable;

export const handlePreviousWeek = () => async (dispatch, getState) => {
  const state = getState();
  let shiftDate = state.shiftTable.shiftDate
  shiftDate = new Date(shiftDate.setDate(shiftDate.getDate() - 7))

  // Move the date backward 7 days
  dispatch(setShiftDate(shiftDate))
  dispatch(handleGetShiftsThisWeek())
}

export const handleNextWeek = () => async (dispatch, getState) => {
  const state = getState();
  let shiftDate = state.shiftTable.shiftDate
  shiftDate = new Date(shiftDate.setDate(shiftDate.getDate() + 7))

  // Move the date backward 7 days
  dispatch(setShiftDate(shiftDate))
  dispatch(handleGetShiftsThisWeek())
}


export const handleGetShiftsThisWeek = () => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();
  
  const shiftDate = state.shiftTable.shiftDate;

  const firstDayOfWeek = new Date(shiftDate.getFullYear(), shiftDate.getMonth(), shiftDate.getDate() - shiftDate.getDay());
  firstDayOfWeek.setDate(firstDayOfWeek.getDate() + 1);
  const weekDates = [];
  for (let i = 0; i < 7; i++) {
    const date = new Date(firstDayOfWeek.getFullYear(), firstDayOfWeek.getMonth(), firstDayOfWeek.getDate() + i);
    const dateOnlyString = format(date, 'yyyy-MM-dd');
    weekDates.push({date: date, string: dateOnlyString});
  }
  
  let tableRows = []
  let shiftSlots = []
  let shiftCount = {}
  try {
    // assume always only 14 shifts per week
    const res1 = await Api.getShiftGroups(0, 14, weekDates[0].string, weekDates[6].string);
    if (res1.status === 401 || res1.status == 403) {
      Api.logout();
      throw res1.error;
    } else if (res1.error) {
      throw res1.error;
    };
    let shiftGroups = res1.content;

    let day = 0
    for (let shiftGroup of shiftGroups) {
      let startTime = new Date(shiftGroup.startTime)
      let endTime = new Date(shiftGroup.endTime)
      shiftSlots.push(
        {
          id: shiftGroup.id,
          day: day,
          date: format(startTime, 'yyyy-MM-dd'),
          dayOfWeek: format(startTime, 'EEE'),
          startTime: format(startTime, 'HHmm'),
          endTime: format(endTime, 'HHmm'),
        }
      )

      // assuming rider user count won't exceed 5000
      const res2 = await Api.getShiftsByShiftGroup(shiftGroup.id, 0, 5000);
      if (res2.status === 401 || res2.status == 403) {
        Api.logout();
        throw res2.error;
      } else if (res2.error) {
        throw res2.error;
      };
      let shifts = res2.content;

      for (let shift of shifts) {
        let row = tableRows.find(x => x.id === shift.user.beepId)
        if (row != undefined) {
          row[day] = 'Y'
        } else {
          row = {}
          row.id = shift.user.beepId
          row.name = shift.user.firstName + ' ' + (shift.user.lastName != undefined ? shift.user.lastName : '')
          row[day] = 'Y'
          tableRows.push(row)
        }
        shiftCount[day] = (shiftCount[day] == undefined ? 0 : shiftCount[day]) + 1
      }
      day = day + 1
    }

    dispatch(getShiftSlots(shiftSlots))
    dispatch(getShiftCount(shiftCount))
    dispatch(getShiftRows(tableRows))
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(hideLoading());
  }
};



export const handleGetShiftTableCsv = () => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();
  
  try {
    const shiftDate = state.shiftTable.shiftDate
    const shiftSlots = state.shiftTable.shiftSlots
    const shiftRows = state.shiftTable.shiftRows

    const keys = Object.keys(shiftSlots)

    const dateHeaders = shiftSlots.map(x => x.date)
    dateHeaders.unshift("", "");
    const dayHeaders = shiftSlots.map(x => x.dayOfWeek)
    dayHeaders.unshift("", "");
    const timeHeaders = shiftSlots.map(x => x.startTime + '-' + x.endTime)
    timeHeaders.unshift("BeepId", "Name");

    shiftRows.map(row => keys.map(key => row[key]).join(","))
    const commaSeparatedString = [
      dateHeaders.join(","), 
      dayHeaders.join(","), 
      timeHeaders.join(","), 
      shiftRows.map(row => row.id + "," + row.name + "," + keys.map(key => row[key]).join(","))
        .join("\n")
    ].join("\n")
    
    // 2. Create blob link to download
    const url = window.URL.createObjectURL(new Blob([commaSeparatedString]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `deliverit_rider_shift_table_${shiftDate}.csv`);
    // 3. Append to html page
    document.body.appendChild(link);
    // 4. Force download
    link.click();
    // 5. Clean up and remove the link
    link.parentNode.removeChild(link);
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(hideLoading());
  }
};

export default shiftTableSlice.reducer;
