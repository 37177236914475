import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { Modal, Paper, Grid, Fade, Button, FormGroup, FormControlLabel, Switch } from "@material-ui/core";
import { closeEditShiftGroupModal, handleGetShiftGroups } from './shiftGroupSlice';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as Api from "../../app/Api";
import { Close } from "@material-ui/icons";
import styles from './ShiftGroup.module.css';

export function EditShiftGroup(props) {
  const dispatch = useDispatch();
  const initialState = {
    form: {
      ...props.shiftGroup,
      startTime: new Date(props.shiftGroup.startTime),
      endTime: new Date(props.shiftGroup.endTime)
    },
    error: false,
    errorMessage: ""
  };
  const [shiftGroupState, _setShiftGroupState] = useStateWithCallbackLazy({
    ...initialState
  });
  const shiftGroupStateRef = useRef(shiftGroupState);

  const setShiftGroupState = data => {
    shiftGroupStateRef.current = data;
    _setShiftGroupState(data);
  };

  const submit = async () => {
    let error = false;

    delete shiftGroupState.form.version;
    for (var key in shiftGroupState.form) {
      if (key != "published" && (shiftGroupState.form[key] == null || shiftGroupState.form[key] == "")) {
        error = true;
        setShiftGroupState({...shiftGroupState, error: true, errorMessage: "All fields are required"});
        return;
      }
    }

    if (shiftGroupState.form.startTime >= shiftGroupState.form.endTime) {
      error = true;
      setShiftGroupState({...shiftGroupState, error: true, errorMessage: "End time must be later than start time"});
      return;
    }

    let payload = {
      ...shiftGroupState.form
    }

    if (!error) {
      try {
        const res = await Api.updateShiftGroup(payload, shiftGroupState.form.id);
        if(res.error) throw res.error;
        
        dispatch(handleGetShiftGroups());
        dispatch(closeEditShiftGroupModal());
      } catch (err) {
        setShiftGroupState({...shiftGroupState, error: true, errorMessage: err});
      }
    }
  }

  return (
    <Modal
      open={true}
      aria-labelledby="edit-shift-group-modal"
      className={styles.modal}
    >
      <Paper className={styles.modalContainer}>
        <div className={styles.container}>
          <Close className={styles.closeButton} onClick={() => dispatch(closeEditShiftGroupModal())}/>
          <h3 className={styles.subtitle}>Update Shift Group <em style={{fontSize: 12}}>(All fields are required)</em></h3>
          <Fade in={shiftGroupState.error}>
            <p className={styles.errorMessage}>{shiftGroupState.errorMessage}</p>
          </Fade>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <p className={styles.inputLabel}>Start Date Time</p>
              <Paper className={styles.textInputContainer}>
                <DatePicker
                  selected={shiftGroupState.form.startTime}
                  value={shiftGroupState.form.startTime}
                  minDate={new Date()}
                  style={styles.textInput}
                  onChange={date => setShiftGroupState({
                    ...shiftGroupState,
                    form: {
                      ...shiftGroupState.form,
                      startTime: date,
                      endTime: ""
                    }
                  })}
                  placeholderText="yyyy-MM-dd hh:mm:ss"
                  dateFormat="yyyy-MM-dd hh:mm:ss"
                  name="startTime"
                  autoComplete="off"
                  showTimeSelect
                  required
                />
              </Paper> 
              <p className={styles.inputLabel}>End Date Time</p>
              <Paper className={styles.textInputContainer}>
                <DatePicker
                  selected={shiftGroupState.form.endTime}
                  value={shiftGroupState.form.endTime}
                  minDate={shiftGroupState.form.startTime}
                  maxDate={shiftGroupState.form.startTime}
                  style={styles.textInput}
                  onChange={date => setShiftGroupState({
                    ...shiftGroupState,
                    form: {
                      ...shiftGroupState.form,
                      endTime: date
                    }
                  })}
                  placeholderText="yyyy-MM-dd hh:mm:ss"
                  dateFormat="yyyy-MM-dd hh:mm:ss"
                  name="endTime"
                  autoComplete="off"
                  disabled={shiftGroupState.form.startTime ? false : true}
                  showTimeSelect
                  required
                />
              </Paper> 
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Switch 
                      checked={shiftGroupState.form.published} 
                      name="published"
                      color="primary" 
                      onChange={e => setShiftGroupState({
                        ...shiftGroupState,
                        form: {
                          ...shiftGroupState.form,
                          published: e.target.checked
                        }
                      })}
                    />
                  }
                  label="Published"
                />
              </FormGroup>
              <div className={styles.submitButtonContainer}>
                <Button onClick={() => submit()} className={styles.gradientButton}>Update</Button>
              </div>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Modal>
  );
}
