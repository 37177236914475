import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { Modal, Paper, Grid, Fade, Button, Chip } from "@material-ui/core";
import { closeEditBeepDeliveryRateModal, handleGetBeepDeliveryRates } from './beepDeliveryRateSlice';
import * as Api from "../../app/Api";
import { Close } from "@material-ui/icons";
import styles from './BeepDeliveryRate.module.css';

export function EditBeepDeliveryRate(props) {
  const dispatch = useDispatch();
  const initialState = {
    form: {
      ...props.beepDeliveryRate,
      tier1Total: props.beepDeliveryRate.tier1Total / 100,
      tier2Rate: props.beepDeliveryRate.tier2Rate / 100,
      bonusRate: props.beepDeliveryRate.bonusRate / 100
    },
    initBeepDeliveryRateInfo: false,
    error: false,
    errorMessage: ""
  };
  const [beepDeliveryRateState, _setBeepDeliveryRateState] = useStateWithCallbackLazy({
    ...initialState
  });
  const beepDeliveryRateStateRef = useRef(beepDeliveryRateState);

  const setBeepDeliveryRateState = data => {
    beepDeliveryRateStateRef.current = data;
    _setBeepDeliveryRateState(data);
  };

  const onFormValueChange = (data) => {
    setBeepDeliveryRateState({
      ...beepDeliveryRateState,
      form: {
        ...beepDeliveryRateState.form,
        [data.name]: data.value
      }
    })
  }

  const submit = async () => {
    let error = false;

    delete beepDeliveryRateState.form.version;
    for (var key in beepDeliveryRateState.form) {
      if (beepDeliveryRateState.form[key] == null || beepDeliveryRateState.form[key] == "") {
        error = true;
        setBeepDeliveryRateState({...beepDeliveryRateState, error: true, errorMessage: "All fields are required"});
        return;
      }
    }

    let payload = {
      ...beepDeliveryRateState.form,
      tier1Total: beepDeliveryRateState.form.tier1Total * 100,
      tier2Rate: beepDeliveryRateState.form.tier2Rate * 100,
      bonusRate: beepDeliveryRateState.form.bonusRate * 100
    }

    if (!error) {
      try {
        const res = await Api.updateBeepDeliveryRate(payload, beepDeliveryRateState.form.id);
        if(res.error) throw res.error;
        
        dispatch(handleGetBeepDeliveryRates());
        dispatch(closeEditBeepDeliveryRateModal());
      } catch (err) {
        setBeepDeliveryRateState({...beepDeliveryRateState, error: true, errorMessage: err});
      }
    }
  }

  return (
    <Modal
      open={true}
      aria-labelledby="add-order-group-modal"
      className={styles.modal}
    >
      <Paper className={styles.modalContainer}>
        <div className={styles.container}>
          <Close className={styles.closeButton} onClick={() => dispatch(closeEditBeepDeliveryRateModal())}/>
          <h3 className={styles.subtitle}>Edit Beep Delivery Rate</h3>
          <Fade in={beepDeliveryRateState.error}>
            <p className={styles.errorMessage}>{beepDeliveryRateState.errorMessage}</p>
          </Fade>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <p>Name</p>
              <Paper className={styles.textInputContainer}>
                <input
                  id="name"
                  name="name"
                  value={beepDeliveryRateState.form.name}
                  onChange={e => onFormValueChange({name: "name", value: e.target.value})}
                  placeholder="Name"
                  className={styles.textInput}
                />
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <p>Minimum Order</p>
              <Paper className={styles.textInputContainer}>
                <input
                  id="tier1Count"
                  name="tier1Count"
                  value={beepDeliveryRateState.form.tier1Count}
                  onChange={e => onFormValueChange({name: "tier1Count", value: e.target.value})}
                  placeholder="Minimum Order"
                  className={styles.textInput}
                />
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <p>Minimum Order Base Rate (RM)</p>
              <Paper className={styles.textInputContainer}>
                <input
                  id="tier1Total"
                  name="tier1Total"
                  value={beepDeliveryRateState.form.tier1Total}
                  onChange={e => onFormValueChange({name: "tier1Total", value: e.target.value})}
                  placeholder="Minimum Order Base Rate (RM)"
                  className={styles.textInput}
                />
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <p>Subsequent Order After Minimum Order (RM)</p>
              <Paper className={styles.textInputContainer}>
                <input
                  id="tier2Rate"
                  name="tier2Rate"
                  value={beepDeliveryRateState.form.tier2Rate}
                  onChange={e => onFormValueChange({name: "tier2Rate", value: e.target.value})}
                  placeholder="Subsequent Order After Minimum Order (RM)"
                  className={styles.textInput}
                />
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <p>Bonus Rate per KM (RM)</p>
              <Paper className={styles.textInputContainer}>
                <input
                  id="bonusRate"
                  name="bonusRate"
                  value={beepDeliveryRateState.form.bonusRate}
                  onChange={e => onFormValueChange({name: "bonusRate", value: e.target.value})}
                  placeholder="Bonus Rate per KM (RM)"
                  className={styles.textInput}
                />
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <p>Bonus Min Distance (KM)</p>
              <Paper className={styles.textInputContainer}>
                <input
                  id="bonusMinDistance"
                  name="bonusMinDistance"
                  value={beepDeliveryRateState.form.bonusMinDistance}
                  onChange={e => onFormValueChange({name: "bonusMinDistance", value: e.target.value})}
                  placeholder="Bonus Min Distance (KM)"
                  className={styles.textInput}
                />
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <p>Bonus Max Distance (KM)</p>
              <Paper className={styles.textInputContainer}>
                <input
                  id="bonusMaxDistance"
                  name="bonusMaxDistance"
                  value={beepDeliveryRateState.form.bonusMaxDistance}
                  onChange={e => onFormValueChange({name: "bonusMaxDistance", value: e.target.value})}
                  placeholder="Bonus Max Distance (KM)"
                  className={styles.textInput}
                />
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <p>Average Order An Hour Threshold</p>
              <Paper className={styles.textInputContainer}>
                <input
                  id="averageOrderAnHourThreshold"
                  name="averageOrderAnHourThreshold"
                  value={beepDeliveryRateState.form.averageOrderAnHourThreshold}
                  onChange={e => onFormValueChange({name: "averageOrderAnHourThreshold", value: e.target.value})}
                  placeholder="Average Order An Hour Threshold"
                  className={styles.textInput}
                />
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <div className={styles.submitButtonContainer}>
                <Button onClick={() => submit()} className={styles.gradientButton}>Submit</Button>
              </div>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Modal>
  );
}
