import React from 'react';
import { useDispatch } from 'react-redux';
import * as Api from "../../app/Api";
import { closeRejectWithdrawModal, handleGetWithdrawals } from './withdrawalSlice';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText } from '@material-ui/core';

export function RejectWithdraw(props) {
    const { withdrawals, withdrawStatus } = props;
    const dispatch = useDispatch();

    const submit = (props) => {
        withdrawals.forEach(withdrawal => {
            try {
                const res = Api.rejectWithdrawal(withdrawal.id);
                if(res.error) throw res.error;        
            } catch (err) {
                alert(err);
            }
        });

        dispatch(closeRejectWithdrawModal());
        dispatch(handleGetWithdrawals(withdrawStatus));
    }

    return (
        <div>
        <Dialog open={true} onClose={() => dispatch(closeRejectWithdrawModal())} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Reject Cashout Request</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to reject all selected withdrawals request? Once rejected, these withdrawals will be mark as REJECTED
                </DialogContentText>  
            </DialogContent>
            <DialogActions>
                <Button onClick={() =>  dispatch(closeRejectWithdrawModal())} color="primary">
                    Cancel
                </Button>
                <Button onClick={() => submit(props)} color="primary">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
        </div>
    );
}