import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox, TablePagination, TableFooter } from "@material-ui/core";
import { TableToolbar } from './TableToolbar';
import { selectState, handleGetUploadedFiles, openRollbackUploadedFileModal, setPage, setRowsPerPage, setSelectedId } from './uploadedFileSlice';
import { ConfirmRollback } from './ConfirmRollback';
import TablePaginationActions from './TablePaginationActions';
import styles from './UploadedFile.module.css';

export function UploadedFile(props) {
  const state = useSelector(selectState);
  const dispatch = useDispatch();
  useEffect(() => {
    if (state.status === 'idle') {
      dispatch(handleGetUploadedFiles());
    }
  });

  const handleChangePage = (event, newPage) => {
    dispatch(setPage(newPage));
    dispatch(handleGetUploadedFiles());
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setRowsPerPage(parseInt(event.target.value, 10)));
    dispatch(setPage(0));
    dispatch(handleGetUploadedFiles());
  };

  return (
    <div className={styles.container}>
      {
        state.uploadedFiles &&
        <TableContainer component={Paper} className={styles.denseTable}>
          <TableToolbar 
            numSelected={state.selectedId == null ? 0 : 1}
            rollbackUploadedFile={() => dispatch(openRollbackUploadedFileModal())}
          />
          <Table size="small">
            <TableHead className={styles.tableHeader}>
              <TableRow>
                <TableCell align="left" className={styles.tableHeader}>{" "}</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Uploaded File ID</TableCell>
                <TableCell align="left" className={styles.tableHeader}>File Name</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Delivery Platform</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Status</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Error Message</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                state.uploadedFiles.length > 0 ? (
                  state.uploadedFiles.map((uploadedFile) => (
                    <TableRow 
                      key={uploadedFile.id}
                      hover
                      onClick={() => dispatch(setSelectedId(state.selectedId == uploadedFile.id ? null : uploadedFile.id))}
                      role="checkbox"
                      aria-checked={state.selectedId == uploadedFile.id ? true : false}
                      selected={state.selectedId == uploadedFile.id ? true : false}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox 
                          color="primary"
                          checked={state.selectedId == uploadedFile.id ? true : false}
                        />
                      </TableCell>
                      <TableCell align="left">{uploadedFile.id}</TableCell>
                      <TableCell align="left">{uploadedFile.filename}</TableCell>
                      <TableCell align="left">{uploadedFile.deliveryPlatform}</TableCell>
                      <TableCell align="left">{uploadedFile.status}</TableCell>
                      <TableCell align="left">{uploadedFile.errorMessage}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={11} align="center">
                      <p>No Uploaded Files</p>
                    </TableCell>
                  </TableRow>
                )
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                  colSpan={7}
                  count={state.pagination ? state.pagination.totalElements ? state.pagination.totalElements : 0 : 0}
                  rowsPerPage={state.rowsPerPage}
                  page={state.page}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      }
      {
        state.rollbackUploadedFileModalOpen &&
        <ConfirmRollback uploadedFile={state.selectedId ? state.uploadedFiles.find(uploadedFile => uploadedFile.id == state.selectedId) : null} />
      }
    </div>
  );
}
