const API_BASE_URL = window.REACT_APP_API_GATEWAY_URL + "/rider/v1";

export const login = data =>
  fetch(`${API_BASE_URL}/auth/login`, {
    crossDomain: true,
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  }).then(res => res);

export const getWeeklyPayments = (page, size, startDate) =>
  fetch(`${API_BASE_URL}/weekly-works?page=${page}&size=${size}&startDate=${startDate}`, {
    crossDomain: true,
    method: "GET",
    headers: {
      Authorization: `Bearer ${getJwtToken()}`
    }
  }).then(res => res.json());

export const getWeeklyPaymentsByUser = (userId, page, size) =>
  fetch(`${API_BASE_URL}/weekly-works?userId=${userId}&page=${page}&size=${size}`, {
    crossDomain: true,
    method: "GET",
    headers: {
      Authorization: `Bearer ${getJwtToken()}`
    }
  }).then(res => res.json());

export const getPayslip = (startDate, platformId, userId) =>
  fetch(`${API_BASE_URL}/weekly-works/payslip?startDate=${startDate}&platformId=${platformId}&userId=${userId}`, {
    crossDomain: true,
    method: "GET",
    headers: {
      Authorization: `Bearer ${getJwtToken()}`,
      'Content-Type': 'application/pdf'
    }
  }).then(res => res);

export const getPayments = (page, size, from, to, deliveryPlatform) =>
  fetch(`${API_BASE_URL}/daily-works?page=${page}&size=${size}&from=${from}&to=${to}&deliveryPlatform=${deliveryPlatform}`, {
    crossDomain: true,
    method: "GET",
    headers: {
      Authorization: `Bearer ${getJwtToken()}`
    }
  }).then(res => res.json());

export const updatePayment = (data, id) =>
  fetch(`${API_BASE_URL}/daily-works/${id}`, {
    crossDomain: true,
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getJwtToken()}`
    },
    body: JSON.stringify(data)
  }).then(res => res);

export const adjustSalary = (data, id) =>
  fetch(`${API_BASE_URL}/daily-works/${id}/adjust-salary`, {
    crossDomain: true,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getJwtToken()}`
    },
    body: JSON.stringify(data)
  }).then(res => res);

export const getPaymentReports = (from, to) =>
  fetch(`${API_BASE_URL}/reportings?from=${from}&to=${to}`, {
    crossDomain: true,
    method: "GET",
    headers: {
      Authorization: `Bearer ${getJwtToken()}`,
      'Content-Type': 'text/csv'
    }
  }).then(res => res);

export const getCashOutRequestReports = data =>
  fetch(`${API_BASE_URL}/reportings/cash-out-request`, {
    crossDomain: true,
    method: "POST",
    headers: {
      Authorization: `Bearer ${getJwtToken()}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  }).then(res => res);

export const getCashedOutRequestReports = (from, to) =>
  fetch(`${API_BASE_URL}/reportings/cashed-out?from=${from}&to=${to}`, {
    crossDomain: true,
    method: "POST",
    headers: {
      Authorization: `Bearer ${getJwtToken()}`,
      "Content-Type": "application/json"
    }
  }).then(res => res);

export const uploadPayment = (data, platform) =>
  fetch(`${API_BASE_URL}/uploads?platform=${platform}`, {
    crossDomain: true,
    method: "POST",
    timeout: 600000,
    headers: {
      Authorization: `Bearer ${getJwtToken()}`
    },
    body: data
  }).then(res => res);

export const getUploadedFiles = (page, size) =>
  fetch(`${API_BASE_URL}/uploads?page=${page}&size=${size}`, {
    crossDomain: true,
    method: "GET",
    headers: {
      Authorization: `Bearer ${getJwtToken()}`
    }
  }).then(res => res.json());

export const rollbackUploadedFile = (filename) =>
  fetch(`${API_BASE_URL}/uploads?filename=${filename}`, {
    crossDomain: true,
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getJwtToken()}`
    }
  }).then(res => res);

export const getShiftGroups = (page, size, from, to) =>
  fetch(`${API_BASE_URL}/shift-groups?page=${page}&size=${size}&from=${from}&to=${to}`, {
    crossDomain: true,
    method: "GET",
    headers: {
      Authorization: `Bearer ${getJwtToken()}`
    }
  }).then(res => res.json());

export const getShiftGroup = (id) =>
  fetch(`${API_BASE_URL}/shift-groups/${id}`, {
    crossDomain: true,
    method: "GET",
    headers: {
      Authorization: `Bearer ${getJwtToken()}`
    }
  }).then(res => res.json());

export const createShiftGroup = data =>
  fetch(`${API_BASE_URL}/shift-groups`, {
    crossDomain: true,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getJwtToken()}`
    },
    body: JSON.stringify(data)
  }).then(res => res.json());

export const updateShiftGroup = (data, id) =>
  fetch(`${API_BASE_URL}/shift-groups/${id}`, {
    crossDomain: true,
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getJwtToken()}`
    },
    body: JSON.stringify(data)
  }).then(res => res);

export const deleteShiftGroup = (id) =>
  fetch(`${API_BASE_URL}/shift-groups/${id}`, {
    crossDomain: true,
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getJwtToken()}`
    }
  }).then(res => res);

export const getShiftsByShiftGroup = (shiftGroupId, page, size) =>
  fetch(`${API_BASE_URL}/shift-groups/${shiftGroupId}/shifts?page=${page}&size=${size}`, {
    crossDomain: true,
    method: "GET",
    headers: {
      Authorization: `Bearer ${getJwtToken()}`
    }
  }).then(res => res.json());

  export const getShiftsByShiftGroupAndUser = (shiftGroupId, page, size, userId, from, to) =>
  fetch(`${API_BASE_URL}/shift-groups/${shiftGroupId}/shifts?page=${page}&size=${size}&userId=${userId}&from=${from}&to=${to}`, {
    crossDomain: true,
    method: "GET",
    headers: {
      Authorization: `Bearer ${getJwtToken()}`
    }
  }).then(res => res.json());

export const getBeepDeliveryRates = (page, size) =>
  fetch(`${API_BASE_URL}/beep-delivery-rates?page=${page}&size=${size}`, {
    crossDomain: true,
    method: "GET",
    headers: {
      Authorization: `Bearer ${getJwtToken()}`
    }
  }).then(res => res.json());

export const updateBeepDeliveryRate = (data, id) =>
  fetch(`${API_BASE_URL}/beep-delivery-rates/${id}`, {
    crossDomain: true,
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getJwtToken()}`
    },
    body: JSON.stringify(data)
  }).then(res => res);

export const getDeliveritDeliveryRates = (page, size) =>
  fetch(`${API_BASE_URL}/deliverit-delivery-rates?page=${page}&size=${size}`, {
    crossDomain: true,
    method: "GET",
    headers: {
      Authorization: `Bearer ${getJwtToken()}`
    }
  }).then(res => res.json());

export const updateDeliveritDeliveryRate = (data, id) =>
  fetch(`${API_BASE_URL}/deliverit-delivery-rates/${id}`, {
    crossDomain: true,
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getJwtToken()}`
    },
    body: JSON.stringify(data)
  }).then(res => res);

export const getWithdrawals = (page, size, status) =>
  fetch(`${API_BASE_URL}/withdraws?page=${page}&size=${size}&status=${status}`, {
    crossDomain: true,
    method: "GET",
    headers: {
      Authorization: `Bearer ${getJwtToken()}`
    }
  }).then(res => res.json());

export const confirmWithdrawal = (id) =>
  fetch(`${API_BASE_URL}/withdraws/${id}/confirm`, {
    crossDomain: true,
    method: "POST",
    headers: {
      Authorization: `Bearer ${getJwtToken()}`
    }
  }).then(res => res.json());

export const rejectWithdrawal = (id) =>
  fetch(`${API_BASE_URL}/withdraws/${id}/reject`, {
    crossDomain: true,
    method: "POST",
    headers: {
      Authorization: `Bearer ${getJwtToken()}`
    }
  }).then(res => res.json());

export const createWithdrawal = data =>
  fetch(`${API_BASE_URL}/withdraws`, {
    crossDomain: true,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getJwtToken()}`
    },
    body: JSON.stringify(data)
  }).then(res => res.json());

export const getBatchNotifications = (page, size, scheduled) =>
  fetch(`${API_BASE_URL}/push-notifications?page=${page}&size=${size}&scheduled=${scheduled}&filterUser=false`, {
    crossDomain: true,
    method: "GET",
    headers: {
      Authorization: `Bearer ${getJwtToken()}`
    }
  }).then(res => res.json());

export const createNotification = data =>
  fetch(`${API_BASE_URL}/push-notifications`, {
    crossDomain: true,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getJwtToken()}`
    },
    body: JSON.stringify(data)
  }).then(res => res);

export const deleteScheduledNotification = (id) =>
  fetch(`${API_BASE_URL}/push-notifications/${id}?scheduled=true`, {
    crossDomain: true,
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getJwtToken()}`
    }
  }).then(res => res);

export const getHotspotGroups = (page, size) =>
  fetch(`${API_BASE_URL}/hotspot-groups?page=${page}&size=${size}`, {
    crossDomain: true,
    method: "GET",
    headers: {
      Authorization: `Bearer ${getJwtToken()}`
    }
  }).then(res => res.json());

export const createHotspotGroup = data =>
  fetch(`${API_BASE_URL}/hotspot-groups`, {
    crossDomain: true,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getJwtToken()}`
    },
    body: JSON.stringify(data)
  }).then(res => res.json());

export const updateHotspotGroup = (data, id) =>
  fetch(`${API_BASE_URL}/hotspot-groups/${id}`, {
    crossDomain: true,
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getJwtToken()}`
    },
    body: JSON.stringify(data)
  }).then(res => res);

export const deleteHotspotGroup = (id) =>
  fetch(`${API_BASE_URL}/hotspot-groups/${id}`, {
    crossDomain: true,
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getJwtToken()}`
    }
  }).then(res => res);

export const getHotspotsByHotspotGroup = (id) =>
  fetch(`${API_BASE_URL}/hotspot-groups/${id}`, {
    crossDomain: true,
    method: "GET",
    headers: {
      Authorization: `Bearer ${getJwtToken()}`
    }
  }).then(res => res.json());

export const createHotspot = (hotspotGroupId, data) =>
  fetch(`${API_BASE_URL}/hotspot-groups/${hotspotGroupId}/hotspots`, {
    crossDomain: true,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getJwtToken()}`
    },
    body: JSON.stringify(data)
  }).then(res => res.json());

export const updateHotspot = (hotspotGroupId, data, id) =>
  fetch(`${API_BASE_URL}/hotspot-groups/${hotspotGroupId}/hotspots/${id}`, {
    crossDomain: true,
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getJwtToken()}`
    },
    body: JSON.stringify(data)
  }).then(res => res);

export const deleteHotspot = (hotspotGroupId, id) =>
  fetch(`${API_BASE_URL}/hotspot-groups/${hotspotGroupId}/hotspots/${id}`, {
    crossDomain: true,
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getJwtToken()}`
    }
  }).then(res => res);

export const getUserInfo = () => 
  fetch(`${API_BASE_URL}/users/${getAuthedUser().id}`, {
    crossDomain: true,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getJwtToken()}`
    }
  }).then(res => res);

export const getUsers = (page, size) =>
  fetch(`${API_BASE_URL}/users?page=${page}&size=${size}`, {
    crossDomain: true,
    method: "GET",
    headers: {
      Authorization: `Bearer ${getJwtToken()}`
    }
  }).then(res => res.json());

export const getUser = (id) =>
  fetch(`${API_BASE_URL}/users/${id}`, {
    crossDomain: true,
    method: "GET",
    headers: {
      Authorization: `Bearer ${getJwtToken()}`
    }
  }).then(res => res.json());

export const searchUsers = (name, page, size) =>
  fetch(`${API_BASE_URL}/users?page=${page}&size=${size}&name=${name}`, {
    crossDomain: true,
    method: "GET",
    headers: {
      Authorization: `Bearer ${getJwtToken()}`
    }
  }).then(res => res.json());
  
export const searchUsersByPlatformId = (platformId, page, size) =>
  fetch(`${API_BASE_URL}/users?page=${page}&size=${size}&platformId=${platformId}`, {
    crossDomain: true,
    method: "GET",
    headers: {
      Authorization: `Bearer ${getJwtToken()}`
    }
  }).then(res => res.json());

export const createUser = data =>
  fetch(`${API_BASE_URL}/users`, {
    crossDomain: true,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getJwtToken()}`
    },
    body: JSON.stringify(data)
  }).then(res => res);

export const updateUser = (data, id) =>
  fetch(`${API_BASE_URL}/users/${id}`, {
    crossDomain: true,
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getJwtToken()}`
    },
    body: JSON.stringify(data)
  }).then(res => res);

export const deleteUser = (id) =>
  fetch(`${API_BASE_URL}/users/${id}`, {
    crossDomain: true,
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getJwtToken()}`
    }
  }).then(res => res);

export const getArchivedDailyWorks = (page, size, from, to) =>
  fetch(`${API_BASE_URL}/archive-daily-works?page=${page}&size=${size}`, {
    crossDomain: true,
    method: "GET",
    headers: {
      Authorization: `Bearer ${getJwtToken()}`
    }
  }).then(res => res.json());

export const getRedeemsByUserId = (userId, page, size) =>
  fetch(`${API_BASE_URL}/redeems?userId=${userId}&page=${page}&size=${size}`, {
    crossDomain: true,
    method: "GET",
    headers: {
      Authorization: `Bearer ${getJwtToken()}`
    }
  }).then(res => res.json());

export const getAllRedeems = (page, size) =>
  fetch(`${API_BASE_URL}/redeems?page=${page}&size=${size}`, {
    crossDomain: true,
    method: "GET",
    headers: {
      Authorization: `Bearer ${getJwtToken()}`
    }
  }).then(res => res.json());

export const getRedeemPayslip = (id) =>
  fetch(`${API_BASE_URL}/redeems/${id}/payslip`, {
    crossDomain: true,
    method: "GET",
    headers: {
      Authorization: `Bearer ${getJwtToken()}`,
      'Content-Type': 'application/pdf'
    }
  }).then(res => res);

export const getAuthedUser = () => {
  return JSON.parse(localStorage.auth);
}

export const getDeliveryPlatforms = () => {
  return ['DELIVERIT', 'BEEP'];
}

export const logout = () => {
  localStorage.removeItem("auth");
  window.location = "/login";
}

function getJwtToken() {
  return JSON.parse(localStorage.auth).accessToken;
}