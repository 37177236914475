import { createSlice } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading';
import * as Api from "../../app/Api";

export const redeemSlice = createSlice({
  name: 'redeem',
  initialState: {
    redeems: [],
    status: 'idle',
    pagination: {},
    page: 0,
    rowsPerPage: 10,
    selectedId: null
  },
  reducers: {
    setSelectedId: (state, action) => {
      state.selectedId = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload;
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
    getRedeems: (state, action) => {
      state.redeems = action.payload;
      state.selectedId = null;
      state.status = 'success'
    },
  },
});

export const { setSelectedId, setPage, setRowsPerPage, setPagination, getRedeems } = redeemSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.redeem.value)`
export const selectState = state => state.redeem;

export const handleGetRedeems = () => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();
  try {
    const res = await Api.getAllRedeems(state.redeem.page, state.redeem.rowsPerPage);
    if (res.status === 401 || res.status == 403) {
      Api.logout();
      throw res.error;
    } else if (res.error) {
      throw res.error;
    };
    
    const { content, ...pagination } = res;
    let redeems = content;

    dispatch(setPagination(pagination));
    dispatch(getRedeems(redeems));
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(hideLoading());
  }
};

export const handleDownloadPayslip = (redeemId) => async (dispatch, getState) => {
  dispatch(showLoading());

  try {
    const res = await Api.getRedeemPayslip(redeemId);
    if (res.status === 401 || res.status === 403) {
      Api.logout();
      throw res.error;
    } else if (res.error) {
      throw res.error;
    };
    
    let report = await res.blob();
    // 2. Create blob link to download
    const url = window.URL.createObjectURL(new Blob([report]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `redeem_payslip_${redeemId}.pdf`);
    // 3. Append to html page
    document.body.appendChild(link);
    // 4. Force download
    link.click();
    // 5. Clean up and remove the link
    link.parentNode.removeChild(link);
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(hideLoading());
  }
};

export default redeemSlice.reducer;
