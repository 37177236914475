import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { Button, Paper, Fade, Hidden } from "@material-ui/core";
import { onValueChange, handleLoginFormSubmit, selectState } from './loginSlice';
import styles from './Login.module.css';
import logo from "../../images/logo.png";

export function Login() {
  const state = useSelector(selectState);
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <div className={styles.container}>
      <Hidden smDown>
        <div display={{ xs: 'none' }} className={styles.leftContainer}></div>
      </Hidden>
      <div className={styles.rightContainer}>
        <form style={{maxWidth: 400}}>
          <img src={logo} alt="logo" className={styles.logo} />
          <p style={{marginBottom: 20}}>DeliverIt Rider Admin</p>
          <Fade in={state.error}>
            <p className={styles.errorMessage}>{state.errorMessage}</p>
          </Fade>
          <Paper className={styles.textInputContainer}>
            <input
              id="email"
              name="email"
              value={state.userName}
              onChange={e => dispatch(onValueChange({name: "userName", value: e.target.value}))}
              placeholder="Email Address"
              type="email"
              className={styles.textInput}
            />
          </Paper>
          <Paper className={styles.textInputContainer}>
            <input
              id="password"
              name="password"
              value={state.password}
              onChange={e => dispatch(onValueChange({name: "password", value: e.target.value}))}
              placeholder="Password"
              type="password"
              className={styles.textInput}
            />
          </Paper>
          <div className={styles.buttonContainer}>
            <Button
              onClick={() => dispatch(handleLoginFormSubmit(history))}
              className={styles.gradientButton}
            >
              Login
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}
