import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import * as Api from "../../app/Api";
import { closeDownloadReportModal, handleGetWithdrawals } from './withdrawalSlice';
import { Modal, Paper, Grid, Fade, Button } from "@material-ui/core";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Close } from "@material-ui/icons";
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import styles from './Withdrawal.module.css';

export function DownloadReport(props) {
  const { withdrawStatus } = props;
  const dispatch = useDispatch();

  const todayDate = new Date()
  const todayDateFormatted = todayDate.toISOString().split('T')[0]
  
  const initialState = {
    form: {
      debitAccountNumber: "110102003",
      transactionReferenceNumber: "",
      effectiveDate: todayDateFormatted,
    },
    effectiveDateObject: todayDate,
    error: false,
    errorMessage: ""
  };
  const [downloadReportState, _setDownloadReportState] = useStateWithCallbackLazy({
    ...initialState
  });
  const downloadReportStateRef = useRef(downloadReportState);
  
  const setDownloadReportState = data => {
    downloadReportStateRef.current = data;
    _setDownloadReportState(data);
  };
  
  const onFormValueChange = (data) => {
    setDownloadReportState({
      ...downloadReportState,
      form: {
        ...downloadReportState.form,
        [data.name]: data.value
      }
    })
  }
  
  const submit = async () => {
    let error = false;
    
    if (downloadReportState.form.effectiveDate == "" || downloadReportState.form.effectiveDate == undefined) {
      error = true;
      setDownloadReportState({...downloadReportState, error: true, errorMessage: "transact date cannot be empty"});
      return;
    }
    
    if (downloadReportState.form.debitAccountNumber == "" || downloadReportState.form.debitAccountNumber == undefined) {
      error = true;
      setDownloadReportState({...downloadReportState, error: true, errorMessage: "debit account number cannot be empty"});
      return;
    }
    
    if (downloadReportState.form.transactionReferenceNumber == "" || downloadReportState.form.transactionReferenceNumber == undefined) {
      error = true;
      setDownloadReportState({...downloadReportState, error: true, errorMessage: "transaction reference number cannot be empty"});
      return;
    }
    
    if (!error) {
      try {
        let payload = { ...downloadReportState.form };
        
        const res = await Api.getCashOutRequestReports(payload);
        if (res.status !== 200) {
          throw res.error;
        }

        let report = await res.blob();
        // 2. Create blob link to download
        const url = window.URL.createObjectURL(new Blob([report]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `cash_out_requests_report_${todayDateFormatted}.csv`);
        // 3. Append to html page
        document.body.appendChild(link);
        // 4. Force download
        link.click();
        // 5. Clean up and remove the link
        link.parentNode.removeChild(link);
        
        dispatch(closeDownloadReportModal());
        dispatch(handleGetWithdrawals(withdrawStatus));
      } catch (err) {
        setDownloadReportState({...downloadReportState, error: true, errorMessage: err});
      }
    }
  }
  
  return (
    <Modal
      open={props.downloadReportModalOpen}
      aria-labelledby="download-report-modal"
      className={styles.modal}
    >
      <Paper className={styles.modalContainer}>
        <div className={styles.container}>
        <Close className={styles.closeButton} onClick={() => dispatch(closeDownloadReportModal())}/>

        <h3 className={styles.subtitle}>Download Cash Out Request Report <em style={{fontSize: 12}}>(All fields are required)</em></h3>
        
        <Fade in={downloadReportState.error}>
        <p className={styles.errorMessage}>{downloadReportState.errorMessage}</p>

        </Fade>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <p className={styles.inputLabel}>Filter By Effective Date</p>
              <Paper className={styles.textInputContainer}>
                <DatePicker
                  selected={downloadReportState.effectiveDateObject}
                  value={downloadReportState.effectiveDateObject}
                  style={styles.textInput}
                  onChange={date => setDownloadReportState({
                    ...downloadReportState,
                    form: {
                      ...downloadReportState.form,
                      effectiveDate: date.toISOString().split('T')[0],
                    },
                    effectiveDateObject: date
                  })}
                  placeholderText="yyyy-MM-dd"
                  dateFormat="yyyy-MM-dd"
                  name="effectiveDate"
                  autoComplete="off"
                  required
                />
              </Paper>
              
              <p className={styles.inputLabel}>Debit Account Number</p>
              <Paper className={styles.textInputContainer}>
                <input
                  id="debitAccountNumber"
                  name="debitAccountNumber"
                  value={downloadReportState.form.debitAccountNumber}
                  onChange={e => onFormValueChange({name: "debitAccountNumber", value: e.target.value})}
                  placeholder="Debit Account Number"
                  className={styles.textInput}
                />
              </Paper>
              
              <p className={styles.inputLabel}>Transaction Reference Number</p>
              <Paper className={styles.textInputContainer}>
                <input
                  id="transactionReferenceNumber"
                  name="transactionReferenceNumber"
                  value={downloadReportState.form.transactionReferenceNumber}
                  onChange={e => onFormValueChange({name: "transactionReferenceNumber", value: e.target.value})}
                  placeholder="Transaction Reference Number"
                  className={styles.textInput}
                />
              </Paper>
            
              <div className={styles.submitButtonContainer}>
                <Button onClick={() => submit()} className={styles.gradientButton}>Download</Button>
              </div>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Modal>
  );
}