import React from 'react';
import { useDispatch } from 'react-redux';
import * as Api from "../../app/Api";
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { closeDeleteBatchNotificationModal, handleGetBatchNotifications } from './batchNotificationSlice';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText } from '@material-ui/core';

export function ConfirmDelete(props) {
    const { batchNotification } = props;
    const dispatch = useDispatch();

    const initialState = {
        submitted: false
    }
    const [dialogState, _setDialogState] = useStateWithCallbackLazy({
        ...initialState
      });

    const submit = async (props) => {
        try {
            _setDialogState({submitted: true})
            const res = await Api.deleteScheduledNotification(batchNotification.id);
            if(!res.ok) {
                const resJson =  await res.json()
                throw resJson.error;
            }
            _setDialogState({submitted: false})
        } catch (err) {
            alert(err);
        } finally {
            dispatch(closeDeleteBatchNotificationModal());
            dispatch(handleGetBatchNotifications(0));
        }
    }

    return (
        <div>
        <Dialog open={true} onClose={() => !dialogState.submitted && dispatch(closeDeleteBatchNotificationModal())} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Delete Scheduled Batch Notification</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to delete this scheduled notification?
                </DialogContentText>  
            </DialogContent>
            <DialogActions>
                <Button onClick={() =>  dispatch(closeDeleteBatchNotificationModal())} color="primary" disabled={dialogState.submitted}>
                    Cancel
                </Button>
                <Button onClick={() => submit(props)} color="primary" disabled={dialogState.submitted}>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
        </div>
    );
}