import { Toolbar, Typography, Tooltip, IconButton, Button } from "@material-ui/core";
import { lighten, makeStyles } from '@material-ui/core/styles';
import RestoreIcon from '@material-ui/icons/Restore';
import styles from './UploadedFile.module.css';

const useToolbarStyles = makeStyles((theme) => ({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      flex: '1 1 100%',
    },
    toolTipContainer: {
        position: "absolute",
        right: 10
    }
}));

export const TableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected, rollbackUploadedFile } = props;
    return (
        <Toolbar>
            { numSelected > 0 ? (
                <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                    Uploaded Files
                </Typography>
            )}
            { 
                numSelected > 0 &&
                <Tooltip title="Rollback" onClick={rollbackUploadedFile}>
                    <IconButton aria-label="Rollback">
                        <RestoreIcon />
                    </IconButton>
                </Tooltip>
            }
        </Toolbar>
    );
};