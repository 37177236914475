import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { Modal, Paper, Grid, Fade, Button, MenuItem, Select } from "@material-ui/core";
import { closeCreateWithdrawalModal, handleGetUsers } from './userSlice';
import * as Api from "../../app/Api";
import { Close } from "@material-ui/icons";
import styles from './User.module.css';

export function CreateWithdrawal(props) {
  const dispatch = useDispatch();
  const initialState = {
    form: {
      amount: 0,
      user: {
        ...props.user,
        bankInfo: {
          holderName: props.user.bankInfo?.holderName,
          accountNumber: props.user.bankInfo?.accountNumber,
          bank: props.user.bankInfo?.bank,
        },
      }
    },
    error: false,
    errorMessage: ""
  };
  const [withdrawalState, _setWithdrawalState] = useStateWithCallbackLazy({
    ...initialState
  });
  const withdrawalStateRef = useRef(withdrawalState);

  const setWithdrawalState = data => {
    withdrawalStateRef.current = data;
    _setWithdrawalState(data);
  };

  const onFormValueChange = (data) => {
    setWithdrawalState({
      ...withdrawalState,
      form: {
        ...withdrawalState.form,
        [data.name]: data.value
      }
    })
  }

  const onBankInfoValueChange = (data) => {
    setWithdrawalState({
      ...withdrawalState,
      form: {
        ...withdrawalState.form,
        user: {
          ...withdrawalState.form.user,
          bankInfo: {
            ...withdrawalState.form.user.bankInfo,
            [data.name]: data.value
          }
        }
      }
    })
  }

  const submit = async () => {
    let error = false;

    if (withdrawalState.form.amount == null || withdrawalState.form.amount == "") {
      error = true;
      setWithdrawalState({...withdrawalState, error: true, errorMessage: "All fields are required"});
      return;
    }

    if (withdrawalState.form.amount <= 0) {
      error = true;
      setWithdrawalState({...withdrawalState, error: true, errorMessage: "Amount must be more than 0"});
      return;
    }

    if (!withdrawalState.form.user.availableAmount) {
      error = true;
      setWithdrawalState({...withdrawalState, error: true, errorMessage: "The user's available amount is 0"});
      return;
    }

    if (withdrawalState.form.user.availableAmount && ((withdrawalState.form.amount * 100) > withdrawalState.form.user.availableAmount)) {
      error = true;
      setWithdrawalState({...withdrawalState, error: true, errorMessage: "The cashout amount cannot be more than user's available amount"});
      return;
    }

    for (var key in withdrawalState.form.user.bankInfo) {
      if (withdrawalState.form.user.bankInfo[key] == null || withdrawalState.form.user.bankInfo[key] == "") {
        error = true;
        setWithdrawalState({...withdrawalState, error: true, errorMessage: "All bank account details are required."});
        return;
      }
    }

    let payload = {
      ...withdrawalState.form,
      amount: withdrawalState.form.amount * 100,
      user: { id: withdrawalState.form.user.id }
    }

    if (!error) {
      try {
        const resUser = await Api.updateUser(withdrawalState.form.user, withdrawalState.form.user.id);
        if(!resUser.ok) {
          const resJson =  await res.json()
          throw resJson.error;
        }

        const res = await Api.createWithdrawal(payload);
        if(res.error) {
          const resJson =  await res.json()
          throw resJson.error;
        };
        
        dispatch(handleGetUsers());
        dispatch(closeCreateWithdrawalModal());
      } catch (err) {
        setWithdrawalState({...withdrawalState, error: true, errorMessage: err});
      }
    }
  }

  return (
    <Modal
      open={true}
      aria-labelledby="add-order-group-modal"
      className={styles.modal}
    >
      <Paper className={styles.modalContainer}>
        <div className={styles.container}>
          <Close className={styles.closeButton} onClick={() => dispatch(closeCreateWithdrawalModal())}/>
          <h3 className={styles.subtitle}>Cash Out for User</h3>
          <Fade in={withdrawalState.error}>
            <p className={styles.errorMessage}>{withdrawalState.errorMessage}</p>
          </Fade>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <p>Name: {withdrawalState.form.user.firstName} {withdrawalState.form.user.lastName}</p>
              <p>Username/Email: {withdrawalState.form.user.userName}</p>
              <p>Total Salary Earned: RM {withdrawalState.form.user.salaryEarned ? withdrawalState.form.user.salaryEarned / 100 : 0}</p>
              <p>Available Amount: RM {withdrawalState.form.user.availableAmount ? withdrawalState.form.user.availableAmount / 100 : 0}</p>
            </Grid>
            <Grid item xs={6}>
              <p>Bank</p>
              <Paper className={styles.textInputContainer}>
                <Select
                  labelId="bank"
                  id="bank"
                  value={withdrawalState.form.user.bankInfo.bank}
                  label="Bank"
                  onChange={e => onBankInfoValueChange({name: "bank", value: e.target.value})}
                  className={styles.textInput}
                >
                  {
                    bankValues.map((bank) => (
                      <MenuItem value={bank.value}>{bank.label}</MenuItem>
                    ))
                  }
                </Select>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <p>Account Holder Name</p>
              <Paper className={styles.textInputContainer}>
                <input
                  id="holderName"
                  name="holderName"
                  value={withdrawalState.form.user.bankInfo.holderName}
                  onChange={e => onBankInfoValueChange({name: "holderName", value: e.target.value})}
                  placeholder="Account Holder Name"
                  className={styles.textInput}
                />
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <p>Account Number</p>
              <Paper className={styles.textInputContainer}>
                <input
                  id="accountNumber"
                  name="accountNumber"
                  value={withdrawalState.form.user.bankInfo.accountNumber}
                  onChange={e => onBankInfoValueChange({name: "accountNumber", value: e.target.value})}
                  placeholder="Account Number"
                  className={styles.textInput}
                />
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <p>Amount</p>
              <Paper className={styles.textInputContainer}>
                <input
                  id="amount"
                  name="amount"
                  value={withdrawalState.form.amount}
                  onChange={e => onFormValueChange({name: "amount", value: e.target.value})}
                  placeholder="Amount (RM)"
                  type="number"
                  className={styles.textInput}
                />
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <div className={styles.submitButtonContainer}>
                <Button onClick={() => submit()} className={styles.gradientButton}>Create</Button>
              </div>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Modal>
  );
}

const bankValues = [
  {label: 'AFFIN BANK BERHAD', value: 'AFFIN'},
  {label: 'AFFIN ISLAMIC BANK BERHAD', value: 'AFFIN_ISLAMIC'},
  {label: 'AGRO BANK', value: 'AGRO'},
  {label: 'AL-RAJHI BKG & INVT CORP (M) BHD', value: 'AL_RAJHI'},
  {label: 'ALLIANCE BANK MALAYSIA BERHAD', value: 'ALLIANCE'},
  {label: 'AMBANK BERHAD', value: 'AMBANK'},
  {label: 'AMISLAMIC BANK BERHAD', value: 'AMISLAMIC'},
  {label: 'BANGKOK BANK BERHAD', value: 'BBB'},
  {label: 'BANK ISLAM MALAYSIA BERHAD', value: 'BANK_ISLAM'},
  {label: 'BANK KERJASAMA RAKYAT', value: 'BKRM'},
  {label: 'BANK KERJASAMA RAKYAT MALAYSIA BHD', value: 'BANK_RAKYAT'},
  {label: 'BANK MUAMALAT MSIA BHD', value: 'MUAMALAT'},
  {label: 'BANK OF AMERICA', value: 'BOA'},
  {label: 'BANK OF CHINA (MALAYSIA) BERHAD', value: 'BANK_OF_CHINA'},
  {label: 'BANK SIMPANAN NASIONAL BHD', value: 'BSN'},
  {label: 'BNP PARIBAS MALAYSIA BERHAD', value: 'BNPP'},
  {label: 'CHINA CONSTRUCTION BANK (M) BERHAD', value: 'CCBM'},
  {label: 'CIMB BANK BERHAD', value: 'CIMB'},
  {label: 'CIMB ISLAMIC BANK BERHAD', value: 'CIMB_ISLAMIC'},
  {label: 'CITIBANK BERHAD', value: 'CITIBANK'},
  {label: 'DEUTSCHE BANK (MALAYSIA) BHD', value: 'DEUSTCHE'},
  {label: 'HONG LEONG BANK BERHAD', value: 'HONG_LEONG'},
  {label: 'HONG LEONG ISLAMIC BANK BERHAD', value: 'HL_ISLAMIC'},
  {label: 'HSBC AMANAH MALAYSIA BERHAD', value: 'HSBC_AMANAH'},
  {label: 'HSBC BANK MALAYSIA BERHAD', value: 'HSBC'},
  {label: 'IND AND COMM BK OF CHINA (M) BHD', value: 'ICBC'},
  {label: 'J.P. MORGAN CHASE BANK BERHAD', value: 'CHASE'},
  {label: 'KUWAIT FINANCE HOUSE (M) BERHAD', value: 'KFH'},
  {label: 'MALAYAN BANKING BERHAD', value: 'MBB'},
  {label: 'MAYBANK ISLAMIC BERHAD', value: 'MAYBANK_ISLAMIC'},
  {label: 'MBSB BANK BERHAD', value: 'MBSB'},
  {label: 'MIZUHO CORPORATE BANK (MSIA) BHD', value: 'MCBM'},
  {label: 'MUFG BANK (MALAYSIA) BERHAD', value: 'MUFG'},
  {label: 'OCBC AL-AMIN BANK BERHAD', value: 'OCBC_AL_AMIN'},
  {label: 'OCBC BANK MALAYSIA BHD', value: 'OCBC'},
  {label: 'PUBLIC BANK BERHAD', value: 'PUBLIC'},
  {label: 'PUBLIC ISLAMIC BANK BERHAD', value: 'PUBLIC_ISLAMIC'},
  {label: 'RHB BANK BERHAD', value: 'RHBB'},
  {label: 'RHB ISLAMIC BANK BERHAD', value: 'RHB_ISLAMIC'},
  {label: 'STANDARD CHARTERED BANK MALAYSIA BE', value: 'SCBL'},
  {label: 'STANDARD CHARTERED SAADIQ BERHAD', value: 'SC_SAADIQ'},
  {label: 'SUMITOMO MITSUI BANKING CORP MY BHD', value: 'SMBC'},
  {label: 'UNITED OVERSEAS BANK BHD', value: 'UOB'}
];