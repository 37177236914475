import { createSlice } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading';
import * as Api from "../../app/Api";

export const hotspotGroupSlice = createSlice({
  name: 'hotspotGroup',
  initialState: {
    hotspotGroups: [],
    status: 'idle',
    createHotspotGroupModalOpen: false,
    editHotspotGroupModalOpen: false,
    deleteHotspotGroupModalOpen: false,
    pagination: {},
    page: 0,
    rowsPerPage: 10,
    selectedId: null
  },
  reducers: {
    openCreateHotspotGroupModal: (state) => {
      state.createHotspotGroupModalOpen = true;
    },
    closeCreateHotspotGroupModal: (state) => {
      state.createHotspotGroupModalOpen = false;
    },
    openEditHotspotGroupModal: (state) => {
      state.editHotspotGroupModalOpen = true;
    },
    closeEditHotspotGroupModal: (state) => {
      state.editHotspotGroupModalOpen = false;
    },
    openDeleteHotspotGroupModal: (state) => {
      state.deleteHotspotGroupModalOpen = true;
    },
    closeDeleteHotspotGroupModal: (state) => {
      state.deleteHotspotGroupModalOpen = false;
    },
    setSelectedId: (state, action) => {
      state.selectedId = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload;
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
    getHotspotGroups: (state, action) => {
      state.hotspotGroups = action.payload;
      state.status = 'success'
    },
  },
});

export const { openCreateHotspotGroupModal, closeCreateHotspotGroupModal, openEditHotspotGroupModal, closeEditHotspotGroupModal, openDeleteHotspotGroupModal, closeDeleteHotspotGroupModal, setSelectedId, setPage, setRowsPerPage, setPagination, getHotspotGroups } = hotspotGroupSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.hotspotGroup.value)`
export const selectState = state => state.hotspotGroup;

export const handleGetHotspotGroups = () => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();

  try {
    const res = await Api.getHotspotGroups(state.hotspotGroup.page, state.hotspotGroup.rowsPerPage);
    if (res.status === 401 || res.status == 403) {
      Api.logout();
      throw res.error;
    } else if (res.error) {
      throw res.error;
    };
    
    const { content, ...pagination } = res;
    let hotspotGroups = content;

    dispatch(setPagination(pagination));
    dispatch(getHotspotGroups(hotspotGroups));
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(hideLoading());
  }
};

export const getPlaceLongName = (address_components, type) => {
  var component = address_components.find(c => c.types.includes(type))
  if (component != null) {
    return component.long_name
  }
  return null
}


export default hotspotGroupSlice.reducer;
