import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox, TablePagination, TableFooter } from "@material-ui/core";
import { TableToolbar } from './TableToolbar';
import { handleGetWithdrawals, selectState, setSelectedIds, setPage, setRowsPerPage, openConfirmWithdrawModal, openDownloadReportModal, openRejectWithdrawModal, openDownloadCashedOutReportModal } from './withdrawalSlice';
import TablePaginationActions from './TablePaginationActions';
import { ConfirmWithdraw } from './ConfirmWithdraw';
import { RejectWithdraw } from './RejectWithdraw';
import { DownloadReport } from './DownloadReport';
import { DownloadCashedOutReport } from './DownloadCashedOutReport';
import styles from './Withdrawal.module.css';
import Moment from 'moment';

export function Withdrawal(props) {
  const state = useSelector(selectState);
  const dispatch = useDispatch();
  
  useEffect(() => {
    if (state.status === 'idle') {
      dispatch(handleGetWithdrawals(props.withdrawStatus));
    }
  });

  const handleChangePage = (event, newPage) => {
    dispatch(setPage(newPage));
    dispatch(handleGetWithdrawals(props.withdrawStatus));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setRowsPerPage(parseInt(event.target.value, 10)));
    dispatch(setPage(0));
    dispatch(handleGetWithdrawals(props.withdrawStatus));
  };

  const getBankName = (shortName) => {
    return bankValues.find(b => b.value == shortName) != undefined ? bankValues.find(b => b.value == shortName).label : shortName
  };

  return (
    <div className={styles.container}>
      <TableContainer component={Paper} className={styles.denseTable}>
        <TableToolbar
          numSelected={state.selectedIds.length}
          withdrawStatus={props.withdrawStatus}
          confirmWithdrawal={() => dispatch(openConfirmWithdrawModal())}
          rejectWithdrawal={() => dispatch(openRejectWithdrawModal())}
          downloadReport={() => dispatch(openDownloadReportModal())}
          downloadCashedOutReport={() => dispatch(openDownloadCashedOutReportModal())}
        />
        <Table size="small">
          <TableHead className={styles.tableHeader}>
            <TableRow>
              <TableCell align="left" className={styles.tableHeader}>{" "}</TableCell>
              <TableCell align="left" className={styles.tableHeaderNarrow}>ID</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Rider Name</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Contact Number</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Bank Name</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Account Number</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Account Holder Name</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Early Amount (RM)</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Normal Amount (RM)</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Payment Amount (RM)</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Service Charge</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Is Early Cashout</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Status</TableCell>
              <TableCell align="left" className={styles.tableHeader}>{props.withdrawStatus == "CASHED_OUT" ? "Paid At" : "Requested At"}</TableCell>
              {props.withdrawStatus == "CASHING_OUT" && <TableCell align="left" className={styles.tableHeader}>Effective Date</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {
              state.withdrawals.length > 0 ? (
                state.withdrawals.map((withdrawal) => (
                  <TableRow 
                    key={withdrawal.id}
                    hover
                    onClick={() => dispatch(setSelectedIds(state.selectedIds.includes(withdrawal.id) ? state.selectedIds.filter(id => id != withdrawal.id) : [...state.selectedIds, withdrawal.id]))}
                    role="checkbox"
                    aria-checked={state.selectedIds.includes(withdrawal.id)}
                    selected={state.selectedIds.includes(withdrawal.id)}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox 
                        color="primary"
                        checked={state.selectedIds.includes(withdrawal.id)}
                      />
                    </TableCell>
                    <TableCell align="left" className={styles.tableCellNarrow}>{withdrawal.id}</TableCell>
                    <TableCell align="left">{`${withdrawal.user.firstName} ${withdrawal.user.lastName ? withdrawal.user.lastName : "" } `}</TableCell>
                    <TableCell align="left">{withdrawal.user.contactNumber}</TableCell>
                    <TableCell align="left">{withdrawal.bankInfo ? getBankName(withdrawal.bankInfo.bank) : getBankName(withdrawal.user.bankInfo?.bank)}</TableCell>
                    <TableCell align="left">{withdrawal.bankInfo ? withdrawal.bankInfo.accountNumber : withdrawal.user.bankInfo?.accountNumber}</TableCell>
                    <TableCell align="left">{withdrawal.bankInfo ? withdrawal.bankInfo.holderName : withdrawal.user.bankInfo?.holderName}</TableCell>
                    <TableCell align="left">{withdrawal.earlyAmount ? withdrawal.earlyAmount / 100 : 0}</TableCell>
                    <TableCell align="left">{withdrawal.normalAmount ? withdrawal.normalAmount / 100 : 0}</TableCell>
                    <TableCell align="left">{withdrawal.paymentAmount ? withdrawal.paymentAmount / 100 : 0}</TableCell>
                    <TableCell align="left">{- ((withdrawal.amount - withdrawal.paymentAmount) / 100).toFixed(2)}</TableCell>
                    <TableCell align="left">{withdrawal.earlyCashOut ? "Yes" : "No" }</TableCell>
                    <TableCell align="left">{withdrawal.status}</TableCell>
                    <TableCell align="left">{new Date(props.withdrawStatus == "CASHED_OUT" ? withdrawal.updatedAt : withdrawal.createdAt).toString()}</TableCell>
                    {props.withdrawStatus == "CASHING_OUT" && <TableCell align="left">{Moment(withdrawal.createdAt).add(3, "days").toString()}</TableCell>}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={11} align="center">
                    <p>No Cashout Requests</p>
                  </TableCell>
                </TableRow>
              )
            }
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                colSpan={7}
                count={state.pagination ? state.pagination.totalElements ? state.pagination.totalElements : 0 : 0}
                rowsPerPage={state.rowsPerPage}
                page={state.page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      {
        state.confirmWithdrawModalOpen  &&
        <ConfirmWithdraw withdrawStatus={props.withdrawStatus} withdrawals={state.selectedIds.length > 0 ? state.withdrawals.filter(withdrawal => state.selectedIds.includes(withdrawal.id)) : []} />
      }
      {
        state.rejectWithdrawModalOpen  &&
        <RejectWithdraw withdrawStatus={props.withdrawStatus} withdrawals={state.selectedIds.length > 0 ? state.withdrawals.filter(withdrawal => state.selectedIds.includes(withdrawal.id)) : []} />
      }
      {
        state.downloadReportModalOpen  &&
        <DownloadReport downloadReportModalOpen={state.downloadReportModalOpen} withdrawStatus={props.withdrawStatus} />
      }
      {
        state.downloadCashedOutReportModalOpen  &&
        <DownloadCashedOutReport downloadCashedOutReportModalOpen={state.downloadCashedOutReportModalOpen} withdrawStatus={props.withdrawStatus} />
      }
    </div>
  );
}

const bankValues = [
  {label: 'AFFIN BANK BERHAD', value: 'AFFIN'},
  {label: 'AFFIN ISLAMIC BANK BERHAD', value: 'AFFIN_ISLAMIC'},
  {label: 'AGRO BANK', value: 'AGRO'},
  {label: 'AL-RAJHI BKG & INVT CORP (M) BHD', value: 'AL_RAJHI'},
  {label: 'ALLIANCE BANK MALAYSIA BERHAD', value: 'ALLIANCE'},
  {label: 'AMBANK BERHAD', value: 'AMBANK'},
  {label: 'AMISLAMIC BANK BERHAD', value: 'AMISLAMIC'},
  {label: 'BANGKOK BANK BERHAD', value: 'BBB'},
  {label: 'BANK ISLAM MALAYSIA BERHAD', value: 'BANK_ISLAM'},
  {label: 'BANK KERJASAMA RAKYAT', value: 'BKRM'},
  {label: 'BANK KERJASAMA RAKYAT MALAYSIA BHD', value: 'BANK_RAKYAT'},
  {label: 'BANK MUAMALAT MSIA BHD', value: 'MUAMALAT'},
  {label: 'BANK OF AMERICA', value: 'BOA'},
  {label: 'BANK OF CHINA (MALAYSIA) BERHAD', value: 'BANK_OF_CHINA'},
  {label: 'BANK SIMPANAN NASIONAL BHD', value: 'BSN'},
  {label: 'BNP PARIBAS MALAYSIA BERHAD', value: 'BNPP'},
  {label: 'CHINA CONSTRUCTION BANK (M) BERHAD', value: 'CCBM'},
  {label: 'CIMB BANK BERHAD', value: 'CIMB'},
  {label: 'CIMB ISLAMIC BANK BERHAD', value: 'CIMB_ISLAMIC'},
  {label: 'CITIBANK BERHAD', value: 'CITIBANK'},
  {label: 'DEUTSCHE BANK (MALAYSIA) BHD', value: 'DEUSTCHE'},
  {label: 'HONG LEONG BANK BERHAD', value: 'HONG_LEONG'},
  {label: 'HONG LEONG ISLAMIC BANK BERHAD', value: 'HL_ISLAMIC'},
  {label: 'HSBC AMANAH MALAYSIA BERHAD', value: 'HSBC_AMANAH'},
  {label: 'HSBC BANK MALAYSIA BERHAD', value: 'HSBC'},
  {label: 'IND AND COMM BK OF CHINA (M) BHD', value: 'ICBC'},
  {label: 'J.P. MORGAN CHASE BANK BERHAD', value: 'CHASE'},
  {label: 'KUWAIT FINANCE HOUSE (M) BERHAD', value: 'KFH'},
  {label: 'MALAYAN BANKING BERHAD', value: 'MBB'},
  {label: 'MAYBANK ISLAMIC BERHAD', value: 'MAYBANK_ISLAMIC'},
  {label: 'MBSB BANK BERHAD', value: 'MBSB'},
  {label: 'MIZUHO CORPORATE BANK (MSIA) BHD', value: 'MCBM'},
  {label: 'MUFG BANK (MALAYSIA) BERHAD', value: 'MUFG'},
  {label: 'OCBC AL-AMIN BANK BERHAD', value: 'OCBC_AL_AMIN'},
  {label: 'OCBC BANK MALAYSIA BHD', value: 'OCBC'},
  {label: 'PUBLIC BANK BERHAD', value: 'PUBLIC'},
  {label: 'PUBLIC ISLAMIC BANK BERHAD', value: 'PUBLIC_ISLAMIC'},
  {label: 'RHB BANK BERHAD', value: 'RHBB'},
  {label: 'RHB ISLAMIC BANK BERHAD', value: 'RHB_ISLAMIC'},
  {label: 'STANDARD CHARTERED BANK MALAYSIA BE', value: 'SCBL'},
  {label: 'STANDARD CHARTERED SAADIQ BERHAD', value: 'SC_SAADIQ'},
  {label: 'SUMITOMO MITSUI BANKING CORP MY BHD', value: 'SMBC'},
  {label: 'UNITED OVERSEAS BANK BHD', value: 'UOB'}
];