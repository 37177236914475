import { createSlice } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading';
import { subDays, format } from 'date-fns'
import * as Api from "../../app/Api";

export const paymentSlice = createSlice({
  name: 'payment',
  initialState: {
    payments: [],
    status: 'idle',
    uploadPaymentModalOpen: false,
    editPaymentModalOpen: false,
    dateRangeModalOpen: false,
    pagination: {},
    page: 0,
    rowsPerPage: 10,
    selectedId: null,
    deliveryPlatform: 'ALL',
    startDate: format(subDays(new Date(), 1), 'yyyy-MM-dd'),
    endDate: format(new Date(), 'yyyy-MM-dd'),
  },
  reducers: {
    openUploadPaymentModal: (state) => {
      state.uploadPaymentModalOpen = true;
    },
    closeUploadPaymentModal: (state) => {
      state.uploadPaymentModalOpen = false;
    },
    openEditPaymentModal: (state) => {
      state.editPaymentModalOpen = true;
    },
    closeEditPaymentModal: (state) => {
      state.editPaymentModalOpen = false;
    },
    openDateRangeModal: (state) => {
      state.dateRangeModalOpen = true;
    },
    closeDateRangeModal: (state) => {
      state.dateRangeModalOpen = false;
    },
    setPayments: (state, action) => {
      state.payments = [...action.payload];
      state.status = 'success';
    },
    setSelectedId: (state, action) => {
      state.selectedId = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload;
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
    setDateRange: (state, action) => {
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
    },
    setDeliveryPlatform: (state, action) => {
      state.deliveryPlatform = action.payload;
    }
  },
});

export const { setPayments, setSelectedId, setPage, setRowsPerPage, setPagination, setDateRange, setDeliveryPlatform, openDateRangeModal, closeDateRangeModal, openUploadPaymentModal, closeUploadPaymentModal, openEditPaymentModal, closeEditPaymentModal } = paymentSlice.actions;

export const handleGetPayments = () => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();
  try {
    const res = await Api.getPayments(state.payment.page, state.payment.rowsPerPage, state.payment.startDate.substring(0, 10), state.payment.endDate.substring(0, 10), state.payment.deliveryPlatform == "ALL" ? Api.getDeliveryPlatforms().join() : state.payment.deliveryPlatform);
    if (res.status == 401) {
      Api.logout();
      throw res.error;
    }
    const { content, ...pagination } = res;
    let payments = content;

    dispatch(setPagination(pagination));
    dispatch(setPayments(payments));
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(hideLoading());
  }
};

export const handleGetPaymentsReport = () => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();
  
  try {
    const res = await Api.getPaymentReports(state.payment.startDate, state.payment.endDate);
    if (res.status === 401 || res.status === 403) {
      Api.logout();
      throw res.error;
    } else if (res.error) {
      throw res.error;
    };
    
    let report = await res.blob();
    // 2. Create blob link to download
    const url = window.URL.createObjectURL(new Blob([report]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `deliverit_rider_payment_report_${state.payment.startDate}-${state.payment.endDate}.csv`);
    // 3. Append to html page
    document.body.appendChild(link);
    // 4. Force download
    link.click();
    // 5. Clean up and remove the link
    link.parentNode.removeChild(link);
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(hideLoading());
  }
};

export const selectState = state => state.payment;

export default paymentSlice.reducer;
