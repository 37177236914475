import React from 'react';
import { useDispatch } from 'react-redux';
import * as Api from "../../app/Api";
import { closeDeleteHotspotModal, handleGetHotspotsByHotspotGroup } from './hotspotSlice';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText } from '@material-ui/core';

export function ConfirmDelete(props) {
    const { hotspot } = props;
    const dispatch = useDispatch();

    const submit = async () => {
        try {
            const res = await Api.deleteHotspot(props.hotspotGroup.id, hotspot.id);
            if(res.error) throw res.error;
    
            dispatch(closeDeleteHotspotModal());
            dispatch(handleGetHotspotsByHotspotGroup(props.hotspotGroup.id));
        } catch (err) {
            alert(err);
        }
    }

    return (
        <div>
        <Dialog open={true} onClose={() => dispatch(closeDeleteHotspotModal())} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Delete Hotspot</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to delete this hotspot?
                </DialogContentText>  
            </DialogContent>
            <DialogActions>
                <Button onClick={() =>  dispatch(closeDeleteHotspotModal())} color="primary">
                    Cancel
                </Button>
                <Button onClick={() => submit()} color="primary">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
        </div>
    );
}