import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox, TablePagination, TableFooter } from "@material-ui/core";
import { TableToolbar } from './TableToolbar';
import { handleGetArchivedDailyWorks, selectState, setSelectedId, setPage, setRowsPerPage } from './archivedDailyWorkSlice';
import TablePaginationActions from './TablePaginationActions';
import styles from './ArchivedDailyWork.module.css';

export function ArchivedDailyWork(props) {
  const state = useSelector(selectState);
  const dispatch = useDispatch();
  
  useEffect(() => {
    if (state.status === 'idle') {
      dispatch(handleGetArchivedDailyWorks());
    }
  });

  const handleChangePage = (event, newPage) => {
    dispatch(setPage(newPage));
    dispatch(handleGetArchivedDailyWorks());
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setRowsPerPage(parseInt(event.target.value, 10)));
    dispatch(setPage(0));
    dispatch(handleGetArchivedDailyWorks());
  };

  return (
    <div className={styles.container}>
      <TableContainer component={Paper} className={styles.denseTable}>
        <TableToolbar 
          numSelected={state.selectedId == null ? 0 : 1}
        />
        <Table size="small">
          <TableHead className={styles.tableHeader}>
            <TableRow>
              <TableCell align="left" className={styles.tableHeader}>{" "}</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Payment Date</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Platform ID</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Name</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Amount</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Bonus</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Completed Order</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Average Order</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Transit Time</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Delivery Platform</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              state.archivedDailyWorks.length > 0 ? (
                state.archivedDailyWorks.map((archivedDailyWork) => (
                  <TableRow 
                    key={archivedDailyWork.id}
                    hover
                    onClick={() => dispatch(setSelectedId(state.selectedId == archivedDailyWork.id ? null : archivedDailyWork.id))}
                    role="checkbox"
                    aria-checked={state.selectedId == archivedDailyWork.id ? true : false}
                    selected={state.selectedId == archivedDailyWork.id ? true : false}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox 
                        color="primary"
                        checked={state.selectedId == archivedDailyWork.id ? true : false}
                      />
                    </TableCell>
                    <TableCell align="left">{archivedDailyWork.dailyWork.date}</TableCell>
                    <TableCell align="left">{archivedDailyWork.dailyWork.user.platformIds?.join(", ")}</TableCell>
                    <TableCell align="left">{archivedDailyWork.dailyWork.user.firstName}</TableCell>
                    <TableCell align="left">{archivedDailyWork.dailyWork.salaryDetail.amount / 100}</TableCell>
                    <TableCell align="left">{archivedDailyWork.dailyWork.salaryDetail.bonus / 100}</TableCell>
                    <TableCell align="left">{archivedDailyWork.dailyWork.completedOrder}</TableCell>
                    <TableCell align="left">{(archivedDailyWork.dailyWork.averageOrderAnHour && typeof archivedDailyWork.dailyWork.averageOrderAnHour === 'number') ? archivedDailyWork.dailyWork.averageOrderAnHour.toFixed(3) : archivedDailyWork.dailyWork.averageOrderAnHour}</TableCell>
                    <TableCell align="left">{new Date(archivedDailyWork.dailyWork.transitTime * 1000).toISOString().substr(11, 8)}</TableCell>
                    <TableCell align="left">{archivedDailyWork.dailyWork.deliveryPlatform}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={9} align="center">
                    <p>No Archived Payments</p>
                  </TableCell>
                </TableRow>
              )
            }
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                colSpan={7}
                count={state.pagination ? state.pagination.totalElements ? state.pagination.totalElements : 0 : 0}
                rowsPerPage={state.rowsPerPage}
                page={state.page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
}
