import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { Modal, Paper, Grid, Fade, Button } from "@material-ui/core";
import { closeEditPaymentModal, handleGetPayments } from './paymentSlice';
import * as Api from "../../app/Api";
import { Close } from "@material-ui/icons";
import styles from './Payment.module.css';

export function EditPayment(props) {
  const dispatch = useDispatch();
  const initialState = {
    payment: {
      ...props.payment,
    },
    form: {
      ...props.payment.salaryDetail,
      amount: props.payment.salaryDetail.amount / 100,
      bonus: props.payment.salaryDetail.bonus / 100,
    },
    initPaymentInfo: false,
    error: false,
    errorMessage: ""
  };
  const [paymentState, _setPaymentState] = useStateWithCallbackLazy({
    ...initialState
  });
  const paymentStateRef = useRef(paymentState);

  const setPaymentState = data => {
    paymentStateRef.current = data;
    _setPaymentState(data);
  };

  const onFormValueChange = (data) => {
    setPaymentState({
      ...paymentState,
      form: {
        ...paymentState.form,
        [data.name]: data.value
      }
    })
  }

  const submit = async () => {
    let error = false;

    delete paymentState.form.version;
    if (paymentState.form.amount == null || paymentState.form.amount == "" || paymentState.form.bonus == null || paymentState.form.bonus == "") {
      error = true;
      setPaymentState({...paymentState, error: true, errorMessage: "All fields are required"});
      return;
    }

    let amountChanged = (paymentState.form.amount * 100) != paymentState.payment.salaryDetail.amount

    let payload = {
      ...paymentState.form,
      amount: paymentState.form.amount * 100,
      bonus: paymentState.form.bonus * 100,
      // reset breakdown if amount changed
      baseAmount: amountChanged ? 0 : paymentState.payment.salaryDetail.baseAmount,
      tier1Amount: amountChanged ? 0 : paymentState.payment.salaryDetail.tier1Amount,
      tier2Amount: amountChanged ? 0 : paymentState.payment.salaryDetail.tier2Amount
    }

    if (!error) {
      try {
        const res = await Api.adjustSalary(payload, paymentState.payment.id);
        if(res.error) throw res.error;
        
        dispatch(handleGetPayments());
        dispatch(closeEditPaymentModal());
      } catch (err) {
        setPaymentState({...paymentState, error: true, errorMessage: err});
      }
    }
  }

  return (
    <Modal
      open={true}
      aria-labelledby="add-order-group-modal"
      className={styles.modal}
    >
      <Paper className={styles.modalContainer}>
        <div className={styles.container}>
          <Close className={styles.closeButton} onClick={() => dispatch(closeEditPaymentModal())}/>
          <h3 className={styles.subtitle}>Edit Payment - {paymentState.payment.user?.firstName} {paymentState.payment.user?.lastName}</h3>
          <Fade in={paymentState.error}>
            <p className={styles.errorMessage}>{paymentState.errorMessage}</p>
          </Fade>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <p>Salary Amount</p>
              <Paper className={styles.textInputContainer}>
                <input
                  id="amount"
                  name="amount"
                  value={paymentState.form.amount}
                  onChange={e => onFormValueChange({name: "amount", value: e.target.value})}
                  placeholder="Salary Amount"
                  className={styles.textInput}
                />
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <p>Bonus</p>
              <Paper className={styles.textInputContainer}>
                <input
                  id="bonus"
                  name="bonus"
                  value={paymentState.form.bonus}
                  onChange={e => onFormValueChange({name: "bonus", value: e.target.value})}
                  placeholder="Bonus"
                  className={styles.textInput}
                />
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <div className={styles.submitButtonContainer}>
                <Button onClick={() => submit()} className={styles.gradientButton}>Submit</Button>
              </div>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Modal>
  );
}
