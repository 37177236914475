/*global google*/
import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { Modal, Paper, Grid, Fade, Button } from "@material-ui/core";
import { closeEditHotspotModal, handleGetHotspotsByHotspotGroup, getPlaceLongName } from './hotspotSlice';
import * as Api from "../../app/Api";
import { Close } from "@material-ui/icons";
import styles from './Hotspot.module.css';

export function EditHotspot(props) {
  const { hotspot } = props;
  const dispatch = useDispatch();
  const [hotspotState, _setHotspotState] = useStateWithCallbackLazy({
    form: {
      ...hotspot
    },
    isPlaceChanged: true,
    error: false,
    init: false,
    autocompleteInit: false,
    errorMessage: "",
    isSubmitDisabled: false
  });

  const hotspotStateRef = useRef(hotspotState);

  const setHotspotState = data => {
    hotspotStateRef.current = data;
    _setHotspotState(data);
  };

  useEffect(() => {
    if (hotspot && !hotspotState.init) {
      const {version, ...hotspotForm} = {...hotspot};
      setHotspotState({
        ...hotspotState,
        init: true,
        form: { 
          ...hotspotForm
        }
      });
    }

    let locationAutocompleteInput = document.getElementById("locationAutocomplete");
    if (!hotspotState.autocompleteInit && locationAutocompleteInput !== null) {
      initializeGoogleAutocomplete("locationAutocomplete");
      setHotspotState({
        ...hotspotState,
        autocompleteInit: true,
      });
    }
  });

  const onPlaceChanged = (autocomplete, elementId) => {
    var place = autocomplete.getPlace();
    if (place) {
      if (elementId === "locationAutocomplete") {
        _setHotspotState({
          ...hotspotStateRef.current,
          isPlaceChanged: true,
          form: {
            ...hotspotStateRef.current.form,
            location: {
              ...hotspotStateRef.current.form.location,
              address: `${place.name}, ${place.formatted_address}`,
              coordinate: {
                latitude: place.geometry.location.lat(),
                longitude: place.geometry.location.lng(),
              },
              street: getPlaceLongName(place.address_components, "street_address"),
              apartment: getPlaceLongName(place.address_components, "street_address"),
              state: getPlaceLongName(place.address_components, "administrative_area_level_1"),
              postalCode: getPlaceLongName(place.address_components, "postal_code"),
              city: getPlaceLongName(place.address_components, "locality"),
              country: getPlaceLongName(place.address_components, "country")
            }
          }
        });
      }
    }
  }

  const initializeGoogleAutocomplete = (elementId) => {
    let autocompleteInput = document.getElementById(elementId);
    let autocomplete = new google.maps.places.Autocomplete(
      autocompleteInput,
      {
        types: ["geocode", "establishment"],
        componentRestrictions: { country: ["my"] }
      }
    );

    google.maps.event.addListener(autocomplete, "place_changed", function() {
      onPlaceChanged(autocomplete, elementId);
    });
  }

  const submit = async (props) => {
    setHotspotState({ ...hotspotState, isSubmitDisabled: true });

    let error = false;

    if (hotspotState.isPlaceChanged == false) {
      error = true;
      setHotspotState({...hotspotState, error: true, errorMessage: "Please select address from the drop down", isSubmitDisabled: false});
      return;
    }

    for (var key in hotspotState.form) {
      if (hotspotState.form[key] === null || hotspotState.form[key] === "") {
        error = true;
        setHotspotState({...hotspotState, error: true, errorMessage: "All fields are required", isSubmitDisabled: false});
        return;
      }
    }

    if (hotspotState.form.location.name == null || hotspotState.form.location.name == "") {
      error = true;
      setHotspotState({...hotspotState, error: true, errorMessage: "Hotspot name is required"});
      return;
    }

    if (hotspotState.form.location.country == null || hotspotState.form.location.country == "") {
      error = true;
      setHotspotState({...hotspotState, error: true, errorMessage: "Please re-select the pick up address from dropdown list", isSubmitDisabled: false});
      return;
    }

    let hotspotPayload = { ...hotspotState.form }
    
    if (!error) {
      try {
        const res = await Api.updateHotspot(props.hotspotGroup.id, hotspotPayload, hotspot.id);
        if(res.error) throw res.error;
        dispatch(closeEditHotspotModal());
        dispatch(handleGetHotspotsByHotspotGroup(props.hotspotGroup.id));
      } catch (err) {
        setHotspotState({...hotspotState, error: true, errorMessage: err, isSubmitDisabled: false});
      }
    }
  }

  return (
    <Modal
      open={true}
      aria-labelledby="edit-hotspot-modal"
      className={styles.modal}
    >
      <Paper className={styles.modalContainer}>
        <div className={styles.container}>
          <Close className={styles.closeButton} onClick={() => dispatch(closeEditHotspotModal())}/>
          <h3 className={styles.subtitle}>Edit Hotspot<em style={{fontSize: 12}}>(All fields are required)</em></h3>
          <Fade in={hotspotState.error}>
            <p className={styles.errorMessage}>{hotspotState.errorMessage}</p>
          </Fade>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <p className={styles.inputLabel}>Name</p>
              <Paper className={styles.textInputContainer}>
                <input
                  id="name"
                  name="name"
                  value={hotspotState.form.location.name}
                  onChange={e => setHotspotState({
                    ...hotspotState,
                    form: {
                      ...hotspotState.form,
                      location: {
                        ...hotspotState.form.location,
                        name: e.target.value
                      }
                    }
                  })}
                  placeholder="Hotspot Name"
                  className={styles.textInput}
                />
              </Paper>
              <p className={styles.inputLabel}>Address</p>
              <Paper className={styles.textInputContainer}>
                <input
                  id="locationAutocomplete"
                  name="locationAddress"
                  value={hotspotState.form.location.address}
                  onChange={e => setHotspotState({
                    ...hotspotState,
                    isPlaceChanged: false,
                    form: {
                      ...hotspotState.form,
                      location: {
                        ...hotspotState.form.location,
                        address: e.target.value
                      }
                    }
                  })}
                  placeholder="Hotspot Address"
                  className={styles.textInput}
                />
              </Paper>
              <div className={styles.submitButtonContainer}>
                <Button onClick={() => submit(props)} className={styles.gradientButton} disabled={hotspotState.isSubmitDisabled}>Update</Button>
              </div>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Modal>
  );
}
