import { createSlice } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading';
import * as Api from "../../app/Api";

export const homeSlice = createSlice({
  name: 'home',
  initialState: {
    email: "",
    password: "",
    error: false,
    errorMessage: "",
  },
  reducers: {
    onValueChange: (state, action) => {
      state[action.payload.name] = action.payload.value;
    },
  },
});

export const { onValueChange, home } = homeSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.home.value)`
export const selectState = state => state.home;


export default homeSlice.reducer;
