import { createSlice } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading';
import * as Api from "../../app/Api";

export const uploadedFileSlice = createSlice({
  name: 'uploadedFile',
  initialState: {
    uploadedFiles: [],
    status: 'idle',
    rollbackUploadedFileModalOpen: false,
    pagination: {},
    page: 0,
    rowsPerPage: 10,
    selectedId: null
  },
  reducers: {
    openRollbackUploadedFileModal: (state) => {
      state.rollbackUploadedFileModalOpen = true;
    },
    closeRollbackUploadedFileModal: (state) => {
      state.rollbackUploadedFileModalOpen = false;
      state.selectedId = null;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setSelectedId: (state, action) => {
      state.selectedId = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload;
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
    setUploadedFiles: (state, action) => {
      state.uploadedFiles = [...action.payload];
      state.status = 'success';
    },
  },
});

export const { openRollbackUploadedFileModal, closeRollbackUploadedFileModal, setStatus, setSelectedId, setPage, setRowsPerPage, setPagination, setUploadedFiles } = uploadedFileSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.uploadedFile.value)`
export const selectState = state => state.uploadedFile;

export const handleGetUploadedFiles = () => async (dispatch, getState) => {
  dispatch(showLoading());
  dispatch(setStatus("success"));
  const state = getState();
  try {
    const res = await Api.getUploadedFiles(state.uploadedFile.page, state.uploadedFile.rowsPerPage);
    if (res.status == 401) {
      Api.logout();
      throw res.error;
    }
    const { content, ...pagination } = res;
    let uploadedFiles = content;

    dispatch(setPagination(pagination));
    dispatch(setUploadedFiles(uploadedFiles));
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(hideLoading());
  }
};

export default uploadedFileSlice.reducer;
