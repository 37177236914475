import { createSlice } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading';
import * as Api from "../../app/Api";

export const userPaymentSlice = createSlice({
  name: 'userPayment',
  initialState: {
    user: null,
    
    userPayments: [],
    status: 'idle',
    pagination: {},
    page: 0,
    rowsPerPage: 10,
    selectedId: null,

    userRedeems: [],
    redeemStatus: 'idle',
    redeemPagination: {},
    redeemPage: 0,
    redeemRowsPerPage: 10,
    redeemSelectedId: null,
  },
  reducers: {
    setSelectedId: (state, action) => {
      state.selectedId = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload;
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
    getUser: (state, action) => {
      state.user = action.payload;
    },
    getUserPayments: (state, action) => {
      state.userPayments = action.payload;
      state.status = 'success'
    },

    setRedeemSelectedId: (state, action) => {
      state.redeemSelectedId = action.payload;
    },
    setRedeemPage: (state, action) => {
      state.redeemPage = action.payload;
    },
    setRedeemRowsPerPage: (state, action) => {
      state.redeemRowsPerPage = action.payload;
    },
    setRedeemPagination: (state, action) => {
      state.redeemPagination = action.payload;
    },
    getUserRedeems: (state, action) => {
      state.userRedeems = action.payload;
      state.redeemStatus = 'success'
    },
  },
});

export const { setSelectedId, setPage, setRowsPerPage, setPagination, getUser, getUserPayments, setRedeemSelectedId, setRedeemPage, setRedeemRowsPerPage, setRedeemPagination, getUserRedeems } = userPaymentSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.userPayment.value)`
export const selectState = state => state.userPayment;

export const handleGetUser = (id) => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();
  try {
    const res = await Api.getUser(id);
    if (res.status === 401 || res.status == 403) {
      Api.logout();
      throw res.error;
    } else if (res.error) {
      throw res.error;
    };

    dispatch(getUser(res));
    dispatch(handleGetWeeklyPaymentsByUser(id));
    dispatch(handleGetRedeemsByUser(id));
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(hideLoading());
  }
};

export const handleGetWeeklyPaymentsByUser = (id) => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();
  try {
    const res = await Api.getWeeklyPaymentsByUser(id, state.userPayment.page, state.userPayment.rowsPerPage);
    if (res.status === 401 || res.status == 403) {
      Api.logout();
      throw res.error;
    } else if (res.error) {
      throw res.error;
    };

    const { content, ...pagination } = res;
    let userPayments = content;

    dispatch(setPagination(pagination));
    dispatch(getUserPayments(userPayments));
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(hideLoading());
  }
};

export const handleGetRedeemsByUser = (id) => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();
  try {
    const res = await Api.getRedeemsByUserId(id, state.userPayment.redeemPage, state.userPayment.redeemRowsPerPage);
    if (res.status === 401 || res.status == 403) {
      Api.logout();
      throw res.error;
    } else if (res.error) {
      throw res.error;
    };
    
    const { content, ...pagination } = res;
    let userRedeems = content;

    dispatch(setRedeemPagination(pagination));
    dispatch(getUserRedeems(userRedeems));
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(hideLoading());
  }
};

export const handleDownloadPayslip = (startDate, platformId, userId) => async (dispatch, getState) => {
  dispatch(showLoading());

  try {
    const res = await Api.getPayslip(startDate, platformId, userId);
    if (res.status === 401 || res.status === 403) {
      Api.logout();
      throw res.error;
    } else if (res.error) {
      throw res.error;
    };
    
    let report = await res.blob();
    // 2. Create blob link to download
    const url = window.URL.createObjectURL(new Blob([report]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `payslip_${startDate}_${platformId}.pdf`);
    // 3. Append to html page
    document.body.appendChild(link);
    // 4. Force download
    link.click();
    // 5. Clean up and remove the link
    link.parentNode.removeChild(link);
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(hideLoading());
  }
};

export const handleDownloadRedeemPayslip = (redeemId) => async (dispatch, getState) => {
  dispatch(showLoading());

  try {
    const res = await Api.getRedeemPayslip(redeemId);
    if (res.status === 401 || res.status === 403) {
      Api.logout();
      throw res.error;
    } else if (res.error) {
      throw res.error;
    };
    
    let report = await res.blob();
    // 2. Create blob link to download
    const url = window.URL.createObjectURL(new Blob([report]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `redeem_payslip_${redeemId}.pdf`);
    // 3. Append to html page
    document.body.appendChild(link);
    // 4. Force download
    link.click();
    // 5. Clean up and remove the link
    link.parentNode.removeChild(link);
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(hideLoading());
  }
};


export default userPaymentSlice.reducer;
