import { createSlice } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading';
import { addDays, format, getISODay } from 'date-fns'
import * as Api from "../../app/Api";

export const getClosestWeekdayDateTheWeek = (weekday, fromDate = new Date()) => {
  const offsetDays = 0 -(getISODay(fromDate) - weekday);
  return addDays(fromDate, offsetDays);
}

export const weeklyPaymentSlice = createSlice({
  name: 'weeklyPayment',
  initialState: {
    weeklyPayments: [],
    status: 'idle',
    dateRangeModalOpen: false,
    pagination: {},
    page: 0,
    rowsPerPage: 10,
    selectedId: null,
    startDate: format(getClosestWeekdayDateTheWeek(1), 'yyyy-MM-dd'),
    endDate: format(getClosestWeekdayDateTheWeek(7), 'yyyy-MM-dd')
  },
  reducers: {
    openDateRangeModal: (state) => {
      state.dateRangeModalOpen = true;
    },
    closeDateRangeModal: (state) => {
      state.dateRangeModalOpen = false;
    },
    setWeeklyPayments: (state, action) => {
      state.weeklyPayments = [...action.payload];
      state.status = 'success';
    },
    setSelectedId: (state, action) => {
      state.selectedId = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload;
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
    setDateRange: (state, action) => {
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
    }
  },
});

export const { setWeeklyPayments, setSelectedId, setPage, setRowsPerPage, setPagination, setDateRange, openDateRangeModal, closeDateRangeModal } = weeklyPaymentSlice.actions;

export const handleGetWeeklyPayments = () => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();
  try {
    const res = await Api.getWeeklyPayments(state.weeklyPayment.page, state.weeklyPayment.rowsPerPage, state.weeklyPayment.startDate.substring(0, 10));
    if (res.status == 401) {
      Api.logout();
      throw res.error;
    }
    const { content, ...pagination } = res;
    let weeklyPayments = content;

    dispatch(setPagination(pagination));
    dispatch(setWeeklyPayments(weeklyPayments));
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(hideLoading());
  }
};

export const selectState = state => state.weeklyPayment;

export default weeklyPaymentSlice.reducer;
