import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox, TablePagination, TableFooter } from "@material-ui/core";
import { TableToolbar } from './TableToolbar';
import { selectState, handleGetWeeklyPayments, setPage, setRowsPerPage, setSelectedId, openDateRangeModal } from './weeklyPaymentSlice';
import { format } from 'date-fns'
import { DateRangeModal } from './DateRangeModal';
import TablePaginationActions from './TablePaginationActions';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import styles from './WeeklyPayment.module.css';

export function WeeklyPayment(props) {
  const state = useSelector(selectState);
  const dispatch = useDispatch();
  useEffect(() => {
    if (state.status === 'idle') {
      dispatch(handleGetWeeklyPayments());
    }
  });

  const handleChangePage = (event, newPage) => {
    dispatch(setPage(newPage));
    dispatch(handleGetWeeklyPayments());
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setRowsPerPage(parseInt(event.target.value, 10)));
    dispatch(setPage(0));
    dispatch(handleGetWeeklyPayments());
  };

  return (
    <div className={styles.container}>
      <Paper className={styles.datePickerContainer} onClick={() => dispatch(openDateRangeModal())}>
        <span>{format(new Date(state.startDate), 'dd/MM/yyyy')}</span> <ArrowRightAltIcon className={styles.dateArrowIcon} /> <span>{format(new Date(state.endDate), 'dd/MM/yyyy')}</span>
      </Paper>
      {
        state.weeklyPayments &&
        <TableContainer component={Paper} className={styles.denseTable}>
          <TableToolbar 
            numSelected={state.selectedId == null ? 0 : 1}
          />
          <Table size="small">
            <TableHead className={styles.tableHeader}>
              <TableRow>
                <TableCell align="left" className={styles.tableHeader}>{" "}</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Payment Date</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Platform ID</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Name</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Amount</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Bonus</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Weekly Transit Time</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                state.weeklyPayments.length > 0 ? (
                  state.weeklyPayments.map((payment) => (
                    <TableRow 
                      key={payment.user.id}
                      hover
                      onClick={() => dispatch(setSelectedId(state.selectedId == payment.user.id ? null : payment.user.id))}
                      role="checkbox"
                      aria-checked={state.selectedId == payment.user.id ? true : false}
                      selected={state.selectedId == payment.user.id ? true : false}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox 
                          color="primary"
                          checked={state.selectedId == payment.user.id ? true : false}
                        />
                      </TableCell>
                      <TableCell align="left">{payment.startDate} - {payment.endDate}</TableCell>
                      <TableCell align="left">{payment.user.platformIds?.join(", ")}</TableCell>
                      <TableCell align="left">{payment.user.firstName}</TableCell>
                      <TableCell align="left">{payment.amount / 100}</TableCell>
                      <TableCell align="left">{payment.bonus / 100}</TableCell>
                      <TableCell align="left">{payment.user.weeklyTransitTime ? new Date(payment.user.weeklyTransitTime * 1000).toISOString().substr(11, 8) : ""}</TableCell>
                      <TableCell align="left"></TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={11} align="center">
                      <p>No Weekly Payments</p>
                    </TableCell>
                  </TableRow>
                )
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                  colSpan={7}
                  count={state.pagination ? state.pagination.totalElements ? state.pagination.totalElements : 0 : 0}
                  rowsPerPage={state.rowsPerPage}
                  page={state.page}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      }
      {
        state.dateRangeModalOpen &&
        <DateRangeModal state={state} dispatch={dispatch} />
      }
    </div>
  );
}
