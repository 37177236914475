import { createSlice } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading';
import * as Api from "../../app/Api";

export const deliveritDeliveryRateSlice = createSlice({
  name: 'deliveritDeliveryRate',
  initialState: {
    deliveritDeliveryRates: [],
    status: 'idle',
    editDeliveritDeliveryRateModalOpen: false,
    pagination: {},
    page: 0,
    rowsPerPage: 10,
    selectedId: null
  },
  reducers: {
    openEditDeliveritDeliveryRateModal: (state) => {
      state.editDeliveritDeliveryRateModalOpen = true;
    },
    closeEditDeliveritDeliveryRateModal: (state) => {
      state.editDeliveritDeliveryRateModalOpen = false;
    },
    setSelectedId: (state, action) => {
      state.selectedId = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload;
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
    getDeliveritDeliveryRates: (state, action) => {
      state.deliveritDeliveryRates = action.payload;
      state.status = 'success'
    },
  },
});

export const { openEditDeliveritDeliveryRateModal, closeEditDeliveritDeliveryRateModal, setSelectedId, setPage, setRowsPerPage, setPagination, getDeliveritDeliveryRates } = deliveritDeliveryRateSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.deliveritDeliveryRate.value)`
export const selectState = state => state.deliveritDeliveryRate;

export const handleGetDeliveritDeliveryRates = () => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();

  try {
    const res = await Api.getDeliveritDeliveryRates(state.deliveritDeliveryRate.page, state.deliveritDeliveryRate.rowsPerPage);
    if (res.status === 401 || res.status == 403) {
      Api.logout();
      throw res.error;
    } else if (res.error) {
      throw res.error;
    };
    
    const { content, ...pagination } = res;
    let deliveritDeliveryRates = content;

    dispatch(setPagination(pagination));
    dispatch(getDeliveritDeliveryRates(deliveritDeliveryRates));
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(hideLoading());
  }
};


export default deliveritDeliveryRateSlice.reducer;
