import { Modal, Paper, Grid } from "@material-ui/core";
import { DateRangePicker } from 'react-date-range';
import { setDateRange, closeDateRangeModal, handleGetWeeklyPayments, getClosestWeekdayDateTheWeek } from './weeklyPaymentSlice';
import { format } from 'date-fns'
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { Close } from "@material-ui/icons";
import styles from './WeeklyPayment.module.css';

export const DateRangeModal = (props) => {
    const { state, dispatch } = props;
    return (
        <Modal
            open={true}
            aria-labelledby="add-payment-modal"
            className={styles.modal}
        >
            <Paper className={styles.modalContainer}>
                <div className={styles.container}>
                    <Close className={styles.closeButton} onClick={() => dispatch(closeDateRangeModal())}/>
                    <h3 className={styles.subtitle}>Select Date Range</h3>
                    <Grid container spacing={5}>
                        <Grid item xs={12}>
                            <DateRangePicker
                                onChange={item => {
                                    dispatch(setDateRange({startDate: format(getClosestWeekdayDateTheWeek(1, item.selection.startDate), 'yyyy-MM-dd'), endDate: format(getClosestWeekdayDateTheWeek(7, item.selection.startDate), 'yyyy-MM-dd')}));
                                    dispatch(handleGetWeeklyPayments());
                                }}
                                showSelectionPreview={true}
                                moveRangeOnFirstSelection={false}
                                months={1}
                                shownDate={new Date(state.startDate)}
                                ranges={[{ startDate: new Date(state.startDate), endDate: new Date(state.endDate), key: 'selection' }]}
                                scroll={{ enabled: true }}
                                direction="vertical"
                                calendarFocus="backwards"
                            />    
                        </Grid>
                    </Grid>
                </div>
            </Paper>
        </Modal>
    );
};